import React, { useContext, useState } from 'react';
import Button from '@mui/material/Button';
import { IoRadioButtonOn, IoCheckbox, IoStar } from "react-icons/io5";
import { MdShortText } from "react-icons/md";
import { TbSortAscendingNumbers } from "react-icons/tb";

import AddIcon from '@mui/icons-material/Add';
import { NewContext } from "../../Context/NewContext";

export default function QuestionTypeDropdown({ onSelectQuestionType, }) {
    const [selectedType, setSelectedType] = useState(null);
    const {
        setMenuOpen,
        menuOpen
    } = useContext(NewContext);

    const handleButtonClick = () => {
        setMenuOpen(!menuOpen);
    };

    const handleMenuItemClick = (questionType) => {
        onSelectQuestionType(questionType);
        setSelectedType(questionType);
        setMenuOpen(false);
    };

    return (
        <div>
            <Button
                id="question-type-button"
                aria-haspopup="true"
                onClick={handleButtonClick}
                startIcon={menuOpen ? '' : <AddIcon />}
                sx={{ width: '325px', mt: 2, color: menuOpen ? 'red' : 'black', justifyContent: menuOpen ? 'center' : 'center', }}
                style={{ backgroundColor: menuOpen ? '' : "#f2f2f2", }}
            >
                {menuOpen ? "Cancel" : "Add Question"}
            </Button>
            {
                menuOpen && (
                    <div id="question-type-menu">
                        <div className='flex w-full text-sm justify-center m-2'>
                            Please choose a question type.
                        </div>
                        <div className='flex flex-col items-center'>
                            <table>
                                <tbody>
                                    <tr>
                                        <td><IoRadioButtonOn style={{ color: 'blue' }} /></td>
                                        <td td > <Button onClick={() => handleMenuItemClick('radio')} className='font-b' style={{ color: 'black', verticalAlign: 'start' }}>Radio</Button></td>
                                    </tr>
                                    <tr>
                                        <td><IoCheckbox style={{ color: 'green' }} /></td>
                                        <td><Button onClick={() => handleMenuItemClick('checkbox')} style={{ color: 'black', verticalAlign: 'start' }}>Checkbox</Button></td>
                                    </tr>
                                    <tr>
                                        <td><IoStar style={{ color: 'gold' }} /></td>
                                        <td><Button onClick={() => handleMenuItemClick('rating')} style={{ color: 'black', verticalAlign: 'start' }}>Rating</Button></td>
                                    </tr>
                                    <tr>
                                        <td><MdShortText style={{ color: 'red' }} /></td>
                                        <td><Button onClick={() => handleMenuItemClick('text')} style={{ color: 'black', verticalAlign: 'start !important' }}>Text</Button></td>
                                    </tr>
                                    <tr>
                                        <td><TbSortAscendingNumbers style={{ color: 'orange' }} /></td>
                                        <td><Button onClick={() => handleMenuItemClick('numberscale')} style={{ color: 'black', verticalAlign: 'start !important' }}>Number Scale</Button></td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                )
            }
        </div >
    );
}
