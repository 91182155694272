import React, { useContext, useEffect, useRef, useState } from "react";
import { NewContext } from "../../Context/NewContext";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Slider from "react-slick";
import Rating from '@mui/material/Rating';
import StarIcon from '@mui/icons-material/Star';
import { Textarea, Typography } from "@material-tailwind/react";
import Button from '@mui/material/Button';
import { FaAngleRight } from "react-icons/fa6";
import { FaAngleLeft } from "react-icons/fa6";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const ViewSurveyModel = () => {
    const { handleClose, formData, open, currentSlide, setCurrentSlide } = useContext(NewContext);
    const [selectedNumber, setSelectedNumber] = useState(3);
    const [value, setValue] = React.useState(2);
    const [hover, setHover] = React.useState(-1);
    const [selectedRadio, setSelectedRadio] = useState({});
    const [selectedCheckboxes, setSelectedCheckboxes] = useState({});
    const [ratings, setRatings] = useState([]);
    const [ratingLabels, setRatingLabels] = useState({});
    const [ratinghover, setratingHover] = useState(-1);
    const [textareavalue, setTextareavalue] = useState({});

    function getLabelText(ratings) {
        return ratings > 1 ? labels[ratings] : "Useless";
    }
    useEffect(() => {
        const labels = {};
        ratings.forEach((rating, index) => {
            labels[index] = getLabelText(rating);
        });
        setRatingLabels(labels);
    }, [ratings]);

    useEffect(() => {
        if (formData?.question?.length) {
            setRatings(Array(formData.question.length).fill(1));
        }
    }, []);

    let sliderRef = useRef(null);
    const next = () => {
        sliderRef.slickNext();
    };
    const previous = () => {
        sliderRef.slickPrev();
    };

    const handleRatingChange = (index, newValue) => {
        setRatings(prevRatings => {
            const newRatings = [...prevRatings];
            newRatings[index] = newValue;
            return newRatings;
        });
    };

    var settings = {
        dots: false,
        infinite: false,
        speed: 300,
        fade: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        waitForAnimate: false,
        lazyLoad: true,
        afterChange: (current) => setCurrentSlide(current),
    }

    const labels = {
        1: 'Useless',
        2: 'Poor',
        3: 'Ok',
        4: 'Good',
        5: 'Excellent',
    };

    function getLabelText(value) {
        return `${value} Star${value !== 1 ? 's' : ''}, ${labels[value]}`;
    }

    const handleNumberScaleClick = (value) => {
        setSelectedNumber(value);
    };

    const handleTextareaChange = (index, value) => {
        setTextareavalue(prevValues => ({
            ...prevValues,
            [index]: value
        }));
    };


    const RenderNumberScale = ({ options }) => {
        return (
            <div className="flex justify-center items-center flex-wrap my-10">
                {options.map((option, index) => (
                    <Button
                        key={index}
                        variant="outlined"
                        onClick={() => handleNumberScaleClick(option)}
                        sx={{
                            backgroundColor: selectedNumber === option ? "#67B7D1" : "transparent",
                            color: selectedNumber === option ? "white" : "gray",
                            "&:hover": {
                                backgroundColor: "#43A6C6",
                                color: "white",
                            },
                            flex: "0 0 20%",
                            maxWidth: "10%",
                            minWidth: "10%",
                            margin: "0.1rem",
                        }}
                    >
                        {option}
                    </Button>
                ))}
            </div>
        );
    };

    const RenderRadioOption = ({ options, questionIndex }) => {
        return (
            <div className="flex justify-center items-center flex-wrap my-5  bg-[#f1f5f9] rounded-xl ">
                {options.map((option, index) => (
                    <Button
                        key={index}
                        sx={{
                            backgroundColor: selectedRadio[questionIndex] === option ? "#a3e635" : "transparent",
                            "&:hover": {
                                backgroundColor: "#a3e635",
                            },
                            color: "#71717a",
                            flex: "0 0 100%",
                            justifyContent: "center",
                            alignItems: "center",
                            borderRadius: "2px",
                            borderBottom: 4,
                            borderColor: 'white',
                            textTransform: 'capitalize',

                        }}
                    >
                        {option}
                    </Button>
                ))
                }
            </div >
        );
    };


    const RenderCheckboxOption = ({ options, questionIndex }) => {
        return (
            <div className="flex justify-center items-center flex-wrap my-5  bg-[#f1f5f9] rounded-xl">
                {options.map((option, index) => (
                    <Button
                        key={index}
                        sx={{
                            backgroundColor: selectedCheckboxes[questionIndex]?.[option] ? "#a3e635" : "transparent",
                            "&:hover": {
                                backgroundColor: selectedCheckboxes[questionIndex]?.[option] ? "#a3e635" : "#a3e635",
                            },
                            color: "#71717a",
                            flex: "0 0 100%",
                            justifyContent: "center",
                            alignItems: "center",
                            borderRadius: "2px",
                            borderBottom: 4,
                            borderColor: 'white',
                            textTransform: 'capitalize',

                        }}
                    >
                        {option}
                    </Button>
                ))}
            </div>
        );
    };


    return (
        <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="responsive-dialog-title"
            sx={{
                "& .MuiDialog-container": {
                    "& .MuiPaper-root": {
                        width: "100%",
                        height: "100%",
                        maxHeight: "667px",
                        maxWidth: "375px",
                        borderRadius: "12px",
                    },
                },
            }}
        >
            <DialogTitle id="responsive-dialog-title">Survey Preview</DialogTitle>
            <DialogContent>
                <div className="slides-container">
                    <h1 className='text-center font-bold py-10 underline text-gray-800 break-words'>{formData.name}</h1>
                    <Slider {...settings} ref={slider => {
                        sliderRef = slider;
                    }}>
                        {formData?.question?.map((question, index) => (
                            <>
                                <h2 className='font-bold leading-normal text-center px-5 text-black  break-words'>{question.text}</h2>
                                <div className='break-words'>
                                    {question?.type === "radio" && (
                                        <ul className="list-none text-center text-gray-700 break-words">
                                            <li className="border-1 border-white font-medium break-words">
                                                <div className='m-7' style={{ wordBreak: "break-word" }}>
                                                    <RenderRadioOption options={question.answer.map(option => (option.text))} questionIndex={index} />
                                                </div>
                                                <div className='mx-10 text-left font-normal break-words'>
                                                    *Single Choice
                                                </div>
                                            </li>
                                        </ul>
                                    )}
                                    {question?.type === "checkbox" && (
                                        <ul className="list-none text-center rounded-[30px] text-gray-700 break-words">
                                            <li className="border-1 border-white font-medium break-words">
                                                <div className='m-7' style={{ wordBreak: "break-word" }}>
                                                    <RenderCheckboxOption options={question.answer.map(option => (option.text))} questionIndex={index} />
                                                </div>
                                                <div className='mx-10 text-left font-normal break-words'>
                                                    *Multiple Choice
                                                </div>
                                            </li>
                                        </ul>
                                    )}
                                    {question?.type === "rating" && (
                                        <div className="flex flex-col items-center p-8">

                                            <div>
                                                <Rating
                                                    name={`rating-${index}`}
                                                    value={ratings[index] || 1}
                                                    precision={1}
                                                    getLabelText={getLabelText}
                                                    onChange={(event, newValue) => {
                                                        handleRatingChange(index, newValue);
                                                    }}
                                                    onChangeActive={(event, newHover) => {
                                                        setratingHover(newHover);
                                                    }}
                                                    size="large"
                                                    emptyIcon={<StarIcon fontSize="inherit" className="opacity-50" />}
                                                    sx={{
                                                        justifyContent: "center",
                                                        alignItems: "center",
                                                        gap: "5px",
                                                        marginTop: 10,
                                                        marginBottom: 5,
                                                        fontSize: "30px",
                                                        color: "#FEB811"
                                                    }}
                                                />
                                                <div className="text-center ">
                                                    {ratingLabels[index] && (
                                                        <div className='flex justify-center text-[20px] font-semibold'>{ratingLabels[index]}</div>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                    {question?.type === "text" && (
                                        <div className='flex p-8'>
                                            <textarea
                                                value={textareavalue[index] || ''}
                                                onChange={(e) => handleTextareaChange(index, e.target.value)}
                                                className="form-control textarea w-full mx-auto text-gray-900 bg-gray-50 rounded-lg border border-gray-300 p-5 leading-[70px] !text-xl !placeholder:!text-xl"
                                                rows={4}
                                                style={{ fontSize: "1.6rem" }}
                                                placeholder="Please let us know your thought"
                                            ></textarea>

                                        </div>
                                    )}
                                    {question?.type === "numberscale" && (
                                        <div className='p-8'>
                                            <RenderNumberScale options={question.answer.map(option => parseInt(option.text))} questionIndex={index} />
                                        </div>
                                    )}
                                </div>
                            </>
                        ))}
                        {formData?.question?.length && (
                            <div>
                                <div style={{ minHeight: "40vh", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
                                    <Typography className='font-bold text-2xl' align="center">
                                        Submit Survey
                                    </Typography>
                                    <div className="mt-4">
                                        <Button variant="contained" color="primary" size="medium" sx={{ fontSize: 15, backgroundColor: "green", borderRadius: "60px", textTransform: "capitalize", padding: "10px", width: "150px", display: "inline-block" }}>
                                            Submit
                                        </Button>
                                    </div>
                                </div>
                            </div>
                        )}
                    </Slider>
                    <div style={{ position: 'sticky', bottom: 0, left: 0, background: 'white', display: 'flex', alignItems: 'center', justifyItems: 'center', gap: 5 }}>
                        <button disabled={currentSlide === 0} className={`button flex justify-center items-center gap-2  text-center px-5 py-2 shadow-xl font-semibold ${currentSlide === 0 ? 'bg-gray-300 text-gray-500' : 'bg-green-900 text-white'} rounded-full float-left ms-2`} onClick={previous}>
                            <FaAngleLeft className='' /> Back
                        </button>
                        <span className="font-medium  text-sm  text-gray-400" >Question {currentSlide + 1} of {formData.question?.length + 1}</span>
                        <button disabled={currentSlide === formData.question?.length} className={`button flex justify-center items-center gap-2  text-center px-5 py-2 shadow-xl font-semibold ${currentSlide === formData.question?.length ? 'bg-gray-300 text-gray-500' : 'bg-green-900 text-white'} rounded-full float-right me-2`} onClick={next}>
                            Next <FaAngleRight className='' />
                        </button>
                    </div>
                </div >
            </DialogContent>
        </Dialog>
    );
};

export default ViewSurveyModel;
