import * as api from "./api";

const COMMON_ERROR = "Error, Please try again.!";
const errorResponse = (error) => {
  if (error.response) {
    const { status, data } = error.response;
    if (status === 403) {
      localStorage.clear();
      window.location.href = "/";
    }
    return data;
  } else {
    return { status: 0, message: COMMON_ERROR };
  }
};
let setFormData = (_payload) => {
  let formData = new FormData();
  for (let key in _payload) {
    if (
      key === "themeTag" ||
      key === "themeCategory" ||
      key === "country" ||
      key === "region" ||
      key === "bigPreviewStickerStore" ||
      key === "videoPreview" ||
      key === "video" ||
      key === "faqImages"
    ) {
      _payload[key]?.forEach((_item) => formData?.append(`${key}`, _item));
    } else if (key === "media") {
      _payload[key]?.forEach((_item, i) => {
        Object.keys(_item).forEach((_ele) => {
          formData?.append(`${key}[${i}][${_ele}]`, _item[_ele]);
        });
      });
    } else {
      formData?.append(`${key}`, _payload[key]);
    }
  }
  return formData;
};

export const check_user_permissions = async () => {
  try {
    const { data } = await api.checkUserPermissions(
      process.env.REACT_APP_PROJECT_ID
    );
    return data;
  } catch (error) {
    console.error(`checkuserpermissions =======>>>>> : ${error}`, error);
    return errorResponse(error);
  }
};
export const admin_signin = async (_payload) => {
  try {
    const { data } = await api.adminSignin(_payload);
    return data;
  } catch (error) {
    console.error(`admin_signin ====>>>> : ${error}`, error);
    return errorResponse(error);
  }
};

export const ldap_admin_signin = async (_payload) => {
  try {
    const { data } = await api.ldapadminSignin(_payload);
    return data;
  } catch (error) {
    console.error(`ldap_admin_signin ====>>>> : ${error}`, error);
    return errorResponse(error);
  }
};
export const get_user_details = async () => {
  try {
    const { data } = await api.getUserDetails();
    return data;
  } catch (error) {
    console.error(`get_user_details ====>>>> : ${error}`, error);
    return errorResponse(error);
  }
};
export const get_dashboard_data = async (_search) => {
  try {
    const { data } = await api.getDashboard(_search);
    return data;
  } catch (error) {
    console.error("get_dashboard_data ====>>>> ", error);
    return errorResponse(error);
  }
};
export const get_country_data = async () => {
  try {
    const { data } = await api.getCountry();
    return data;
  } catch (error) {
    console.error("get_country_data ====>>>> ", error);
    return errorResponse(error);
  }
};
export const get_region_data = async () => {
  try {
    const { data } = await api.getRegion();
    return data;
  } catch (error) {
    console.error("get_region_data ====>>>> ", error);
    return errorResponse(error);
  }
};
export const get_sliders_data = async (
  _currentPage,
  _perPage,
  _search,
  _filterDropdown
) => {
  try {
    let getData;
    if (_currentPage || _perPage || _search || _filterDropdown) {
      const { data } = await api.getSliders(
        _currentPage,
        _perPage,
        _search,
        _filterDropdown
      );
      getData = data;
    } else {
      const { data } = await api.getSliders();
      getData = data;
    }
    return getData;
  } catch (error) {
    console.error("get_sliders_data ====>>>> ", error);
    return errorResponse(error);
  }
};
export const add_slider = async (_payload) => {
  try {
    let formData = setFormData(_payload);
    const { data } = await api.addSliders(formData);
    return data;
  } catch (error) {
    console.error("add_slider ====>>>> ", error);
    return errorResponse(error);
  }
};
export const update_slider = async (_payload, _id) => {
  try {
    let formData = setFormData(_payload);
    const { data } = await api.updateSliders(formData, _id);
    return data;
  } catch (error) {
    console.error("update_slider ====>>>> ", error);
    return errorResponse(error);
  }
};
export const delete_slider = async (_id) => {
  try {
    const { data } = await api.deleteSliders(_id);
    return data;
  } catch (error) {
    console.error("delete_slider ====>>>> ", error);
    return errorResponse(error);
  }
};
export const delete_all_slider = async (_payload) => {
  try {
    const { data } = await api.deleteAllSliders(_payload);
    return data;
  } catch (error) {
    console.error("delete_all_slider ====>>>> ", error);
    return errorResponse(error);
  }
};
export const get_slider_tag = async (
  _currentPage,
  _perPage,
  _search,
  _filterDropdown
) => {
  try {
    let getData;
    if (_currentPage || _perPage || _search || _filterDropdown) {
      const { data } = await api.getSliderTag(
        _currentPage,
        _perPage,
        _search,
        _filterDropdown
      );
      getData = data;
    } else {
      const { data } = await api.getSliderTag();
      getData = data;
    }
    return getData;
  } catch (error) {
    console.error("get_slider_tag ====>>>> ", error);
    return errorResponse(error);
  }
};
export const add_slider_tags = async (_payload) => {
  try {
    const { data } = await api.addSliderTag(_payload);
    return data;
  } catch (error) {
    console.error("add_slider_tags ====>>>> ", error);
    return errorResponse(error);
  }
};
export const update_slider_tags = async (_payload, _id) => {
  try {
    const { data } = await api.updateSliderTag(_payload, _id);
    return data;
  } catch (error) {
    console.error("update_slider_tags ====>>>> ", error);
    return errorResponse(error);
  }
};
export const delete_slider_tag = async (_id) => {
  try {
    const { data } = await api.deleteSliderTag(_id);
    return data;
  } catch (error) {
    console.error("delete_slider_tag ====>>>> ", error);
    return errorResponse(error);
  }
};
export const delete_all_slider_tag = async (_payload) => {
  try {
    const { data } = await api.deleteAllSliderTag(_payload);
    return data;
  } catch (error) {
    console.error("delete_all_slider_tag ====>>>> ", error);
    return errorResponse(error);
  }
};
export const get_home_activity_data = async (
  _currentPage,
  _perPage,
  _search,
  _filterDropdown
) => {
  try {
    let getData;
    if (_currentPage || _perPage || _search || _filterDropdown) {
      const { data } = await api.getHomeActivity(
        _currentPage,
        _perPage,
        _search,
        _filterDropdown
      );
      getData = data;
    } else {
      const { data } = await api.getHomeActivity();
      getData = data;
    }
    return getData;
  } catch (error) {
    console.error("get_home_activity_data ====>>>> ", error);
    return errorResponse(error);
  }
};
export const get_home_screen_data = async () => {
  try {
    const { data } = await api.getHomeScreen();
    return data;
  } catch (error) {
    console.error("get_home_activity_data ====>>>> ", error);
    return errorResponse(error);
  }
};
export const get_wallpaper_home_screen_data = async (count) => {
  try {
    const { data } = await api.getWallpaperHomeScreen(count);
    return data;
  } catch (error) {
    console.error("get_wallpaper_home_screen_data ====>>>> ", error);
    return { status: 0, message: COMMON_ERROR };
  }
};
export const add_section_settings = async (_payload) => {
  try {
    let formData;
    if (_payload.homePreview) {
      formData = setFormData(_payload);
    } else {
      formData = _payload;
    }
    const { data } = await api.addSectionSettings(formData);
    return data;
  } catch (error) {
    console.error("add_section_settings ====>>>> ", error);
    return errorResponse(error);
  }
};
export const update_section_settings = async (_payload, _id) => {
  try {
    let formData;
    if (_payload.homePreview) {
      formData = setFormData(_payload);
    } else {
      formData = _payload;
    }
    const { data } = await api.updateSectionSettings(formData, _id);
    return data;
  } catch (error) {
    console.error("update_section_settings ====>>>> ", error);
    return errorResponse(error);
  }
};
export const update_section_settings_priority = async (_payload) => {
  try {
    const { data } = await api.updateSectionSettingsPriority(_payload);
    return data;
  } catch (error) {
    console.error("update_section_settings_priority ====>>>> ", error);
    return errorResponse(error);
  }
};
export const delete_section_settings = async (_id) => {
  try {
    const { data } = await api.deleteSectionSettings(_id);
    return data;
  } catch (error) {
    console.error("delete_section_settings ====>>>> ", error);
    return errorResponse(error);
  }
};
export const delete_all_section_settings = async (_payload) => {
  try {
    const { data } = await api.deleteAllSectionSettings(_payload);
    return data;
  } catch (error) {
    console.error("delete_all_section_settings ====>>>> ", error);
    return errorResponse(error);
  }
};

export const get_non_active_slider_data = async (_currentPage, _perPage) => {
  try {
    let getData;
    if (_currentPage || _perPage) {
      const { data } = await api.getNonActiveSlider(_currentPage, _perPage);
      getData = data;
    } else {
      const { data } = await api.getNonActiveSlider();
      getData = data;
    }
    return getData;
  } catch (error) {
    console.error("get_non_active_slider_data ====>>>> ", error);
    return errorResponse(error);
  }
};
export const get_non_active_functionality_data = async (
  _currentPage,
  _perPage
) => {
  try {
    let getData;
    if (_currentPage || _perPage) {
      const { data } = await api.getNonActiveFunctionalityFontList(
        _currentPage,
        _perPage
      );
      getData = data;
    } else {
      const { data } = await api.getNonActiveFunctionalityFontList();
      getData = data;
    }
    return getData;
  } catch (error) {
    console.error("get_non_active_functionality_data ====>>>> ", error);
    return errorResponse(error);
  }
};
export const get_non_active_theme_data = async (_currentPage, _perPage) => {
  try {
    let getData;
    if (_currentPage || _perPage) {
      const { data } = await api.getNonActiveTab(_currentPage, _perPage);
      getData = data;
    } else {
      const { data } = await api.getNonActiveTab();
      getData = data;
    }
    return getData;
  } catch (error) {
    console.error("get_non_active_theme_data ====>>>> ", error);
    return errorResponse(error);
  }
};
export const get_non_active_gamezone_data = async (_currentPage, _perPage) => {
  try {
    let getData;
    if (_currentPage || _perPage) {
      const { data } = await api.getNonActiveGamezone(_currentPage, _perPage);
      getData = data;
    } else {
      const { data } = await api.getNonActiveGamezone();
      getData = data;
    }
    return getData;
  } catch (error) {
    console.error("get_non_active_gamezone_data ====>>>> ", error);
    return errorResponse(error);
  }
};
export const get_non_custom_button_data = async (_currentPage, _perPage) => {
  try {
    let getData;
    if (_currentPage || _perPage) {
      const { data } = await api.getNonCustomButtonHomeScreen(
        _currentPage,
        _perPage
      );
      getData = data;
    } else {
      const { data } = await api.getNonCustomButtonHomeScreen();
      getData = data;
    }
    return getData;
  } catch (error) {
    console.error("get_non_custom_button_data ====>>>> ", error);
    return errorResponse(error);
  }
};
export const get_homescreen_key_data = async (
  _currentPage,
  _perPage,
  _search,
  _filterDropdown
) => {
  try {
    let getData;
    if (_currentPage || _perPage || _search || _filterDropdown) {
      const { data } = await api.getHomeScreenKey(
        _currentPage,
        _perPage,
        _search,
        _filterDropdown
      );
      getData = data;
    } else {
      const { data } = await api.getHomeScreenKey();
      getData = data;
    }
    return getData;
  } catch (error) {
    console.error("get_homescreen_key_data ====>>>> ", error);
    return errorResponse(error);
  }
};
export const add_homescreen_key = async (_payload) => {
  try {
    const { data } = await api.addHomeScreenKey(_payload);
    return data;
  } catch (error) {
    console.error("add_homescreen_key ====>>>> ", error);
    return errorResponse(error);
  }
};
export const update_homescreen_key = async (_payload, _id) => {
  try {
    const { data } = await api.updateHomeScreenKey(_payload, _id);
    return data;
  } catch (error) {
    console.error("update_homescreen_key ====>>>> ", error);
    return errorResponse(error);
  }
};
export const delete_homescreen_key = async (_id) => {
  try {
    const { data } = await api.deleteHomeScreenKey(_id);
    return data;
  } catch (error) {
    console.error("delete_homescreen_key ====>>>> ", error);
    return errorResponse(error);
  }
};
export const delete_all_homescreen_key = async (_payload) => {
  try {
    const { data } = await api.deleteAllHomeScreenKey(_payload);
    return data;
  } catch (error) {
    console.error("delete_all_homescreen_key ====>>>> ", error);
    return errorResponse(error);
  }
};
export const get_custom_button_data = async (
  _currentPage,
  _perPage,
  _search,
  _filterDropdown
) => {
  try {
    let getData;
    if (_currentPage || _perPage || _search || _filterDropdown) {
      const { data } = await api.getCustomeButtonHomeScreen(
        _currentPage,
        _perPage,
        _search,
        _filterDropdown
      );
      getData = data;
    } else {
      const { data } = await api.getCustomeButtonHomeScreen();
      getData = data;
    }
    return getData;
  } catch (error) {
    console.error("get_custom_button_data ====>>>> ", error);
    return errorResponse(error);
  }
};
export const add_custom_button = async (_payload) => {
  try {
    let formData = setFormData(_payload);
    const { data } = await api.addCustomeButtonHomeScreen(formData);
    return data;
  } catch (error) {
    console.error("add_custom_button ====>>>> ", error);
    return errorResponse(error);
  }
};
export const update_custom_button = async (_payload, _id) => {
  try {
    let formData = setFormData(_payload);
    const { data } = await api.updateCustomeButtonHomeScreen(formData, _id);
    return data;
  } catch (error) {
    console.error("update_custom_button ====>>>> ", error);
    return errorResponse(error);
  }
};
export const delete_custom_button = async (_id) => {
  try {
    const { data } = await api.deleteCustomeButtonHomeScreen(_id);
    return data;
  } catch (error) {
    console.error("delete_custom_button ====>>>> ", error);
    return errorResponse(error);
  }
};
export const delete_all_custom_button = async (_payload) => {
  try {
    const { data } = await api.deleteAllCustomeButtonHomeScreen(_payload);
    return data;
  } catch (error) {
    console.error("delete_all_custom_button ====>>>> ", error);
    return errorResponse(error);
  }
};
export const get_banners = async (
  _currentPage,
  _perPage,
  _search,
  _filterDropdown
) => {
  try {
    let getData;
    if (_currentPage || _perPage || _search || _filterDropdown) {
      const { data } = await api.getHomeScreenBanners(
        _currentPage,
        _perPage,
        _search,
        _filterDropdown
      );
      getData = data;
    } else {
      const { data } = await api.getHomeScreenBanners();
      getData = data;
    }
    return getData;
  } catch (error) {
    console.error("get_banners ====>>>> ", error);
    return errorResponse(error);
  }
};
export const add_banners = async (_payload) => {
  try {
    let formData = setFormData(_payload);
    const { data } = await api.addHomescreenBanner(formData);
    return data;
  } catch (error) {
    console.error("add_banners ====>>>> ", error);
    return errorResponse(error);
  }
};
export const update_banners = async (_payload, _id) => {
  try {
    let formData = setFormData(_payload);
    const { data } = await api.updateHomescreenBanner(formData, _id);
    return data;
  } catch (error) {
    console.error("update_banners ====>>>> ", error);
    return errorResponse(error);
  }
};
export const delete_banners = async (_id) => {
  try {
    const { data } = await api.deleteHomescreenBanner(_id);
    return data;
  } catch (error) {
    console.error("delete_banners ====>>>> ", error);
    return errorResponse(error);
  }
};
export const delete_all_banners = async (_payload) => {
  try {
    const { data } = await api.deleteAllHomescreenBanner(_payload);
    return data;
  } catch (error) {
    console.error("delete_all_banners ====>>>> ", error);
    return errorResponse(error);
  }
};
export const get_functionality_fonts = async (
  _currentPage,
  _perPage,
  _search,
  _filterDropdown
) => {
  try {
    let getData;
    if (_currentPage || _perPage || _search || _filterDropdown) {
      const { data } = await api.getFunctionalityFontList(
        _currentPage,
        _perPage,
        _search,
        _filterDropdown
      );
      getData = data;
    } else {
      const { data } = await api.getFunctionalityFontList();
      getData = data;
    }
    return getData;
  } catch (error) {
    console.error("get_functionality_fonts ====>>>> ", error);
    return errorResponse(error);
  }
};
export const add_functionality_fonts = async (_payload) => {
  try {
    let formData = setFormData(_payload);
    const { data } = await api.addFunctionalityFontList(formData);
    return data;
  } catch (error) {
    console.error("add_functionality_fonts ====>>>> ", error);
    return errorResponse(error);
  }
};
export const update_functionality_fonts = async (_payload, _id) => {
  try {
    let formData = setFormData(_payload);
    const { data } = await api.updateFunctionalityFontList(formData, _id);
    return data;
  } catch (error) {
    console.error("update_functionality_fonts ====>>>> ", error);
    return errorResponse(error);
  }
};
export const delete_functionality_fonts = async (_id) => {
  try {
    const { data } = await api.deleteFunctionalityFontList(_id);
    return data;
  } catch (error) {
    console.error("delete_functionality_fonts ====>>>> ", error);
    return errorResponse(error);
  }
};
export const delete_all_functionality_fonts = async (_payload) => {
  try {
    const { data } = await api.deleteAllFunctionalityFontList(_payload);
    return data;
  } catch (error) {
    console.error("delete_all_functionality_fonts ====>>>> ", error);
    return errorResponse(error);
  }
};
export const get_themes_data = async (
  _currentPage,
  _perPage,
  _search,
  _filterDropdown
) => {
  try {
    let getData;
    if (_currentPage || _perPage || _search || _filterDropdown) {
      const { data } = await api.getThemes(
        _currentPage,
        _perPage,
        _search,
        _filterDropdown
      );
      getData = data;
    } else {
      const { data } = await api.getThemes();
      getData = data;
    }
    return getData;
  } catch (error) {
    console.error("get_themes_data ====>>>> ", error);
    return errorResponse(error);
  }
};
export const add_theme = async (_payload) => {
  try {
    let formData = setFormData(_payload);
    const { data } = await api.addTheme(formData);
    return data;
  } catch (error) {
    console.error("add_theme ====>>>> ", error);
    return errorResponse(error);
  }
};
export const update_theme = async (_payload, _id) => {
  try {
    let formData = setFormData(_payload);
    const { data } = await api.updateTheme(formData, _id);
    return data;
  } catch (error) {
    console.error("update_theme ====>>>> ", error);
    return errorResponse(error);
  }
};
export const delete_theme = async (_id) => {
  try {
    const { data } = await api.deleteTheme(_id);
    return data;
  } catch (error) {
    console.error("delete_theme ====>>>> ", error);
    return errorResponse(error);
  }
};
export const delete_all_themes = async (_payload) => {
  try {
    const { data } = await api.deleteAllThemes(_payload);
    return data;
  } catch (error) {
    console.error("delete_all_themes ====>>>> ", error);
    return errorResponse(error);
  }
};
export const get_Community_Theme_data = async (
  _currentPage,
  _perPage,
  _search,
  _filterDropdown
) => {
  try {
    let CommunityData;
    if (_currentPage || _perPage || _search || _filterDropdown) {
      const { data } = await api.getCommunityThemeAPI(
        _currentPage,
        _perPage,
        _search,
        _filterDropdown
      );
      CommunityData = data;
    } else {
      const { data } = await api.getCommunityThemeAPI();
      CommunityData = data;
    }
    return CommunityData;
  } catch (error) {
    console.error("get_theme_categories_data ====>>>> ", error);
    return errorResponse(error);
  }
};
// ===========================================================================================================
export const get_Community_Pending_Theme_data = async (
  _currentPage,
  _perPage,
  _search,
  _filterDropdown
) => {
  try {
    let CommunityData;
    if (_currentPage || _perPage || _search || _filterDropdown) {
      const { data } = await api.getCommunityPendingThemeAPI(
        _currentPage,
        _perPage,
        _search,
        _filterDropdown
      );
      CommunityData = data;
    } else {
      const { data } = await api.getCommunityPendingThemeAPI();
      CommunityData = data;
    }
    return CommunityData;
  } catch (error) {
    console.error("get_theme_categories_data ====>>>> ", error);
    return errorResponse(error);
  }
};
// ===========================================================================================================
export const get_Community_Reason = async (
  _currentPage,
  _perPage,
  _search,
  _filterDropdown
) => {
  try {
    let CommunityData;
    if (_currentPage || _perPage || _search || _filterDropdown) {
      const { data } = await api.getCommunityReason(
        _currentPage,
        _perPage,
        _search,
        _filterDropdown
      );
      CommunityData = data;
    } else {
      const { data } = await api.getCommunityReason();
      CommunityData = data;
    }
    return CommunityData;
  } catch (error) {
    console.error("get_theme_categories_data ====>>>> ", error);
    return errorResponse(error);
  }
};
export const update_Community_Reason = async (_payload, _id) => {
  try {
    const { data } = await api.updateCommunity_Reason(_payload, _id);
    return data;
  } catch (error) {
    console.error("update_kamoji ====>>>> ", error);
    return errorResponse(error);
  }
};
export const add_Community_Reason = async (_payload) => {
  try {
    const { data } = await api.addCommunity_Reason(_payload);
    return data;
  } catch (error) {
    console.error("add_kamoji ====>>>> ", error);
    return errorResponse(error);
  }
};
export const delete_CommunityReason = async (_id) => {
  try {
    const { data } = await api.deleteCommunityReason(_id);
    return data;
  } catch (error) {
    console.error("delete_kamoji ====>>>> ", error);
    return errorResponse(error);
  }
};
export const deleteAll_CommunityReason = async (_payload) => {
  try {
    const { data } = await api.deleteAllCommunityReason(_payload);
    return data;
  } catch (error) {
    console.error("deleteAllCommunityReason ====>>>> ", error);
    return errorResponse(error);
  }
};
export const get_CommunityUserReport_data = async (
  _currentPage,
  _perPage,
  _search,
  _filterDropdown
) => {
  try {
    let categoryData;
    if (_currentPage || _perPage || _search || _filterDropdown) {
      const { data } = await api.getCommunityUserReportAPI(
        _currentPage,
        _perPage,
        _search,
        _filterDropdown
      );
      categoryData = data;
    } else {
      const { data } = await api.getCommunityUserReportAPI();
      categoryData = data;
    }
    return categoryData;
  } catch (error) {
    console.error("get_theme_categories_data ====>>>> ", error);
    return errorResponse(error);
  }
};
export const delete_Report = async (_id) => {
  try {
    const { data } = await api.deleteCommunityReport(_id);
    return data;
  } catch (error) {
    console.error("delete_community_user ====>>>> ", error);
    return errorResponse(error);
  }
};
export const delete_all_community_Report = async (_payload) => {
  try {
    const { data } = await api.deleteAllCommunityReport(_payload);
    return data;
  } catch (error) {
    console.error("deleteAllCommunityReport ====>>>> ", error);
    return errorResponse(error);
  }
};
export const get_theme_categories_data = async (
  _currentPage,
  _perPage,
  _search,
  _filterDropdown
) => {
  try {
    let categoryData;
    if (_currentPage || _perPage || _search || _filterDropdown) {
      const { data } = await api.getThemeCategory(
        _currentPage,
        _perPage,
        _search,
        _filterDropdown
      );
      categoryData = data;
    } else {
      const { data } = await api.getThemeCategory();
      categoryData = data;
    }
    return categoryData;
  } catch (error) {
    console.error("get_theme_categories_data ====>>>> ", error);
    return errorResponse(error);
  }
};
export const add_theme_category = async (_payload) => {
  try {
    let formData = setFormData(_payload);
    const { data } = await api.addThemeCategory(formData);
    return data;
  } catch (error) {
    console.error("add_theme_category ====>>>> ", error);
    return errorResponse(error);
  }
};
export const update_theme_category = async (_payload, _id) => {
  try {
    let formData = setFormData(_payload);
    const { data } = await api.updateThemeCategory(formData, _id);
    return data;
  } catch (error) {
    console.error("update_theme_category ====>>>> ", error);
    return errorResponse(error);
  }
};
export const delete_theme_category = async (_id) => {
  try {
    const { data } = await api.deleteThemeCategory(_id);
    return data;
  } catch (error) {
    console.error("delete_theme_category ====>>>> ", error);
    return errorResponse(error);
  }
};
export const delete_all_theme_category = async (_payload) => {
  try {
    const { data } = await api.deleteAllCategory(_payload);
    return data;
  } catch (error) {
    console.error("delete_all_theme_category ====>>>> ", error);
    return errorResponse(error);
  }
};
export const get_tag = async (
  _currentPage,
  _perPage,
  _search,
  _filterDropdown
) => {
  try {
    let getData;
    if (_currentPage || _perPage || _search || _filterDropdown) {
      const { data } = await api.getTag(
        _currentPage,
        _perPage,
        _search,
        _filterDropdown
      );
      getData = data;
    } else {
      const { data } = await api.getTag();
      getData = data;
    }
    return getData;
  } catch (error) {
    console.error("get_tag ====>>>> ", error);
    return errorResponse(error);
  }
};
export const add_tags = async (_payload) => {
  try {
    const { data } = await api.addTag(_payload);
    return data;
  } catch (error) {
    console.error("add_tags ====>>>> ", error);
    return errorResponse(error);
  }
};
export const update_tags = async (_payload, _id) => {
  try {
    const { data } = await api.updateTag(_payload, _id);
    return data;
  } catch (error) {
    console.error("update_tags ====>>>> ", error);
    return errorResponse(error);
  }
};
export const delete_tag = async (_id) => {
  try {
    const { data } = await api.deleteUpdateTag(_id);
    return data;
  } catch (error) {
    console.error("delete_tag ====>>>> ", error);
    return errorResponse(error);
  }
};
export const delete_all_tag = async (_payload) => {
  try {
    const { data } = await api.deleteAllTag(_payload);
    return data;
  } catch (error) {
    console.error("delete_all_tag ====>>>> ", error);
    return errorResponse(error);
  }
};
export const get_tab = async (
  _currentPage,
  _perPage,
  _search,
  _filterDropdown
) => {
  try {
    let getData;
    if (_currentPage || _perPage || _search || _filterDropdown) {
      const { data } = await api.getTab(
        _currentPage,
        _perPage,
        _search,
        _filterDropdown
      );
      getData = data;
    } else {
      const { data } = await api.getTab();
      getData = data;
    }
    return getData;
  } catch (error) {
    console.error("get_tab ====>>>> ", error);
    return errorResponse(error);
  }
};
export const add_tab = async (_payload) => {
  try {
    let formData = setFormData(_payload);
    const { data } = await api.addTab(formData);
    return data;
  } catch (error) {
    console.error("add_tab ====>>>> ", error);
    return errorResponse(error);
  }
};
export const update_tab = async (_payload, _id) => {
  try {
    let formData = setFormData(_payload);
    const { data } = await api.updateTab(formData, _id);
    return data;
  } catch (error) {
    console.error("update_tab ====>>>> ", error);
    return errorResponse(error);
  }
};
export const delete_tab = async (_id) => {
  try {
    const { data } = await api.deleteUpdateTab(_id);
    return data;
  } catch (error) {
    console.error("delete_tab ====>>>> ", error);
    return errorResponse(error);
  }
};
export const delete_all_tab = async (_payload) => {
  try {
    const { data } = await api.deleteAllTab(_payload);
    return data;
  } catch (error) {
    console.error("delete_all_tab ====>>>> ", error);
    return errorResponse(error);
  }
};
export const get_background_data = async (
  _currentPage,
  _perPage,
  _search,
  _filterDropdown
) => {
  try {
    let getData;
    if (_currentPage || _perPage || _search || _filterDropdown) {
      const { data } = await api.getBackground(
        _currentPage,
        _perPage,
        _search,
        _filterDropdown
      );
      getData = data;
    } else {
      const { data } = await api.getBackground();
      getData = data;
    }
    return getData;
  } catch (error) {
    console.error("get_background_data ====>>>> ", error);
    return errorResponse(error);
  }
};
export const add_background = async (_payload) => {
  try {
    let formData = setFormData(_payload);
    const { data } = await api.addBackground(formData);
    return data;
  } catch (error) {
    console.error("add_background ====>>>> ", error);
    return errorResponse(error);
  }
};
export const update_background = async (_payload, _id) => {
  try {
    let formData = setFormData(_payload);
    const { data } = await api.updateBackground(formData, _id);
    return data;
  } catch (error) {
    console.error("update_background ====>>>> ", error);
    return errorResponse(error);
  }
};
export const delete_background = async (_id) => {
  try {
    const { data } = await api.deleteBackground(_id);
    return data;
  } catch (error) {
    console.error("delete_background ====>>>> ", error);
    return errorResponse(error);
  }
};
export const delete_approvedTheme = async (_id) => {
  try {
    const { data } = await api.deleteApprovedTheme(_id);
    return data;
  } catch (error) {
    console.error("deleteApprovedTheme ====>>>> ", error);
    return errorResponse(error);
  }
};
export const delete_all_approvedTheme = async (_payload) => {
  try {
    const { data } = await api.deleteAllApprovedTheme(_payload);
    return data;
  } catch (error) {
    console.error("delete_all_approvedTheme ====>>>> ", error);
    return errorResponse(error);
  }
};
export const update_approvedTheme = async (_payload, _id) => {
  try {
    const { data } = await api.updateApprovedTheme(_payload, _id);
    return data;
  } catch (error) {
    console.error("update_background_category ====>>>> ", error);
    return errorResponse(error);
  }
};
export const delete_all_background = async (_payload) => {
  try {
    const { data } = await api.deleteAllBackground(_payload);
    return data;
  } catch (error) {
    console.error("delete_all_background ====>>>> ", error);
    return errorResponse(error);
  }
};
export const get_background_categories_data = async (
  _currentPage,
  _perPage,
  _search,
  _filterDropdown
) => {
  try {
    let categoryData;
    if (_currentPage || _perPage || _search || _filterDropdown) {
      const { data } = await api.getBackgroundCategory(
        _currentPage,
        _perPage,
        _search,
        _filterDropdown
      );
      categoryData = data;
    } else {
      const { data } = await api.getBackgroundCategory();
      categoryData = data;
    }
    return categoryData;
  } catch (error) {
    console.error("get_background_categories_data ====>>>> ", error);
    return errorResponse(error);
  }
};
export const add_background_category = async (_payload) => {
  try {
    const data = await api.addBackgroundCategory(_payload);
    return data;
  } catch (error) {
    console.error("add_background_category ====>>>> ", error);
    return errorResponse(error);
  }
};
export const update_background_category = async (_payload, _id) => {
  try {
    const { data } = await api.updateBackgroundCategory(_payload, _id);
    return data;
  } catch (error) {
    console.error("update_background_category ====>>>> ", error);
    return errorResponse(error);
  }
};
export const delete_background_category = async (_id) => {
  try {
    const { data } = await api.deleteBackgroundCategory(_id);
    return data;
  } catch (error) {
    console.error("delete_background_category ====>>>> ", error);
    return errorResponse(error);
  }
};
export const delete_all_background_category = async (_payload) => {
  try {
    const { data } = await api.deleteAllBackgroundCategory(_payload);
    return data;
  } catch (error) {
    console.error("delete_all_background_category ====>>>> ", error);
    return errorResponse(error);
  }
};
export const get_effects_data = async (
  _currentPage,
  _perPage,
  _search,
  _filterDropdown
) => {
  try {
    let getData;
    if (_currentPage || _perPage || _search || _filterDropdown) {
      const { data } = await api.getEffects(
        _currentPage,
        _perPage,
        _search,
        _filterDropdown
      );
      getData = data;
    } else {
      const { data } = await api.getEffects();
      getData = data;
    }
    return getData;
  } catch (error) {
    console.error("get_effects_data ====>>>> ", error);
    return errorResponse(error);
  }
};
export const add_effects = async (_payload) => {
  try {
    let formData = setFormData(_payload);
    const { data } = await api.addEffect(formData);
    return data;
  } catch (error) {
    console.error("add_effects ====>>>> ", error);
    return errorResponse(error);
  }
};
export const update_effects = async (_payload, _id) => {
  try {
    let formData = setFormData(_payload);
    const { data } = await api.updateEffect(formData, _id);
    return data;
  } catch (error) {
    console.error("update_effects ====>>>> ", error);
    return errorResponse(error);
  }
};
export const delete_effects = async (_id) => {
  try {
    const { data } = await api.deleteEffect(_id);
    return data;
  } catch (error) {
    console.error("delete_effects ====>>>> ", error);
    return errorResponse(error);
  }
};
export const delete_all_effects = async (_payload) => {
  try {
    const { data } = await api.deleteAllEffect(_payload);
    return data;
  } catch (error) {
    console.error("delete_all_effects ====>>>> ", error);
    return errorResponse(error);
  }
};
export const get_fontlist_data = async (
  _currentPage,
  _perPage,
  _search,
  _filterDropdown
) => {
  try {
    let getData;
    if (_currentPage || _perPage || _search || _filterDropdown) {
      const { data } = await api.getFontList(
        _currentPage,
        _perPage,
        _search,
        _filterDropdown
      );
      getData = data;
    } else {
      const { data } = await api.getFontList();
      getData = data;
    }
    return getData;
  } catch (error) {
    console.error("get_fontlist_data ====>>>> ", error);
    return errorResponse(error);
  }
};
export const add_fontlist = async (_payload) => {
  try {
    let formData = setFormData(_payload);
    const { data } = await api.addFontList(formData);
    return data;
  } catch (error) {
    console.error("add_fontlist ====>>>> ", error);
    return errorResponse(error);
  }
};
export const update_fontlist = async (_payload, _id) => {
  try {
    let formData = setFormData(_payload);
    const { data } = await api.updateFontList(formData, _id);
    return data;
  } catch (error) {
    console.error("update_fontlist ====>>>> ", error);
    return errorResponse(error);
  }
};
export const delete_fontlist = async (_id) => {
  try {
    const { data } = await api.deleteFontLists(_id);
    return data;
  } catch (error) {
    console.error("delete_fontlist ====>>>> ", error);
    return errorResponse(error);
  }
};
export const delete_all_fontlist = async (_payload) => {
  try {
    const { data } = await api.deleteAllFontlist(_payload);
    return data;
  } catch (error) {
    console.error("delete_all_fontlist ====>>>> ", error);
    return errorResponse(error);
  }
};
export const get_sounds_data = async (
  _currentPage,
  _perPage,
  _search,
  _filterDropdown
) => {
  try {
    let getData;
    if (_currentPage || _perPage || _search || _filterDropdown) {
      const { data } = await api.getSounds(
        _currentPage,
        _perPage,
        _search,
        _filterDropdown
      );
      getData = data;
    } else {
      const { data } = await api.getSounds();
      getData = data;
    }
    return getData;
  } catch (error) {
    console.error("get_sounds_data ====>>>> ", error);
    return errorResponse(error);
  }
};
export const add_sounds = async (_payload) => {
  try {
    let formData = setFormData(_payload);
    const { data } = await api.addSounds(formData);
    return data;
  } catch (error) {
    console.error("add_sounds ====>>>> ", error);
    return errorResponse(error);
  }
};
export const update_sounds = async (_payload, _id) => {
  try {
    let formData = setFormData(_payload);
    const { data } = await api.updateSounds(formData, _id);
    return data;
  } catch (error) {
    console.error("update_sounds ====>>>> ", error);
    return errorResponse(error);
  }
};
export const delete_sounds = async (_id) => {
  try {
    const { data } = await api.deleteSounds(_id);
    return data;
  } catch (error) {
    console.error("delete_sounds ====>>>> ", error);
    return errorResponse(error);
  }
};
export const delete_all_sounds = async (_payload) => {
  try {
    const { data } = await api.deleteAllSoundlist(_payload);
    return data;
  } catch (error) {
    console.error("delete_all_sounds ====>>>> ", error);
    return errorResponse(error);
  }
};
export const get_keys_data = async (
  _currentPage,
  _perPage,
  _search,
  _filterDropdown
) => {
  try {
    let getData;
    if (_currentPage || _perPage || _search || _filterDropdown) {
      const { data } = await api.getKey(
        _currentPage,
        _perPage,
        _search,
        _filterDropdown
      );
      getData = data;
    } else {
      const { data } = await api.getKey();
      getData = data;
    }
    return getData;
  } catch (error) {
    console.error("get_keys_data ====>>>> ", error);
    return errorResponse(error);
  }
};
export const add_keys = async (_payload) => {
  try {
    let formData = setFormData(_payload);
    const { data } = await api.addKey(formData);
    return data;
  } catch (error) {
    console.error("add_keys ====>>>> ", error);
    return errorResponse(error);
  }
};
export const update_keys = async (_payload, _id) => {
  try {
    let formData = setFormData(_payload);
    const { data } = await api.updateKey(formData, _id);
    return data;
  } catch (error) {
    console.error("update_keys ====>>>> ", error);
    return errorResponse(error);
  }
};
export const delete_keys = async (_id) => {
  try {
    const { data } = await api.deleteKey(_id);
    return data;
  } catch (error) {
    console.error("delete_keys ====>>>> ", error);
    return errorResponse(error);
  }
};
export const delete_all_keys = async (_payload) => {
  try {
    const { data } = await api.deleteAllKey(_payload);
    return data;
  } catch (error) {
    console.error("delete_all_keys ====>>>> ", error);
    return errorResponse(error);
  }
};
export const get_textart_data = async (
  _currentPage,
  _perPage,
  _search,
  _filterDropdown
) => {
  try {
    let getData;
    if (_currentPage || _perPage || _search || _filterDropdown) {
      const { data } = await api.getTextArt(
        _currentPage,
        _perPage,
        _search,
        _filterDropdown
      );
      getData = data;
    } else {
      const { data } = await api.getTextArt();
      getData = data;
    }
    return getData;
  } catch (error) {
    console.error("get_textart_data ====>>>> ", error);
    return errorResponse(error);
  }
};
export const add_textart = async (_payload) => {
  try {
    let formData = setFormData(_payload);
    const { data } = await api.addTextArt(formData);
    return data;
  } catch (error) {
    console.error("add_textart ====>>>> ", error);
    return errorResponse(error);
  }
};
export const update_textart = async (_payload, _id) => {
  try {
    let formData = setFormData(_payload);
    const { data } = await api.updateTextArt(formData, _id);
    return data;
  } catch (error) {
    console.error("update_textart ====>>>> ", error);
    return errorResponse(error);
  }
};
export const delete_textart = async (_id) => {
  try {
    const { data } = await api.deleteTextArt(_id);
    return data;
  } catch (error) {
    console.error("delete_textart ====>>>> ", error);
    return errorResponse(error);
  }
};
export const delete_all_textart = async (_payload) => {
  try {
    const { data } = await api.deleteAllTextArt(_payload);
    return data;
  } catch (error) {
    console.error("delete_all_textart ====>>>> ", error);
    return errorResponse(error);
  }
};
export const get_textart_categories_data = async (
  _currentPage,
  _perPage,
  _search,
  _filterDropdown
) => {
  try {
    let getData;
    if (_currentPage || _perPage || _search || _filterDropdown) {
      const { data } = await api.getTextArtCategory(
        _currentPage,
        _perPage,
        _search,
        _filterDropdown
      );
      getData = data;
    } else {
      const { data } = await api.getTextArtCategory();
      getData = data;
    }
    return getData;
  } catch (error) {
    console.error("get_textart_categories_data ====>>>> ", error);
    return errorResponse(error);
  }
};
export const add_textart_categories = async (_payload) => {
  try {
    let formData = setFormData(_payload);
    const { data } = await api.addTextArtCategory(formData);
    return data;
  } catch (error) {
    console.error("add_textart_categories ====>>>> ", error);
    return errorResponse(error);
  }
};
export const update_textart_categories = async (_payload, _id) => {
  try {
    let formData;
    if (_payload.categoryBinFile) {
      formData = setFormData(_payload);
    } else {
      formData = _payload;
    }
    const { data } = await api.updateTextArtCategory(formData, _id);
    return data;
  } catch (error) {
    console.error("update_textart_categories ====>>>> ", error);
    return errorResponse(error);
  }
};
export const delete_textart_categories = async (_id) => {
  try {
    const { data } = await api.deleteTextArtCategory(_id);
    return data;
  } catch (error) {
    console.error("delete_textart_categories ====>>>> ", error);
    return errorResponse(error);
  }
};
export const delete_all_textart_categories = async (_payload) => {
  try {
    const { data } = await api.deleteAllTextArtCategory(_payload);
    return data;
  } catch (error) {
    console.error("delete_all_textart_categories ====>>>> ", error);
    return errorResponse(error);
  }
};
export const get_kamoji_data = async (
  _currentPage,
  _perPage,
  _search,
  _filterDropdown
) => {
  try {
    let getData;
    if (_currentPage || _perPage || _search || _filterDropdown) {
      const { data } = await api.getKamoji(
        _currentPage,
        _perPage,
        _search,
        _filterDropdown
      );
      getData = data;
    } else {
      const { data } = await api.getKamoji();
      getData = data;
    }
    return getData;
  } catch (error) {
    console.error("get_kamoji_data ====>>>> ", error);
    return errorResponse(error);
  }
};
export const add_kamoji = async (_payload) => {
  try {
    const { data } = await api.addKamoji(_payload);
    return data;
  } catch (error) {
    console.error("add_kamoji ====>>>> ", error);
    return errorResponse(error);
  }
};
export const update_kamoji = async (_payload, _id) => {
  try {
    const { data } = await api.updateKamoji(_payload, _id);
    return data;
  } catch (error) {
    console.error("update_kamoji ====>>>> ", error);
    return errorResponse(error);
  }
};
export const delete_kamoji = async (_id) => {
  try {
    const { data } = await api.deleteKamoji(_id);
    return data;
  } catch (error) {
    console.error("delete_kamoji ====>>>> ", error);
    return errorResponse(error);
  }
};
export const delete_all_kamoji = async (_payload) => {
  try {
    const { data } = await api.deleteAllKamoji(_payload);
    return data;
  } catch (error) {
    console.error("delete_all_kamoji ====>>>> ", error);
    return errorResponse(error);
  }
};
export const get_fancy_fonts_data = async (
  _currentPage,
  _perPage,
  _search,
  _filterDropdown
) => {
  try {
    let getData;
    if (_currentPage || _perPage || _search || _filterDropdown) {
      const { data } = await api.getFancyFont(
        _currentPage,
        _perPage,
        _search,
        _filterDropdown
      );
      getData = data;
    } else {
      const { data } = await api.getFancyFont();
      getData = data;
    }
    return getData;
  } catch (error) {
    console.error("get_fancy_fonts_data ====>>>> ", error);
    return errorResponse(error);
  }
};
export const add_fancy_fonts = async (_payload) => {
  try {
    let formData = setFormData(_payload);
    const { data } = await api.addFancyFont(formData);
    return data;
  } catch (error) {
    console.error("add_fancy_fonts ====>>>> ", error);
    return errorResponse(error);
  }
};
export const update_fancy_fonts = async (_payload, _id) => {
  try {
    let formData = setFormData(_payload);
    const { data } = await api.updateFancyFont(formData, _id);
    return data;
  } catch (error) {
    console.error("update_fancy_fonts ====>>>> ", error);
    return errorResponse(error);
  }
};
export const delete_fancy_fonts = async (_id) => {
  try {
    const { data } = await api.deleteFancyFont(_id);
    return data;
  } catch (error) {
    console.error("delete_fancy_fonts ====>>>> ", error);
    return errorResponse(error);
  }
};
export const delete_all_fancy_fonts = async (_payload) => {
  try {
    const { data } = await api.deleteAllFancyFont(_payload);
    return data;
  } catch (error) {
    console.error("delete_all_fancy_fonts ====>>>> ", error);
    return errorResponse(error);
  }
};
export const get_sticker_store_data = async (
  _currentPage,
  _perPage,
  _search,
  _filterDropdown
) => {
  try {
    let getData;
    if (_currentPage || _perPage || _search || _filterDropdown) {
      const { data } = await api.getStickerStore(
        _currentPage,
        _perPage,
        _search,
        _filterDropdown
      );
      getData = data;
    } else {
      const { data } = await api.getStickerStore();
      getData = data;
    }
    return getData;
  } catch (error) {
    console.error("get_sticker_store_data ====>>>> ", error);
    return errorResponse(error);
  }
};
export const add_sticker_store = async (_payload) => {
  try {
    let formData = setFormData(_payload);
    const { data } = await api.addStickerStore(formData);
    return data;
  } catch (error) {
    console.error("add_sticker_store ====>>>> ", error);
    return errorResponse(error);
  }
};
export const update_sticker_store = async (_payload, _id) => {
  try {
    let formData;
    if (_payload.smallPreviewStickerStore) {
      formData = setFormData(_payload);
    } else {
      formData = _payload;
    }
    const { data } = await api.updateStickerStore(formData, _id);
    return data;
  } catch (error) {
    console.error("update_sticker_store ====>>>> ", error);
    return errorResponse(error);
  }
};
export const delete_sticker_store = async (_id) => {
  try {
    const { data } = await api.deleteStickerStore(_id);
    return data;
  } catch (error) {
    console.error("delete_sticker_store ====>>>> ", error);
    return errorResponse(error);
  }
};
export const delete_all_sticker_store = async (_payload) => {
  try {
    const { data } = await api.deleteAllStickerStore(_payload);
    return data;
  } catch (error) {
    console.error("delete_all_sticker_store ====>>>> ", error);
    return errorResponse(error);
  }
};
export const get_community_user_data = async (
  _currentPage,
  _perPage,
  _search,
  _filterDropdown
) => {
  try {
    let getData;
    if (_currentPage || _perPage || _search || _filterDropdown) {
      const { data } = await api.getCommunityUser(
        _currentPage,
        _perPage,
        _search,
        _filterDropdown
      );
      getData = data;
    } else {
      const { data } = await api.getCommunityUser();
      getData = data;
    }
    return getData;
  } catch (error) {
    console.error("get_community_user_data ====>>>> ", error);
    return errorResponse(error);
  }
};
export const getOneThemeOffensiveReport = async (_id) => {
  try {
    const { data } = await api.getOneThemeOffensiveReport(_id);
    return data;
  } catch (error) {
    console.error("delete_community_user ====>>>> ", error);
    return errorResponse(error);
  }
};
export const delete_communityUser = async (_id) => {
  try {
    const { data } = await api.deleteCommunityUser(_id);
    return data;
  } catch (error) {
    console.error("delete_community_user ====>>>> ", error);
    return errorResponse(error);
  }
};
export const delete_all_community_user = async (_payload) => {
  try {
    const { data } = await api.deleteAllCommunityUser(_payload);
    return data;
  } catch (error) {
    console.error("delete_all_community_user ====>>>> ", error);
    return errorResponse(error);
  }
};
export const add_setting = async (_payload) => {
  try {
    const { data } = await api.addSetting(_payload);
    return data;
  } catch (error) {
    console.error("add_setting ====>>>> ", error);
    return errorResponse(error);
  }
};
export const get_wallpaper_data = async (
  _currentPage,
  _perPage,
  _search,
  _filterDropdown
) => {
  try {
    let getData;
    if (_currentPage || _perPage || _search || _filterDropdown) {
      const { data } = await api.getWallpaper(
        _currentPage,
        _perPage,
        _search,
        _filterDropdown
      );
      getData = data;
    } else {
      const { data } = await api.getWallpaper();
      getData = data;
    }
    return getData;
  } catch (error) {
    console.error("get_wallpaper_data ====>>>> ", error);
    return errorResponse(error);
  }
};
export const add_wallpaper = async (_payload) => {
  try {
    let formData = setFormData(_payload);
    const { data } = await api.addWallpaper(formData);
    return data;
  } catch (error) {
    console.error("add_wallpaper ====>>>> ", error);
    return errorResponse(error);
  }
};
export const update_wallpaper = async (_payload, _id) => {
  try {
    let formData = setFormData(_payload);
    const { data } = await api.updateWallpaper(formData, _id);
    return data;
  } catch (error) {
    console.error("update_wallpaper ====>>>> ", error);
    return errorResponse(error);
  }
};
export const delete_wallpaper = async (_id) => {
  try {
    const { data } = await api.deleteWallpaper(_id);
    return data;
  } catch (error) {
    console.error("delete_wallpaper ====>>>> ", error);
    return errorResponse(error);
  }
};
export const delete_all_wallpaper = async (_payload) => {
  try {
    const { data } = await api.deleteAllWallpaper(_payload);
    return data;
  } catch (error) {
    console.error("delete_all_wallpaper ====>>>> ", error);
    return errorResponse(error);
  }
};
export const get_wallpaper_categories_data = async (
  _currentPage,
  _perPage,
  _search,
  _filterDropdown
) => {
  try {
    let categoryData;
    if (_currentPage || _perPage || _search || _filterDropdown) {
      const { data } = await api.getWallpaperCategory(
        _currentPage,
        _perPage,
        _search,
        _filterDropdown
      );
      categoryData = data;
    } else {
      const { data } = await api.getWallpaperCategory();
      categoryData = data;
    }
    return categoryData;
  } catch (error) {
    console.error("get_wallpaper_categories_data ====>>>> ", error);
    return errorResponse(error);
  }
};
export const add_wallpaper_category = async (_payload) => {
  try {
    const data = await api.addWallpaperCategory(_payload);
    return data;
  } catch (error) {
    console.error("add_wallpaper_category ====>>>> ", error);
    return errorResponse(error);
  }
};
export const update_wallpaper_category = async (_payload, _id) => {
  try {
    const { data } = await api.updateWallpaperCategory(_payload, _id);
    return data;
  } catch (error) {
    console.error("update_wallpaper_category ====>>>> ", error);
    return errorResponse(error);
  }
};
export const delete_wallpaper_category = async (_id) => {
  try {
    const { data } = await api.deleteWallpaperCategory(_id);
    return data;
  } catch (error) {
    console.error("delete_wallpaper_category ====>>>> ", error);
    return errorResponse(error);
  }
};
export const pending_all_theme = async (_payload) => {
  try {
    const { data } = await api.pendingAllCommunityTheme(_payload);
    return data;
  } catch (error) {
    console.error("delete_wallpaper_category ====>>>> ", error);
    return errorResponse(error);
  }
};
export const reject_all_theme = async (_payload) => {
  try {
    const { data } = await api.rejectedAllCommunityTheme(_payload);
    return data;
  } catch (error) {
    console.error("delete_wallpaper_category ====>>>> ", error);
    return errorResponse(error);
  }
};
export const approved_all_theme = async (_payload) => {
  try {
    const { data } = await api.approvedAllCommunityTheme(_payload);
    return data;
  } catch (error) {
    console.error("delete_wallpaper_category ====>>>> ", error);
    return errorResponse(error);
  }
};
export const delete_all_wallpaper_category = async (_payload) => {
  try {
    const { data } = await api.deleteAllWallpaperCategory(_payload);
    return data;
  } catch (error) {
    console.error("delete_all_wallpaper_category ====>>>> ", error);
    return errorResponse(error);
  }
};
export const get_history_data = async (
  _currentPage,
  _perPage,
  _search,
  _filterDropdown
) => {
  try {
    let historyData;
    if (_currentPage || _perPage || _search || _filterDropdown) {
      const { data } = await api.getHistory(
        _currentPage,
        _perPage,
        _search,
        _filterDropdown
      );
      historyData = data;
    } else {
      const { data } = await api.getHistory();
      historyData = data;
    }
    return historyData;
  } catch (error) {
    console.error("get_wallpaper_categories_data ====>>>> ", error);
    return errorResponse(error);
  }
};
export const delete_all_history = async (_payload) => {
  try {
    const { data } = await api.deleteAllHistory(_payload);
    return data;
  } catch (error) {
    console.error("delete_all_history ====>>>> ", error);
    return errorResponse(error);
  }
};
export const get_history_filter_data = async () => {
  try {
    const { data } = await api.getHistoryFilterList();
    return data;
  } catch (error) {
    console.error("get_history_user_data ====>>>> ", error);
    return errorResponse(error);
  }
};
export const get_user_data = async () => {
  try {
    const { data } = await api.userDetails();
    return data;
  } catch (error) {
    console.error("get_user_data ====>>>> ", error);
    return errorResponse(error);
  }
};
export const get_storage_data = async (
  _currentPage,
  _perPage,
  _search,
  _filterDropdown
) => {
  try {
    let getData;
    if (_currentPage || _perPage || _search || _filterDropdown) {
      const { data } = await api.getStorage(
        _currentPage,
        _perPage,
        _search,
        _filterDropdown
      );
      getData = data;
    } else {
      const { data } = await api.getStorage();
      getData = data;
    }
    return getData;
  } catch (error) {
    console.error("get_themes_data ====>>>> ", error);
    return errorResponse(error);
  }
};
export const delete_storage = async (_id) => {
  try {
    const { data } = await api.deleteStorage(_id);
    return data;
  } catch (error) {
    console.error("deleteApprovedTheme ====>>>> ", error);
    return errorResponse(error);
  }
};
export const remove_common_storage = async (label) => {
  try {
    const { data } = await api.removeCommonStorage(label);
    return data;
  } catch (error) {
    console.error("deleteApprovedTheme ====>>>> ", error);
    return errorResponse(error);
  }
};
export const delete_all_storage = async (_payload) => {
  try {
    const { data } = await api.deleteAllStorage(_payload);
    return data;
  } catch (error) {
    console.error("delete_all_approvedTheme ====>>>> ", error);
    return errorResponse(error);
  }
};
export const remove_all_community_extra_files = async () => {
  try {
    const { data } = await api.removeAllCommunityExtraFiles();
    return data;
  } catch (error) {
    console.error("delete_all_approvedTheme ====>>>> ", error);
    return errorResponse(error);
  }
};
export const get_common_storage_data = async () => {
  try {
    const { data } = await api.getCommonStorage();
    return data;
  } catch (error) {
    console.error("delete_all_approvedTheme ====>>>> ", error);
    return errorResponse(error);
  }
};
export const get_community_storage_chart = async (_filterDropdown) => {
  try {
    let getData;
    if (_filterDropdown) {
      const { data } = await api.getCommunityStorageChart(_filterDropdown);
      getData = data;
    } else {
      const { data } = await api.getCommunityStorageChart();
      getData = data;
    }
    return getData;
  } catch (error) {
    console.error("get_themes_data ====>>>> ", error);
    return errorResponse(error);
  }
};

//Blogs
export const get_blog = async (
  _currentPage,
  _perPage,
  _search,
  _filterDropdown
) => {
  try {
    let blogData;
    if (_currentPage || _perPage || _search || _filterDropdown) {
      const { data } = await api.getBlog(
        _currentPage,
        _perPage,
        _search,
        _filterDropdown
      );
      blogData = data;
    } else {
      const { data } = await api.getBlog();
      blogData = data;
    }
    return blogData;
  } catch (error) {
    return errorResponse(error);
  }
};
export const update_blog = async (_payload, _id) => {
  try {
    let formData;
    if (_payload.blogPreview) {
      formData = setFormData(_payload);
    } else {
      formData = _payload;
    }
    const { data } = await api.updateBlog(formData, _id);
    return data;
  } catch (error) {
    return errorResponse(error);
  }
};
export const getOne_blog = async (_id) => {
  try {
    const { data } = await api.getOneBlog(_id);
    return data;
  } catch (error) {
    console.error("getOneBlog ====>>>> ", error);
    return errorResponse(error);
  }
};
export const add_blog = async (_payload) => {
  try {
    const formData = setFormData(_payload);
    const { data } = await api.addBlog(formData);
    return data;
  } catch (error) {
    return errorResponse(error);
  }
};
export const delete_blog = async (_id) => {
  try {
    const { data } = await api.deleteBlog(_id);
    return data;
  } catch (error) {
    return errorResponse(error);
  }
};
export const deleteAll_blog = async (_payload) => {
  try {
    const { data } = await api.deleteAllBlog(_payload);
    return data;
  } catch (error) {
    return errorResponse(error);
  }
};
//FAQ
export const add_faq = async (_payload) => {
  try {
    const formData = setFormData(_payload);
    const { data } = await api.addFAQ(formData);
    return data;
  } catch (error) {
    return errorResponse(error);
  }
};

export const update_faq = async (_payload, _id) => {
  try {
    let formData;
    if (_payload.media) {
      formData = setFormData(_payload);
    } else {
      formData = _payload;
    }
    const { data } = await api.updateFAQ(formData, _id);
    return data;
  } catch (error) {
    return errorResponse(error);
  }
};

export const get_faq = async (_filterDropdown) => {
  try {
    let CommunityData;
    if (_filterDropdown) {
      const { data } = await api.getFAQ(_filterDropdown);
      CommunityData = data
    } else {
      const { data } = await api.getFAQ();
      CommunityData = data
    }
    return CommunityData;
  } catch (error) {
    return errorResponse(error);
  }
};

export const get_one_faq = async (_id) => {
  try {
    const { data } = await api.getOneFAQ(_id);
    return data;
  } catch (error) {
    return errorResponse(error);
  }
};

export const delete_faq = async (_id) => {
  try {
    const { data } = await api.deleteFAQ(_id);
    return data;
  } catch (error) {
    return errorResponse(error);
  }
};

export const delete_media_faq = async (_payload) => {
  try {
    const { data } = await api.deleteMediaFAQ(_payload);
    return data;
  } catch (error) {
    return errorResponse(error);
  }
};

export const update_faq_priority = async (_payload) => {
  try {
    const { data } = await api.updateFaqPriority(_payload);
    return data;
  } catch (error) {
    return errorResponse(error);
  }
};

export const updateFaq_media_priority = async (_payload) => {
  try {
    const { data } = await api.updateFaqMediaPriority(_payload);
    return data;
  } catch (error) {
    return errorResponse(error);
  }
};

// Survey
export const add_survey = async (_payload) => {
  try {
    // const formData = setFormData(_payload);
    const { data } = await api.addSurvey(_payload);
    return data;
  } catch (error) {
    return errorResponse(error);
  }
};

export const get_survey = async (_filterDropdown) => {
  try {
    let CommunityData;
    if (_filterDropdown) {
      const { data } = await api.getSurvey(_filterDropdown);
      CommunityData = data
    } else {
      const { data } = await api.getSurvey();
      CommunityData = data
    }
    return CommunityData;
  } catch (error) {
    return errorResponse(error);
  }
};

export const delete_survey = async (_id) => {
  try {
    const { data } = await api.deleteSurvey(_id);
    return data;
  } catch (error) {
    return errorResponse(error);
  }
};

export const update_survey = async (_payload, _id) => {
  try {
    const { data } = await api.updateSurvey(_payload, _id);
    return data;
  } catch (error) {
    return errorResponse(error);
  }
};

export const survey_analysis = async (_filterDropdown) => {
  try {
    let CommunityData;
    if (_filterDropdown) {
      const { data } = await api.surveyAnalysis(_filterDropdown);
      CommunityData = data
    } else {
      const { data } = await api.surveyAnalysis();
      CommunityData = data
    }
    return CommunityData;
  } catch (error) {
    return errorResponse(error);
  }
};

// asset
export const add_asset = async (_payload) => {
  try {
    const { data } = await api.addAsset(_payload);
    return data;
  } catch (error) {
    return errorResponse(error);
  }
};

export const get_asset = async (_currentPage,
  _perPage,) => {
  try {
    let CommunityData;

    if (_currentPage || _perPage) {
      const { data } = await api.getAsset(
        _currentPage,
        _perPage,
      );
      CommunityData = data;
    } else {
      const { data } = await api.getAsset();
      CommunityData = data
    }
    return CommunityData;
  } catch (error) {
    return errorResponse(error);
  }
};

export const update_asset = async (_payload, _id) => {
  try {
    const { data } = await api.updateAsset(_payload, _id);
    return data;
  } catch (error) {
    return errorResponse(error);
  }
};


export const delete_asset = async (_id) => {
  try {
    const { data } = await api.deleteAsset(_id);
    return data;
  } catch (error) {
    return errorResponse(error);
  }
};
export const get_asset_link = async () => {
  try {
    let CommunityData;
    const { data } = await api.getAssetLink();
    CommunityData = data
    return CommunityData;
  } catch (error) {
    return errorResponse(error);
  }
};

//Blogs categories
export const get_blog_categories = async (
  _currentPage,
  _perPage,
  _search,
  _filterDropdown
) => {
  try {
    let CommunityData;
    if (_currentPage || _perPage || _search || _filterDropdown) {
      const { data } = await api.getBlogCategories(
        _currentPage,
        _perPage,
        _search,
        _filterDropdown
      );
      CommunityData = data;
    } else {
      const { data } = await api.getBlogCategories();
      CommunityData = data;
    }
    return CommunityData;
  } catch (error) {
    return errorResponse(error);
  }
};
export const update_blog_categories = async (_payload, _id) => {
  try {
    const { data } = await api.updateBlogCategories(_payload, _id);
    return data;
  } catch (error) {
    return errorResponse(error);
  }
};
export const add_blog_categories = async (_payload) => {
  try {
    const { data } = await api.addBlogCategories(_payload);
    return data;
  } catch (error) {
    return errorResponse(error);
  }
};
export const delete_blog_categories = async (_id) => {
  try {
    const { data } = await api.deleteBlogCategories(_id);
    return data;
  } catch (error) {
    return errorResponse(error);
  }
};
export const deleteAll_blog_categories = async (_payload) => {
  try {
    const { data } = await api.deleteAllBlogCategories(_payload);
    return data;
  } catch (error) {
    return errorResponse(error);
  }
};

//Blogs Comments
export const get_blog_comments = async (
  _currentPage,
  _perPage,
  _search,
  _filterDropdown
) => {
  try {
    let CommunityData;
    if (_currentPage || _perPage || _search || _filterDropdown) {
      const { data } = await api.getBlogComments(
        _currentPage,
        _perPage,
        _search,
        _filterDropdown
      );
      CommunityData = data;
    } else {
      const { data } = await api.getBlogComments();
      CommunityData = data;
    }
    return CommunityData;
  } catch (error) {
    return errorResponse(error);
  }
};
export const delete_reply = async (_id) => {
  try {
    const { data } = await api.deleteReply(_id);
    return data;
  } catch (error) {
    return errorResponse(error);
  }
};
export const deleteAll_reply = async (_payload) => {
  try {
    const { data } = await api.deleteAllReply(_payload);
    return data;
  } catch (error) {
    return errorResponse(error);
  }
};

export const zip_validation = async (_payload) => {
  try {
    let formData = setFormData(_payload);
    const { data } = await api.zipValidation(formData);
    return data;
  } catch (error) {
    console.error("add_effects ====>>>> ", error);
    return errorResponse(error);
  }
};
