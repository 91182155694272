import React, { useContext, useEffect, useState } from 'react'
import { toast } from 'react-toastify';

import { Button, Spinner, Typography } from "@material-tailwind/react";
import { NewContext } from '../../Context/NewContext';
import Switch from '../Switch/Switch';

import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { DialogActions } from '@mui/material';
import { useNavigate } from 'react-router-dom';

const PermissionLogoutModel = () => {
    const { openModal, handleLogout, handleModalClose } = useContext(NewContext);
    const navigate = useNavigate();
    const handleProceedLogout = () => {
        handleLogout();
        navigate(`/signin`);
    }
    return (
        <>
            <Dialog
                open={openModal}
                // onBackdropClick={(e) => e.preventDefault()}
                onClose={handleModalClose}
                aria-labelledby="responsive-dialog-title"
                sx={{
                    "& .MuiDialog-container": {
                        "& .MuiPaper-root": {
                            width: "100%",
                            maxWidth: "550px",
                            borderRadius: "12px" // Set your width here
                        },
                    },
                }}
            >
                <DialogTitle id="responsive-dialog-title" className='p-0 mb-[30px] text-[#1E1627]'>
                    Access Revoked!
                </DialogTitle>
                <DialogContent>
                    <Typography className="text-center font-normal leading-3 text-[17px] text-[#1E1627]">
                        Your access to this panel has been revoked by the Admin,
                        <br />
                        Click below button to Exit this panel
                    </Typography>
                </DialogContent>
                <DialogActions className="flex gap-2.5 !justify-center p-0">
                    <Button
                        onClick={handleProceedLogout}
                        className="flex items-center mr-1 px-5 py-2.5 bg-[#0047FF] shadow-none text-white font-medium border border-[#0047FF] hover:shadow-none capitalize text-base"
                    >
                        <span>Proceed</span>
                    </Button>
                </DialogActions>
            </Dialog >
        </>
    )
}

export default PermissionLogoutModel
