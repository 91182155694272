import React, { useContext, useEffect, useState } from "react";
import { Flip, ToastContainer, toast } from "react-toastify";
import {
  HashRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";

import "./App.css";
import "react-toastify/dist/ReactToastify.css";
import "react-medium-image-zoom/dist/styles.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import routes from "./routes";
import { NewContext } from "./Context/NewContext";

import { Layout, ProtectedRoutes } from "./Components";
import {
  ApprovedThemes,
  Dashboard,
  FancyFont,
  History,
  NotFound,
  PendingThemes,
  Redirectional,
  RejectThemes,
  Report,
  SignIn,
  Sliders,
  Sounds,
  StickerStore,
  TextArt,
  UserFeedback,
  Users,
  Wallpaper,
  WallpaperCategories,
  Kamoji,
  TextArtCategories,
  HomeActivity,
  Storage,
  CommunityStorage,
} from "./Pages";
import {
  ThemeBadges,
  ThemeStores,
  ThemeTab,
  ThemeTags,
  ThemesCategories,
} from "./Pages/Themes";

import {
  Background,
  BackgroundCategories,
  Effects,
  FontList,
  Keys,
} from "./Pages/DIYs";
import ConfirmationModal from "./Components/Modals/ConfirmationModal";
import {
  Banners,
  BannersRedirectional,
  CustomSection,
  Functionality,
} from "./Pages/HomeActivity";
import Reason from "./Pages/Community/Report/Reason";
import { check_user_permissions, get_user_data } from "./Controller/actions";
import Splash from "./Pages/Splash/Splash";
import PermissionLogoutModel from "./Components/Modals/PermissionLogoutModel";
import Blogs from "./Pages/Blogs/Blogs";
import BlogCategories from "./Pages/Blogs/BlogCategories";
import NewBlogs from "./Pages/Blogs/add/NewBlogs";
import Comments from "./Pages/Blogs/Comments";
import Faq from "./Pages/FAQ/Faq";
import Survey from "./Pages/Survey/Survey";
import SurveyAnalysis from "./Pages/Survey/SurveyAnalysis";
import Asset from "./Pages/Assets/Asset";
import ZipValidation from "./Pages/ZipValidation/ZipValidation";
import PreviewBlog from "./Components/Modals/Blog/PreviewBlog";

const socket = require("socket.io-client").connect(
  process.env.REACT_APP_SOCKET_URL,
  { transports: ["polling"] }
);

export const NotAuthorized = () => {
  toast.warn(`Not Authorized!`);
  return <Navigate to={"/"} />;
};

export const App = () => {
  const [token, setToken] = useState(localStorage.getItem("token"));
  const {
    setOpenModal,
    tokenType,
    permissions,
    handlePermissions,
    setExpiryTime,
  } = useContext(NewContext);
  const [currentPath, setCurrentPath] = useState("");
  const [currentRoutes, setCurrentRoutes] = useState(routes.sidebarRoutes);
  let [homeScreenRoute, setHomeScreenRoute] = useState("");

  useEffect(() => {
    window.addEventListener("error", (e) => {
      if (e.message === "ResizeObserver loop limit exceeded") {
        const resizeObserverErrDiv = document.getElementById(
          "webpack-dev-server-client-overlay-div"
        );
        const resizeObserverErr = document.getElementById(
          "webpack-dev-server-client-overlay"
        );
        if (resizeObserverErr) {
          resizeObserverErr.setAttribute("style", "display: none");
        }
        if (resizeObserverErrDiv) {
          resizeObserverErrDiv.setAttribute("style", "display: none");
        }
      }
    });
  }, [token]);

  useEffect(() => {
    if (localStorage.getItem("loginType") === "standard") {
      setHomeScreenRoute(undefined);
      setCurrentPath("/dashboard");
    }

    if (permissions?.subPermissions) {
      const updatedRoutes = currentRoutes?.filter((route) =>
        permissions?.subPermissions?.some((permission) => {
          if (route?.key === permission?.key) return permission;
          if (route?.items) {
            route.items = route?.items?.filter((route) =>
              permissions?.subPermissions?.some(
                (itemPermission) => route?.key === itemPermission?.key
              )
            );
            if (route.items.length) {
              return route;
            }
          }
        })
      );

      setCurrentRoutes(updatedRoutes);
      setHomeScreenRoute(
        updatedRoutes.find((route) => route?.path === "/dashboard")
      );
      homeScreenRoute = updatedRoutes.find(
        (route) => route?.path === "/dashboard"
      );
      if (!homeScreenRoute) {
        const availableRoute = updatedRoutes[0];
        const firstObj = availableRoute?.path;
        setCurrentPath(firstObj);
      }
    }

    if (
      permissions &&
      permissions?.subPermissions &&
      permissions?.subPermissions.length === 0
    ) {
      setOpenModal(true);
    } else {
      setOpenModal(false);
    }
  }, [permissions]);

  const fetchPermissions = async () => {
    const permissionsData = await check_user_permissions();
    const { status, data } = permissionsData;
    if (status) {
      if (
        data?.length > 0 &&
        data[0]?.subPermissions &&
        data[0]?.subPermissions?.length > 0
      ) {
        setOpenModal(false);
      } else {
        setOpenModal(true);
      }
    }
  };

  useEffect(() => {
    if (localStorage.getItem("loginType") === "ldap") {
      fetchPermissions();
    }
  }, [localStorage.getItem("loginType")]);

  useEffect(() => {
    if (permissions?.subPermissions) {
      const data = localStorage.getItem("token");
      const [_, payload, _$] = data?.split(".");
      handlePermissions(JSON.parse(atob(payload))?.permission);
      socket.emit("join", { userId: JSON.parse(atob(payload))?._id });
    }
  }, []);

  useEffect(() => {
    socket.on(process.env.REACT_APP_PROJECT_ID, async (data) => {
      localStorage.setItem("token", data);
      const [_, payload, _$] = data?.split(".");
      handlePermissions(JSON.parse(atob(payload))?.permission);
      return () => socket.off(process.env.REACT_APP_PROJECT_ID);
    });
  }, [socket]);

  useEffect(() => {
    if (localStorage.getItem("token")) {
      setExpiryTime(
        Date.now() + process.env.REACT_APP_SESSION_TIMEOUT_DURATION * 60 * 1000
      );
    }
  }, []);

  useEffect(() => {
    const handleActivity = () => {
      setExpiryTime(
        Date.now() + process.env.REACT_APP_SESSION_TIMEOUT_DURATION * 60 * 1000
      );
    };

    document.addEventListener("click", handleActivity);
    document.addEventListener("keydown", handleActivity);

    return () => {
      document.removeEventListener("click", handleActivity);
      document.removeEventListener("keydown", handleActivity);
    };
  }, []);

  return (
    <>
      <ToastContainer
        position="bottom-center"
        transition={Flip}
        hideProgressBar={true}
        pauseOnFocusLoss={false}
        autoClose={400}
        style={{ zIndex: "9999999" }}
        theme="dark"
        className={`w-auto`}
      />
      <Router>
        <Routes>
          <Route exact path="/" element={<ProtectedRoutes />}>
            <Route element={<Layout />}>
              {!homeScreenRoute ? (
                <Route index element={<Navigate to={currentPath} />} />
              ) : (
                <Route index element={<Navigate to={"/dashboard"} />} />
              )}
              {/* <Route index element={<Navigate to={'/dashboard'} />} /> */}
              <Route exact path="/dashboard" element={<Dashboard />} />
              <Route
                exact
                path="/homescreen"
                element={<HomeActivity />
                }
              />
              <Route
                exact
                path="/customsection"
                element={<CustomSection />
                }
              />
              <Route
                exact
                path="/functionality"
                element={<Functionality />
                }
              />
              <Route
                exact
                path="/banners"
                element={<Banners />
                }
              />
              <Route exact path="/previewBlogs" element={<PreviewBlog />} />
              <Route
                exact
                path="/redirectional"
                element={<BannersRedirectional />
                }
              />
              <Route exact path="/sliders" element={<Sliders />} />
              <Route exact path="/redirectional" element={<Redirectional />} />
              <Route exact path="/themestore" element={<ThemeStores />} />
              <Route
                exact
                path="/themecategories"
                element={<ThemesCategories />}
              />
              <Route exact path="/themetags" element={<ThemeTags />} />
              <Route exact path="/themetabs" element={<ThemeTab />} />
              {/* <Route exact path="/themebadges" element={<ThemeBadges />} /> */}
              <Route exact path="/backgrounds" element={<Background />} />
              <Route
                exact
                path="/backgroundcategories"
                element={<BackgroundCategories />}
              />
              <Route exact path="/effects" element={<Effects />} />
              <Route exact path="/fontlists" element={<FontList />} />
              <Route exact path="/keys" element={<Keys />} />
              <Route exact path="/fancyfonts" element={<FancyFont />} />
              <Route exact path="/textart" element={<TextArt />} />
              <Route
                exact
                path="/textartcategory"
                element={<TextArtCategories />}
              />
              <Route exact path="/kamoji" element={<Kamoji />} />
              <Route exact path="/sounds" element={<Sounds />} />
              <Route exact path="/stickerstore" element={<StickerStore />} />
              {/* <Route exact path="/wallpapers" element={<Wallpaper />} />
                <Route exact path="/wallpapercategories" element={< WallpaperCategories />} /> */}
              <Route
                exact
                path="/approved-themes"
                element={<ApprovedThemes />}
              />
              <Route exact path="/pending-themes" element={<PendingThemes />} />
              <Route exact path="/reject-themes" element={<RejectThemes />} />
              <Route exact path="/community-report" element={<Report />} />
              <Route exact path="/community-Reason" element={<Reason />} />
              <Route exact path="/community-users" element={<Users />} />
              <Route exact path="/storage" element={<Storage />} />
              <Route
                exact
                path="/communityStorage"
                element={<CommunityStorage />}
              />
              {/* <Route exact path="/userfeedback" element={<UserFeedback />} /> */}

              <Route exact path="/blogs" element={<Blogs />} />
              <Route exact path="/categories" element={<BlogCategories />} />
              <Route exact path="/addBlogs" element={<NewBlogs />} />
              <Route exact path="/editBlogs" element={<NewBlogs />} />
              <Route exact path="/comment" element={<Comments />} />
              <Route exact path="/history" element={<History />} />
              <Route exact path="/faq" element={<Faq />} />
              <Route exact path="/survey" element={<Survey />} />
              <Route exact path="/surveyAnalysis" element={<SurveyAnalysis />} />
              <Route exact path="/asset" element={<Asset />} />
              <Route exact path="/zip-validation" element={<ZipValidation />} />
            </Route >
          </Route >

          <Route
            exact
            path="/splash/:loginType/:userName/:token"
            element={<Splash />}
          />
          <Route
            exact
            path="/signin"
            element={token ? <Navigate to={"/"} /> : <SignIn socket={socket} />}
          />
          <Route exact path="/notfound" element={<NotFound />} />
          <Route path="*" element={<Navigate to="/notfound" />} />
        </Routes >
        <ConfirmationModal />
        <PermissionLogoutModel />
      </Router >
    </>
  );
};

export default App;
