import React, { useContext, useEffect, useState } from 'react'
import { Button } from '@material-tailwind/react'
import { AiFillInfoCircle, AiOutlinePlusSquare } from 'react-icons/ai'
import { TbInfoSquareRoundedFilled, TbWorld } from 'react-icons/tb'

import { NewContext } from '../../Context/NewContext'
import PerpageAndDeleteBtn from '../PerpageAndDeleteBtn/PerpageAndDeleteBtn'
import Dropdowns from '../Dropdowns/Dropdowns'
import { get_country_data, get_region_data } from '../../Controller/actions'
import { toast } from 'react-toastify'
import Switch from '../Switch/Switch'
import { MdOutlineArrowBackIosNew, MdOutlineCloudUpload } from 'react-icons/md'
import { Link, useNavigate } from 'react-router-dom'

export const SearchAndAddBtn = ({ infoBtn = false, backBtn = false, updateBtn = false, isSearch = true, addLink = false, title, addBtn = true, countryFilter = true, recommendedLoading = false, FilterDropdowns, delBtn, resetFilterBtn, perPageCount, deleteUpdateTag, redirectionBtn = false, movePending = false, movePendingTag, moveRejected = false, moveRejectedTag, moveAprroved = false, moveApprovedTag, destinationUrl, CustomButton }) => {
    const { setCheckedValues, currentUserId, setCurrentUserId, setCurrentHomeModalKey, deleteUpdateYes, homeScreenData, isLoadMore, handleIsLoadMoreSwitch, setFormData, setDeleteUpdateYes, search, setSearch, setCurrentPage, handleOpen, setConfirmationModalShow, setDeleteUpdateItem, currentHomeModalKey, setFilterDropdown, isShown } = useContext(NewContext);
    const [searchAddTop, setSearchAddTop] = useState(document && (document.getElementById("main-header")?.offsetHeight || 0));
    const [country, setCountry] = useState([]);
    const [region, setRegion] = useState([]);
    const [pageLink, setPageLink] = useState("");
    const [pageState, setPageState] = useState({ state: { isAdd: true } });
    const navigate = useNavigate();
    const getDataFunc = async () => {
        try {
            const countryData = await get_country_data();
            const regionData = await get_region_data();
            if (countryData.status && regionData.status) {
                countryData.data.map(_ele => setCountry(_prev => [..._prev, { id: _ele._id, label: _ele.name }]))
                regionData.data.map(_ele => setRegion(_prev => [..._prev, { id: _ele?._id, label: _ele?.name?.toLowerCase() }]))
            } else {
                toast.error(countryData.message || regionData.message);
            }
        } catch (error) {
            console.error(error);
        };
    };
    useEffect(() => {
        getDataFunc();
    }, []);
    useEffect(() => {
        if (deleteUpdateYes === "update-sectionsettings-priority") {
            let newAry = homeScreenData && homeScreenData.map((_ele, i) => ({ _id: _ele._id, priority: _ele.priority }));
            setDeleteUpdateItem({ updates: newAry });
        }
    }, [deleteUpdateYes, homeScreenData]);
    // useEffect(() => {
    //     if (currentHomeModalKey === 'slider') {        
    // }, [currentHomeModalKey])
    useEffect(() => {
        setSearchAddTop(document.getElementById("main-header").offsetHeight)
    }, [document])

    return (
        <>
            {
                searchAddTop > 0 &&
                <div id="searchAndAddBar" className={`py-4 flex items-center gap-0 sm:gap-2 2xl:gap-0 flex-wrap sticky z-[11] top-[${searchAddTop}px] bg-gray-100 ${title ? `justify-between` : `justify-end`}`}>
                    <div className='flex flex-wrap gap-2 lg:gap-2 2xl:gap-0 items-center'>
                        {
                            currentUserId && <button className='mr-3' onClick={() => { setCurrentUserId(''); setCheckedValues([]); }} ><MdOutlineArrowBackIosNew className='text-2xl' /></button>
                        }{
                            title && <div className='mr-5 lg:mr-0 2xl:mr-5'>
                                <h2 className={`font-medium text-[24px] text-[#1E1627] leading-none`}>{title}</h2>
                            </div>
                        }
                        <div className='flex flex-wrap gap-2 lg:gap-2 items-center'>
                            <PerpageAndDeleteBtn delBtn={delBtn} moveAprroved={moveAprroved} movePending={movePending} movePendingTag={movePendingTag} moveRejectedTag={moveRejectedTag} moveApprovedTag={moveApprovedTag} moveRejected={moveRejected} resetFilterBtn={resetFilterBtn} deleteUpdateTag={deleteUpdateTag} perPageCount={perPageCount} />
                            {
                                recommendedLoading && <div className='flex items-center gap-2'>
                                    <label htmlFor='isLoadMore' className='font-normal text-base'>Recommended Loading</label>
                                    <Switch id="isLoadMore" name='isLoadMore' onChange={handleIsLoadMoreSwitch} checked={isLoadMore} />
                                </div>
                            }{
                                countryFilter && <Dropdowns title={'Country'} name={`country`} Icon={TbWorld} dropData={country} />
                            }{
                                FilterDropdowns && FilterDropdowns
                            }
                        </div>
                    </div>
                    <div className="flex flex-wrap gap-2 mt-2 lg:mt-0 mx-auto xs:mx-0">
                        {
                            isSearch &&
                            <div className="flex items-center bg-white py-3 px-[15px] lg:px-2 w-[275px] lg:w-[267px] space-x-2 xl:space-x-4 rounded-lg border border-[#BCC2CC]">
                                <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 opacity-30" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z" />
                                </svg>
                                <input
                                    className="bg-white outline-none w-full"
                                    type="text"
                                    placeholder={`Search ${title}`}
                                    value={search}
                                    onChange={(e) => { setSearch(e.target.value); setCurrentPage(1) }}
                                />
                            </div>
                        }{
                            redirectionBtn &&
                            <Button
                                className='lg:ml-2.5 lg:px-4 2xl:px-5 flex items-center bg-[#0047FF] capitalize text-sm leading-none font-medium whitespace-nowrap shadow-none hover:shadow-none'
                                onClick={() => {
                                    setDeleteUpdateYes(`add`);
                                    setCurrentHomeModalKey(`add`);
                                    setFormData({});
                                    handleOpen();
                                }}>
                                Redirections <AiOutlinePlusSquare size={"22px"} className='ml-2.5' />
                            </Button>
                        }{
                            infoBtn &&
                            <Button
                                className='lg:ml-2.5 lg:px-4 2xl:px-5 flex items-center bg-[#0047FF] capitalize text-sm leading-none font-medium whitespace-nowrap shadow-none hover:shadow-none'
                                onClick={() => { handleOpen() }}>
                                Info <AiFillInfoCircle size={"22px"} className='ml-2.5' />
                            </Button>
                        }{
                            addBtn &&
                            <Button
                                className='lg:ml-2.5 lg:px-4 2xl:px-5 flex items-center bg-[#0047FF] capitalize text-sm leading-none font-medium whitespace-nowrap shadow-none hover:shadow-none'
                                onClick={() => {
                                    setDeleteUpdateYes(`add`);
                                    setCurrentHomeModalKey(`add`);
                                    setFormData({});
                                    handleOpen();
                                }}
                            >
                                Add New {title} <AiOutlinePlusSquare size={"22px"} className='ml-2.5' />
                            </Button>
                        }
                        {
                            addLink &&
                            <Link to={destinationUrl}>
                                <Button
                                    onClick={() => {
                                        setDeleteUpdateYes(`add`);
                                        setCurrentHomeModalKey(`add`);
                                        setFormData({});
                                    }}
                                    className='lg:ml-2.5 lg:px-4 2xl:px-5 flex items-center bg-[#0047FF] capitalize text-sm leading-none font-medium whitespace-nowrap shadow-none hover:shadow-none'
                                >
                                    Add New {title} <AiOutlinePlusSquare size={"22px"} className='ml-2.5' />
                                </Button>
                            </Link>
                        }
                        {
                            updateBtn &&
                            <Button
                                className='lg:ml-2.5 flex items-center bg-[#0047FF] capitalize text-sm leading-none font-medium whitespace-nowrap shadow-none hover:shadow-none'
                                onClick={() => {
                                    setDeleteUpdateYes("update-sectionsettings-priority");
                                    setConfirmationModalShow(true)
                                }}
                            >
                                Update<MdOutlineCloudUpload size={"22px"} className='ml-2.5' />
                            </Button>
                        }

                        {
                            CustomButton && CustomButton
                        }
                    </div>
                </div >
            }
        </>
    )
}

export default SearchAndAddBtn