import React from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';

const RatingChart = ({ question }) => {

    const options = {
        chart: {
            type: 'column'
        },
        title: {
            text: ''
        },
        credits: {
            enabled: false
        },
        subtitle: {
            text:
                'Type: Rating'
        },
        legend: {
            align: 'right',
            verticalAlign: 'middle',
            layout: 'vertical'
        },
        xAxis: {
            title: {
                text: ''
            },
            labels: {
                formatter: function () {
                    return this.value + 1;
                }
            },
            categories: question.answer.map(option => option.rating)
        },
        tooltip: {
            formatter: function () {
                return '<b>Count:</b> ' + this.y;
            }
        },
        yAxis: {
            min: 0,
            minRange: 5,
            title: {
                text: 'Count'
            }
        },
        series: [{
            name: 'Count',
            data: question.answer.map(option => option.count)
        }],
        lang: {
            noData: 'There are currently no survey Result'
        },
        noData: {
            style: {
                fontWeight: 'bold',
                fontSize: '15px',
                color: '#303030'
            }
        },
        responsive: {
            rules: [{
                condition: {
                    maxWidth: 500
                },
                chartOptions: {
                    legend: {
                        align: 'center',
                        verticalAlign: 'bottom',
                        layout: 'horizontal'
                    }
                }
            }]
        }
    };

    return <HighchartsReact highcharts={Highcharts} options={options} />;
};

export default RatingChart;
