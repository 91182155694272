import React, { useContext, useEffect, useState } from 'react'
import { NewContext } from '../../Context/NewContext';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import CanvasJSReact from '@canvasjs/react-charts';
import { get_community_storage_chart } from '../../Controller/actions';
import { Spinner } from '@material-tailwind/react';
export const StorageModal = () => {
    const [option, setOption] = useState({});
    const { handleClose, open, setIsModalLoading, isModalLoading, filterDropdown } = useContext(NewContext);
    var CanvasJSChart = CanvasJSReact.CanvasJSChart;
    const getSorageData = async () => {
        setIsModalLoading(true);
        try {
            // return
            const { data, status } = await get_community_storage_chart(filterDropdown);
            if (status) {
                setOption({
                    animationEnabled: true,
                    width: 900,
                    height: 450,
                    title: {
                        text: "Theme Storage"
                    },
                    subtitles: [{
                        text: data.title,
                        verticalAlign: "center",
                        fontSize: 18,
                        maxWidth: 100,
                        wrap: true,
                        dockInsidePlotArea: true
                    }],
                    data: [{
                        type: "doughnut",
                        showInLegend: false,
                        indexLabel: "{name}",
                        yValueFormatString: "#,###'%'",
                        dataPoints: [
                            { name: data.pending.value, color: "blue", y: data.pending.y },
                            { name: data.approved.value, color: "green", y: data.approved.y },
                            { name: data.rejected.value, color: "red", y: data.rejected.y },
                            { name: data.extra.value, y: data.extra.y },
                        ]
                    }]
                })
            } else {
                setOption({});
            }
        } catch (error) {
            console.error(error);
        };
        setIsModalLoading(false);
    };
    useEffect(() => {
        if (open) {

            getSorageData();
        }
    }, [open]);
    return (
        <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="responsive-dialog-title"
            sx={{
                "& .MuiDialog-container": {
                    "& .MuiPaper-root": {
                        width: "100%",
                        maxWidth: "1000px",
                        height: "100%",
                        maxHeight: "500px",
                    },
                },
            }}
        >

            <DialogContent>
                <div className="flex justify-center items-center h-full">
                    {/* <div className=""> */}
                    {!isModalLoading ? <CanvasJSChart options={option} /> : <Spinner className="w-10 h-10 flex justify-center" />}
                    {/* </div> */}
                </div>
            </DialogContent>


        </Dialog>
    )
}

export default StorageModal
