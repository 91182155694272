import React from 'react';
import {
    Card,
    CardBody,
} from "@material-tailwind/react";
import { TbPointFilled } from 'react-icons/tb';

const OpenEndedQuestion = ({ openEndedAnswers, surveyId, questionId }) => {
    const matchingAnswer = openEndedAnswers.find(answer => answer.surveyId === surveyId && answer.quid === questionId);
    const textAnswers = matchingAnswer ? matchingAnswer.textAnswers : [];
    return (
        <>
            <div className="p-2.5 rounded-lg overflow-x-hidden">
                <div className='flex ml-7 text-sm justify-center'>
                    {`Type: Open-Ended-Questions`}
                </div>
                <div>
                    <Card className="w-full rounded-md shadow-none col-span-12 max-h-[800px] overflow-scroll overflow-x-hidden">
                        <CardBody className="w-full py-2.5 px-[5px]">
                            <div className="flex flex-col w-full">
                                {textAnswers.map((text, index) => (
                                    <div key={index} className="flex items-center border-b border-blue-gray-50 dragging w-full">
                                        <div className="text-[#1E1627]">
                                            <TbPointFilled className="mt-1" />
                                        </div>
                                        <div className="text-[#1E1627] p-2 overflow-hidden break-words whitespace-pre-wrap">
                                            <div dangerouslySetInnerHTML={{ __html: (text) }} />
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </CardBody>
                    </Card>

                </div>
            </div >
        </>
    );
};

export default OpenEndedQuestion;
