import React, { useContext, useEffect, useState } from 'react'
import { toast } from 'react-toastify';
import { MultiSelect } from "react-multi-select-component";

import { Typography, Button, Select, Option, Spinner, } from "@material-tailwind/react";
import { add_textart, get_country_data, get_region_data, get_textart_categories_data, update_textart } from '../../Controller/actions';
import { NewContext } from '../../Context/NewContext';
import { BiLink } from 'react-icons/bi';
import { MdContentCopy } from 'react-icons/md';
import Switch from '../Switch/Switch';

import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import TextArtChildModal from './TextArtChildModal';

const TextArtModal = () => {
    const { truncateString, homeActivityRef, setAddEditDeleteTag, isModalLoading, setIsModalLoading, setFormData, currentHomeModalKey, setCurrentHomeModalKey, handleChildOpen, childOpen, setDeleteUpdateTag, deleteUpdateYes, handleCopyData, handleSelectMultiCountry, handleSelectMultiRegion, handleSingleSelect, handleSwitch, handleFileInput, handleClose, formData, open, addEditDeleteTag, capitalalizeFirstletter, updateStateAryData, multiCountry, setMultiCountry, multiRegion, setMultiRegion } = useContext(NewContext);

    const [category, setCategory] = useState([]);
    const [country, setCountry] = useState([]);
    const [region, setRegion] = useState([]);
    const [multiCategories, setMultiCategories] = useState([]);
    const [isOpenCategoryDropDown, setIsOpenCategoryDropDown] = useState(false);
    const [isOpenCountryDropDown, setIsOpenCountryDropDown] = useState(false);
    const getDataFunc = async () => {
        setIsModalLoading(true);
        try {
            const categoryData = await get_textart_categories_data(1, "", "", { isActive: true });
            const countryData = await get_country_data();
            const regionData = await get_region_data();
            if (categoryData.status && countryData.status && regionData.status) {
                // setCategory(categoryData.data);
                categoryData.data.map(_ele => {
                    if (category.find(_e => _e._id === _ele._id)) {
                        if (category.find(_e => _e.name !== _ele.name)) {
                            let filterData = category.map(_t => ({ ..._t, name: 'category' }))
                            setCategory(filterData);
                        }
                    } else {
                        _ele.name && setCategory(_prev => [..._prev, { _id: _ele._id, value: _ele._id, label: _ele.name, name: 'category' }])
                    }
                });
                countryData.data.map(_ele => !country.find(_e => _e._id === _ele._id) && setCountry(_prev => [..._prev, { _id: _ele._id, value: _ele.code, label: capitalalizeFirstletter(_ele.name), name: 'country' }]))
                // regionData.data.map(_ele => !region.find(_e => _e._id === _ele._id) && setRegion(_prev => [..._prev, { _id: _ele._id, value: _ele.name.toLowerCase(), label: capitalalizeFirstletter(_ele.name), name: 'region' }]))
                if (addEditDeleteTag === `update`) {
                    let oldCategoryData = updateStateAryData([formData['category']], categoryData.data, 'category');
                    let oldCountryData = updateStateAryData(formData?.country, countryData.data, 'country');
                    setMultiCategories(oldCategoryData);
                    setMultiCountry(oldCountryData);
                    // let oldRegionData = updateStateAryData(formData?.region, regionData.data, 'region');
                    // setMultiRegion(oldRegionData);
                    if (oldCategoryData.length == 1) {
                        let selectedSliderTag = category.filter(_ele => oldCategoryData[0]._id === _ele._id);
                        let nonSelectedSliderTag = category.filter(_ele => oldCategoryData[0]._id !== _ele._id).map(_ele => ({ ..._ele, disabled: true }))
                        setCategory(selectedSliderTag.concat(nonSelectedSliderTag));
                    }
                }
            } else {
                toast.error(categoryData.message || countryData.message || regionData.message);
            }
        } catch (error) {
            console.error(error);
        };
        setIsModalLoading(false);
    };
    useEffect(() => {
        getDataFunc();
    }, [addEditDeleteTag, currentHomeModalKey]);
    const handleNewCategoryField = async (_value) => { setCurrentHomeModalKey('addCategory'); handleChildOpen(); };
    const handleSelectMultiCategories = (e) => {
        if (e[0] && e.length > 0) {
            e.map(_ele => {
                let multiSelectAry = [].slice.call(e).map(item => item && item.value);
                setMultiCategories([{ ...e[0], disabled: false }]);
                setFormData({ ...formData, [_ele?.name]: multiSelectAry[0] });
                if (e[0] && e.length == 1) {
                    let selectedSliderTag = category.filter(_ele => e[0]._id === _ele._id);
                    let nonSelectedSliderTag = category.filter(_ele => e[0]._id !== _ele._id).map(_ele => ({ ..._ele, disabled: true }))
                    setCategory(selectedSliderTag.concat(nonSelectedSliderTag));
                }
            })
        } else {
            let categoryData = category.map(_ele => ({ ..._ele, disabled: false }));
            setCategory(categoryData);
            setMultiCategories([]);
            setFormData({ ...formData, category: [] })
        }
        homeActivityRef.current.focus();
    }
    const handleSubmit = async (e) => {
        e.preventDefault();
        let createUpdateSlider;
        setIsModalLoading(true);
        if (addEditDeleteTag === `update`) {
            let countryIdAry = multiCountry.map(_ele => _ele._id)
            createUpdateSlider = await update_textart({ ...formData, country: countryIdAry || [], region: formData?.region || [] }, formData?._id)
        } else {
            createUpdateSlider = await add_textart(formData)
        }
        if (createUpdateSlider) {
            const { message, status } = createUpdateSlider;
            if (status) {
                toast.success(message);
                handleClose();
                setDeleteUpdateTag(deleteUpdateYes);
                // setValidated(false);
                setMultiCountry([]);
                setMultiRegion([]);
                setCategory([]); setMultiCategories([]); setAddEditDeleteTag(`add`);
            } else {
                toast.error(message);
            };
        }
        setIsModalLoading(false);
    }

    return (
        <>
            <Dialog
                open={open}
                onClose={() => { handleClose(); setCategory([]); setMultiCategories([]); setAddEditDeleteTag(`add`); }}
                aria-labelledby="responsive-dialog-title"
            >
                <DialogTitle id="responsive-dialog-title" className='p-0 mb-[30px] text-[#1E1627]'>
                    {addEditDeleteTag === 'view' ? 'Details View' : addEditDeleteTag === 'update' ? `Edit Text Art` : `Add New Text Art`}
                </DialogTitle>
                <DialogContent>
                    <div>
                        {
                            addEditDeleteTag !== 'view' ?
                                <form onSubmit={handleSubmit}>
                                    <div className="mt-5 grid grid-cols-1 lg:grid-cols-6 gap-5 add-edit-form">
                                        <div className="col-span-6 sm:col-span-3">
                                            <label htmlFor="category" className="block text-sm font-medium leading-6 text-gray-900">Category:</label>
                                            <div className="mt-1.5" onFocus={() => { setIsOpenCategoryDropDown(true) }} onBlur={() => { setIsOpenCategoryDropDown(false) }}>
                                                {/* <Select name='category' onChange={(e) => handleSingleSelect(`category`, e)} value={formData?.category?._id || formData?.category}>
                                                    {category.map((_ele, i) => <Option key={i} value={_ele._id}>{_ele?.name || `None`}</Option>)}
                                                </Select> */}
                                                {
                                                    !childOpen && category && category.length > 0 &&
                                                    <MultiSelect
                                                        options={category}
                                                        value={multiCategories}
                                                        onChange={handleSelectMultiCategories}
                                                        hasSelectAll={false}
                                                        // ClearSelectedIcon={null}
                                                        isCreatable={true}
                                                        onCreateOption={handleNewCategoryField}
                                                        isOpen={isOpenCategoryDropDown}
                                                    />
                                                }
                                            </div>
                                        </div>
                                        <div className="col-span-6 sm:col-span-3">
                                            <label htmlFor="categoryBinFile" className="block text-sm font-medium leading-6 text-gray-900">Font File:</label>
                                            <div className="mt-1.5 relative">
                                                <input id='categoryBinFile' type='file' accept='.bin' name='categoryBinFile' onChange={handleFileInput} className='w-full border px-3 py-1.5 rounded-[7px] text-sm border-blue-gray-200 focus:border-blue-500' required={addEditDeleteTag !== `update`} />
                                                {
                                                    addEditDeleteTag === `update` && formData.textmoji && typeof formData.textmoji === 'string' && !formData.categoryBinFile &&
                                                    <label htmlFor="bigPreviewStickerStore" className='absolute sm:left-[39%] left-[33.5%] right-[2px] w-auto rounded-r-[7px] border-1 border-white text-sm leading-none bg-white h-[90%] top-0 bottom-0 m-auto flex items-center pl-1'>{truncateString(formData.textmoji.split('/').pop(), 12)}</label>
                                                }
                                            </div>
                                        </div>
                                        <div className="col-span-6 sm:col-span-3">
                                            <label htmlFor="country" className="block text-sm font-medium leading-6 text-gray-900">Country:</label>
                                            <div className="mt-1.5" onFocus={() => { setIsOpenCountryDropDown(true) }} onBlur={() => { setIsOpenCountryDropDown(false) }}>
                                                {
                                                    !childOpen && country && country.length > 0 &&
                                                    <MultiSelect
                                                        labelledBy='Select Country'
                                                        options={country}
                                                        value={multiCountry}
                                                        onChange={handleSelectMultiCountry}
                                                        hasSelectAll={false}
                                                        ClearSelectedIcon={null}
                                                        isOpen={isOpenCountryDropDown}
                                                    />
                                                }
                                                <input ref={homeActivityRef} className='absolute top-0 right-0 bottom-0 left-0 m-auto w-[95%] h-[80%] bg-red-300 -z-10' />
                                            </div>
                                        </div>
                                        <div className="col-span-2 sm:col-span-1">
                                            <label htmlFor="isPremium" className="block text-sm font-medium leading-6 text-gray-900">Premium:</label>
                                            <div className="mt-1.5">
                                                <Switch color='green' id='isPremium' name='isPremium' onChange={handleSwitch} checked={formData?.isPremium} />
                                            </div>
                                        </div>
                                        <div className="col-span-2 sm:col-span-2">
                                            <label htmlFor="isActive" className="block text-sm font-medium leading-6 text-gray-900">Active:</label>
                                            <div className="mt-1.5">
                                                <Switch color='green' id='isActive' name='isActive' onChange={handleSwitch} checked={formData?.isActive} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className='flex justify-end mt-8'>
                                        <Button
                                            onClick={() => { handleClose(); setCategory([]); setMultiCategories([]); setAddEditDeleteTag(`add`); }}
                                            className="mr-1 px-5 py-2.5 bg-white shadow-none text-[#1E3354] font-medium border border-[#75869F] hover:shadow-none capitalize text-base"
                                        >
                                            <span>Cancel</span>
                                        </Button>
                                        <Button
                                            disabled={isModalLoading}
                                            className="flex items-center mr-1 px-5 py-2.5 bg-[#0047FF] shadow-none text-white font-medium border border-[#0047FF] hover:shadow-none capitalize text-base"
                                            type='submit'
                                        >
                                            {isModalLoading && <Spinner className="h-4 w-4" />}
                                            <span className={isModalLoading && 'ml-4'}>{addEditDeleteTag === `add` ? `Add` : `Update`}</span>
                                        </Button>
                                    </div>
                                </form>
                                :
                                <div>
                                    <div className="mt-5 grid grid-cols-1 lg:grid-cols-6 gap-5 add-edit-form">
                                        <div className="sm:col-span-6">
                                            <label htmlFor="link" className="block text-lg font-medium leading-5 text-[#1E3354]">Link:</label>
                                            <div className="flex items-center justify-between">
                                                <div className="mt-2 flex items-center text-[#2288FF]">
                                                    <span className='mr-[5px]'><BiLink className='text-xl leading-none' /></span>
                                                    <Typography>{formData?.link || `None`}</Typography>
                                                </div>
                                                <Button variant='text' disabled={!formData?.link} className='p-3 rounded-full' onClick={() => handleCopyData(`Link`, formData?.link)}><MdContentCopy className='text-xl leading-none' /></Button>
                                            </div>
                                        </div>
                                        <div className="sm:col-span-3">
                                            <label htmlFor="name" className="block text-lg font-medium leading-5 text-[#1E3354]">Name:</label>
                                            <div className="mt-2">
                                                <Typography className="text-[17px] text-[#75869F] font-normal leading-5">{formData?.name || `None`}</Typography>
                                            </div>
                                        </div>
                                        <div className="sm:col-span-3">
                                            <label htmlFor="from_flg" className="block text-lg font-medium leading-5 text-[#1E3354]">From Flag:</label>
                                            <div className="mt-2">
                                                <Typography className="text-[17px] text-[#75869F] font-normal leading-5">{formData?.from_flg || `None`}</Typography>
                                            </div>
                                        </div>
                                        <div className="sm:col-span-3">
                                            <label htmlFor="street-address" className="block text-lg font-medium leading-5 text-[#1E3354]">Priority:</label>
                                            <div className="mt-2">
                                                <Typography className="text-[17px] text-[#75869F] font-normal leading-5">{formData?.priority}</Typography>
                                            </div>
                                        </div>
                                        <div className="sm:col-span-3">
                                            <label htmlFor="country" className="block text-lg font-medium leading-5 text-[#1E3354]">Slider Tag:</label>
                                            <div className="mt-2">
                                                <Typography className="text-[17px] text-[#75869F] font-normal leading-5">{formData?.slider_tag || `None`}</Typography>
                                            </div>
                                        </div>
                                        <div className="sm:col-span-3">
                                            <label htmlFor="country" className="block text-lg font-medium leading-5 text-[#1E3354]">Region:</label>
                                            <div className="mt-2">
                                                <Typography className="text-[17px] text-[#75869F] font-normal leading-5">
                                                    {formData?.region && formData?.region?.length !== 0 ? formData?.region?.map((_ele, key) => key === _ele.region?.length - 1 ? _ele : `${_ele}, `) : `None`}
                                                </Typography>
                                            </div>
                                        </div>
                                        <div className="sm:col-span-3">
                                            <label htmlFor="country" className="block text-lg font-medium leading-5 text-[#1E3354]">Country:</label>
                                            <div className="mt-2">
                                                <Typography className="text-[17px] text-[#75869F] font-normal leading-5">
                                                    {formData?.country && formData?.country?.length !== 0 ? formData?.country?.map((_ele, key) => key === _ele.country?.length - 1 ? _ele : `${_ele}, `) : `None`}
                                                </Typography>
                                            </div>
                                        </div>
                                        <div className="sm:col-span-3">
                                            <label htmlFor="city" className="block text-lg font-medium leading-5 text-[#1E3354]">Orientation:</label>
                                            <div className="mt-2 grid grid-cols-2">
                                                <Typography className="text-[17px] text-[#75869F] font-normal leading-5">{formData?.orientation || `None`}</Typography>
                                            </div>
                                        </div>
                                        <div className="sm:col-span-3">
                                            <label htmlFor="region" className="block text-lg font-medium leading-5 text-[#1E3354]">Active:</label>
                                            <div className="mt-2 ml-2">
                                                <Typography className="text-[17px] text-[#75869F] font-normal leading-5">{formData?.isActive ? "Off" : "On"}</Typography>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='flex justify-end mt-10'>
                                        <Button
                                            onClick={handleClose}
                                            className="mr-1 px-5 py-2.5 bg-white shadow-none text-[#1E3354] font-medium border border-[#75869F] hover:shadow-none capitalize text-base"
                                        >
                                            <span>Cancel</span>
                                        </Button>
                                    </div>
                                </div>
                        }
                    </div>
                </DialogContent>
            </Dialog>
            <TextArtChildModal />
        </>
    )
}

export default TextArtModal
