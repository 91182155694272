import React, { useContext, useEffect, useRef, useState } from "react";
import { toast } from "react-toastify";
import ReactPlayer from "react-player";
import { Button, Input, Spinner } from "@material-tailwind/react";
import { NewContext } from "../../Context/NewContext";
import {
  get_one_faq,
  update_faq,
  add_faq,
} from "../../Controller/actions";
import Select from "react-select";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Editor from "../../Pages/FAQ/Editor/Editor";
import Zoom from "react-medium-image-zoom";

import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";

const FaqModel = () => {
  const {
    isModalLoading,
    setDeleteUpdateTag,
    deleteUpdateYes,
    handleFileInput,
    setIsModalLoading,
    handleClose,
    handleChange,
    formData,
    open,
    addEditDeleteTag,
    setFormData,
    truncateString
  } = useContext(NewContext);

  const mediaTypeRef = useRef(null);
  const youTubeObj = {
    label: "Youtube",
    value: "youtube",
    name: `type`,
  };
  const videoObj = {
    label: "Video",
    value: "video",
    name: `type`,
  };
  const imagesObj = {
    label: "Image",
    value: "image",
    name: `type`,
  };


  const getDataFunc = async (id) => {
    const { data } = await get_one_faq(id);
    try {
      setFormData(data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (addEditDeleteTag === "update") {
      getDataFunc(formData?._id);
    }
  }, [addEditDeleteTag]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!formData.answerDetails) {
      toast.error("Answer details is required.");
      return;
    }
    if (!formData.question) {
      return toast.error("Question is required.");
    }

    const imagesIndices = formData?.media?.reduce((acc, item, index) => {
      if (item.type === 'image' && item?.isNew == true) {
        acc.push(index);
      }
      return acc;
    }, []);
    if (imagesIndices?.length > (formData?.faqImages?.length || 0)) {
      return toast.error("Please upload image.");
    }

    const videoIndices = formData?.media?.reduce((acc, item, index) => {
      if (item.type === 'video' && item?.isNew == true) {
        acc.push(index);
      }
      return acc;
    }, []);
    if (videoIndices?.length > (formData?.video?.length || 0)) {
      return toast.error("Please upload video.");
    }

    if (videoIndices?.length > (formData?.videoPreview?.length || 0)) {
      return toast.error("Please upload video preview.");
    }

    setIsModalLoading(true);
    let createUpdateFAQ;
    setIsModalLoading(true);
    if (addEditDeleteTag === "update") {
      createUpdateFAQ = await update_faq(formData, formData?._id);
    } else {
      createUpdateFAQ = await add_faq({ ...formData });
    }
    setIsModalLoading(false);
    if (createUpdateFAQ) {
      const { message, status } = createUpdateFAQ;
      if (status) {
        toast.success(message);
        handleClose();
        setDeleteUpdateTag(deleteUpdateYes);
      } else {
        toast.error(message);
      }
    }
    handleClose();
  };
  const handleMediaChange = (e, i) => {
    formData.media[i][e.target.name] = e.target.value
    setFormData({ ...formData });
  }

  useEffect(() => {
    if (open && mediaTypeRef.current && mediaTypeRef.current.focusInput) {
      mediaTypeRef.current.focusInput();
    }
  }, [open, mediaTypeRef.current]);

  const handleMediaOpen = () => {
    if (formData?.media?.length > 0) {
      formData.media.push({ type: '', url: '', preview: '', isNew: true })
    } else {
      formData.media = [{ type: '', url: '', preview: '', isNew: true }]
    }
    setFormData({ ...formData })
  };


  const handleDragEnd = async (result) => {
    if (!result.destination) {
      return;
    }
    const { source, destination } = result;
    if (source.index === destination.index) {
      return;
    }

    // Reorder the data array according to the drag and drop operation
    const newData = Array.from(formData?.media);
    const [movedItem] = newData.splice(source.index, 1);
    newData.splice(destination.index, 0, movedItem);
    formData.media = newData

    if (
      (formData?.media[source.index]?.type === 'video' && formData?.media[source.index]?.isNew === true) &&
      (formData?.media[destination.index]?.type === 'video' && formData?.media[destination.index]?.isNew === true)) {

      const newDataVideo = Array.from(formData?.video);
      const [movedItemVideo] = newDataVideo.splice(source.index, 1);
      newDataVideo.splice(destination.index, 0, movedItemVideo);
      formData.video = newDataVideo


      const newDataVideoPreview = Array.from(formData?.videoPreview);
      const [movedItemVideoPreview] = newDataVideoPreview.splice(source.index, 1);
      newDataVideoPreview.splice(destination.index, 0, movedItemVideoPreview);
      formData.videoPreview = newDataVideoPreview
    }

    if (
      (formData?.media[source.index]?.type === 'image' && formData?.media[source.index]?.isNew === true) &&
      (formData?.media[destination.index]?.type === 'image' && formData?.media[destination.index]?.isNew === true)) {
      const newDataImages = Array.from(formData?.faqImages);
      const [movedItemImage] = newDataImages.splice(source.index, 1);
      newDataImages.splice(destination.index, 0, movedItemImage);
      formData.faqImages = newDataImages
    }
    setFormData({ ...formData });
  };

  // Remove prev item from the UI
  const handleRemovePrevMedia = async (indexToRemove) => {
    try {
      const updatedMedia = formData.media;
      updatedMedia.splice(indexToRemove, 1);
      setFormData({ ...formData });
    } catch (error) {
      toast.error("Failed to delete media item. Please try again later.");
    }
  };

  return (
    <>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              width: "100%",
              maxWidth: "1080px",
              borderRadius: "12px",
            },
          },
        }}
      >
        <DialogTitle id="responsive-dialog-title" className=" text-[#1E1627] ">
          {addEditDeleteTag === "view"
            ? "Details View"
            : addEditDeleteTag === "update"
              ? `Edit FAQ`
              : `Add FAQ`}
        </DialogTitle>
        <DialogContent>
          <div>
            {addEditDeleteTag !== "view" ? (
              <form onSubmit={handleSubmit}>
                <DialogContent>
                  <div className="grid grid-cols-6 lg:grid-cols-6 gap-5 add-edit-form">
                    <div className="col-span-6 sm:col-span-3">
                      <label
                        htmlFor="name"
                        className="block text-sm font-medium leading-6 text-gray-900"
                      >
                        Question:<span style={{ color: "red" }}>*</span>
                      </label>
                      <div className="mt-1.5 mb-1.5">
                        <Input
                          id="question"
                          placeholder="Enter question"
                          value={formData?.question}
                          name="question"
                          autoComplete="off"
                          onChange={handleChange}
                          required={true}
                          maxLength={100}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="border p-2 mt-1.5 mb-1.5">
                    <div className="grid grid-cols-12 lg:grid-cols-12 gap-5 add-edit-form ">
                      <div className="col-span-12 lg:grid-cols-12 font-medium flex gap-2 items-center">
                        <Button
                          className="inline-flex items-center mr-1 px-5 py-1 bg-[#0047FF] shadow-none text-white font-medium border border-[#0047FF] hover:shadow-none capitalize text-base"
                          type="button"
                          onClick={handleMediaOpen}
                        >
                          <span>Add Media</span>
                        </Button>
                      </div>
                    </div>

                    <DragDropContext onDragEnd={handleDragEnd}>
                      <Droppable droppableId="Images">
                        {(provided) => (
                          <div
                            ref={provided.innerRef}
                            {...provided.droppableProps}
                          >
                            {formData?.media?.length > 0 &&
                              formData?.media?.map((media, i) => {
                                return (
                                  <Draggable
                                    key={i}
                                    draggableId={i.toString()}
                                    index={i}
                                  >
                                    {(provided) => (
                                      media?.isNew ? <div
                                        ref={provided.innerRef}
                                        {...provided.draggableProps}
                                        {...provided.dragHandleProps}
                                        className="mt-5 grid grid-cols-12 lg:grid-cols-12 gap-5 add-edit-form "
                                        key={i}
                                      >
                                        <div className="col-span-3 lg:grid-cols-3 ">
                                          <label
                                            className="block text-sm font-medium leading-6 text-gray-900"
                                          >
                                            Select Media Type:
                                          </label>
                                          <div className="mt-1.5 relative">
                                            {
                                              <Select
                                                ref={mediaTypeRef}
                                                labelledBy="Select Type"
                                                options={[youTubeObj, videoObj, imagesObj]}
                                                onChange={(e) => {
                                                  formData.media[i] = { type: e?.value, url: '', preview: '', isNew: true }
                                                  setFormData({ ...formData });
                                                }}
                                                value={
                                                  formData.media[i]['type'] === "youtube" ? youTubeObj : formData.media[i]['type'] === "video" ? videoObj : formData.media[i]['type'] === "image" ? imagesObj : {}}
                                              />
                                            }
                                          </div>
                                        </div>

                                        {formData.media[i]['type'] === "youtube" && (
                                          <div className="col-span-6">
                                            <label
                                              className="block text-sm font-medium leading-6 text-gray-900"
                                            >
                                              Youtube URL:
                                              <span style={{ color: "red" }}>*</span>
                                            </label>
                                            <div className="mt-1.5">
                                              <Input
                                                placeholder="Enter question"
                                                name={`url`}
                                                value={formData.media[i]['url']}
                                                onChange={(e) => { handleMediaChange(e, i) }}
                                                required={true}
                                                autoComplete="off"
                                              />
                                            </div>
                                          </div>
                                        )}
                                        {formData.media[i]['type'] === "video" && (
                                          <>
                                            <div className="col-span-3">
                                              <label
                                                className="block text-sm font-medium leading-6 text-gray-900"
                                              >
                                                Video:<span style={{ color: "red" }}>*</span>
                                              </label>
                                              <div className="mt-1.5 relative">
                                                <input
                                                  id={`${i}`}
                                                  type="file"
                                                  accept="video/*"
                                                  name={`video`}
                                                  onChange={(e) => handleFileInput(e, '', '', i)}
                                                  className="w-full border px-3 py-1.5 rounded-[7px] text-sm border-blue-gray-200 focus:border-blue-500"
                                                  // required
                                                  autoComplete="off"
                                                />
                                                {
                                                  formData?.media?.[i]?.url &&
                                                  <label className='absolute sm:left-[35%] left-[33.5%] right-[2px] w-[100px] rounded-r-[7px] border-1 border-white text-sm leading-none bg-white h-[90%] top-0 bottom-0 m-auto flex items-center pl-1'>{truncateString(formData?.media?.[i]?.url.split('/').pop(), 24)}</label>
                                                }
                                              </div>
                                            </div>
                                            <div className="col-span-3">
                                              <label
                                                className="block text-sm font-medium leading-6 text-gray-900"
                                              >
                                                Video Preview:
                                                <span style={{ color: "red" }}>*</span>
                                              </label>
                                              <div className="mt-1.5 relative">
                                                <input
                                                  type="file"
                                                  accept="image/*"
                                                  name={`videoPreview`}
                                                  onChange={(e) => handleFileInput(e, '', '', i)}
                                                  className="w-full border px-3 py-1.5 rounded-[7px] text-sm border-blue-gray-200 focus:border-blue-500"
                                                  autoComplete="off"
                                                />
                                                {
                                                  formData?.media?.[i]?.preview &&
                                                  <label className='absolute sm:left-[35%] left-[33.5%] right-[2px] w-[100px] rounded-r-[7px] border-1 border-white text-sm leading-none bg-white h-[90%] top-0 bottom-0 m-auto flex items-center pl-1'>{truncateString(formData?.media?.[i]?.preview.split('/').pop(), 24)}</label>
                                                }
                                              </div>
                                            </div>
                                          </>
                                        )}
                                        {formData.media[i]['type'] === "image" && (
                                          <div className="col-span-6 ">
                                            <label
                                              className="block text-sm font-medium leading-6 text-gray-900"
                                            >
                                              Images :
                                              <span style={{ color: "red" }}>*</span>
                                            </label>
                                            <div className="mt-1.5 relative">
                                              <input
                                                type="file"
                                                accept="image/*"
                                                name={`faqImages`}
                                                onChange={(e) => handleFileInput(e, '', '', i)}
                                                className="w-full border px-3 py-1.5 rounded-[7px] text-sm border-blue-gray-200 focus:border-blue-500"
                                              />
                                              {
                                                formData?.media?.[i]?.url &&
                                                <label className='absolute sm:left-[22%] left-[22.5%] right-[2px] w-auto rounded-r-[7px] border-1 border-white text-sm leading-none bg-white h-[90%] top-0 bottom-0 m-auto flex items-center pl-1'>{truncateString(formData?.media?.[i]?.url.split('/').pop(), 15)}</label>
                                              }
                                            </div>
                                          </div>
                                        )}

                                        <div className="col-span-1 mt-7">
                                          <Button
                                            onClick={() =>
                                              handleRemovePrevMedia(i)
                                            }
                                            className="inline-flex mr-1 px-2 py-1 bg-red-500 text-white border border-red-500 hover:bg-red-600 hover:border-red-600 capitalize text-base"
                                            type="button"
                                          >
                                            Delete
                                          </Button>
                                        </div>
                                      </div> :
                                        <div
                                          ref={provided.innerRef}
                                          {...provided.draggableProps}
                                          {...provided.dragHandleProps}
                                          className="mt-5 grid grid-cols-6 lg:grid-cols-6 gap-5 add-edit-form"
                                          key={i}
                                        >
                                          <div className="col-span-6 sm:col-span-3 bg-blue-gray-50 p-2 rounded-md flex items-center justify-between">
                                            <div className="flex items-center gap-2">
                                              {media.type === "youtube" ? (
                                                <ReactPlayer
                                                  key={formData?._id}
                                                  url={media?.url}
                                                  showPreview
                                                  controls
                                                  loop
                                                  width="100%"
                                                  height="50%"
                                                />
                                              ) : media.type === "video" ? (
                                                <ReactPlayer
                                                  key={formData?._id}
                                                  url={media?.url}
                                                  showPreview
                                                  controls
                                                  loop
                                                  width="100%"
                                                  height="50%"
                                                />
                                              ) : (
                                                <Zoom>
                                                  <img
                                                    width={"50%"}
                                                    className="rounded-lg block"
                                                    src={`${media?.url}`}
                                                    alt="media?.url"
                                                  />
                                                </Zoom>
                                              )}
                                              <div className="capitalize"></div>
                                            </div>
                                            <div>
                                              <Button
                                                onClick={() =>
                                                  handleRemovePrevMedia(i)
                                                }
                                                className="ml-5 inline-flex items-center mr-1 px-2 py-1 bg-red-500 text-white font-medium border border-red-500 hover:bg-red-600 hover:border-red-600 capitalize text-base"
                                                type="button"
                                              >
                                                Delete
                                              </Button>
                                            </div>
                                          </div>
                                        </div>
                                    )}
                                  </Draggable>
                                );
                              })}
                            {provided.placeholder}
                          </div>
                        )}
                      </Droppable>
                    </DragDropContext>

                  </div >
                  <div className="col-span-12 sm:col-span-12">
                    <label
                      htmlFor="showStartDate"
                      className="block text-sm font-medium leading-6 text-gray-900 mt-1.5 mb-1.5"
                    >
                      Answer Details:<span style={{ color: "red" }}>*</span>
                    </label>

                    <Editor />
                  </div >
                </DialogContent >
                <div className="flex justify-end mt-2">
                  <Button
                    onClick={handleClose}
                    className="mr-2.5 px-5 py-2.5 bg-white shadow-none text-[#1E3354] font-medium border border-[#75869F] hover:shadow-none capitalize text-base"
                  >
                    <span>Cancel</span>
                  </Button>
                  <Button
                    disabled={isModalLoading}
                    className="flex items-center mr-1 px-5 py-2.5 bg-[#0047FF] shadow-none text-white font-medium border border-[#0047FF] hover:shadow-none capitalize text-base"
                    type="submit"
                  >
                    {isModalLoading && <Spinner className="h-4 w-4" />}
                    <span className={isModalLoading ? "ml-4" : ""}>
                      {addEditDeleteTag === `add` ? `Add` : `Update`}
                    </span>
                  </Button>
                </div>
              </form >
            ) : (
              ""
            )}
          </div >
        </DialogContent >
      </Dialog >
    </>
  );
};

export default FaqModel;
