import React, { useContext, useEffect, useState } from 'react'
import { Dialog, DialogHeader, DialogBody, Spinner } from "@material-tailwind/react";
import { toast } from 'react-toastify';
import { MultiSelect } from "react-multi-select-component";

import { Typography, Button, Input } from "@material-tailwind/react";
import { add_slider, get_country_data, get_region_data, get_slider_tag, update_slider } from '../../Controller/actions';
import { NewContext } from '../../Context/NewContext';
import { BiLink } from 'react-icons/bi';
import { MdContentCopy } from 'react-icons/md';
import Switch from '../Switch/Switch';

export const WallpaperModal = () => {
    const { homeActivityRef, isModalLoading, handleCopyData, handleSelectMultiCountry, handleSwitch, setIsModalLoading, handleClose, handleChange, formData, open, handleOpen, addEditDeleteTag, capitalalizeFirstletter, updateStateAryData, imgObj, multiCountry, setMultiCountry, setMultiRegion } = useContext(NewContext);

    const [tag, setTag] = useState([]);
    const [country, setCountry] = useState([]);
    const [region, setRegion] = useState([]);

    const getDataFunc = async () => {
        setIsModalLoading(true);
        try {
            const tagData = await get_slider_tag(1, "", "", { isActive: true });
            const countryData = await get_country_data();
            const regionData = await get_region_data();
            if (tagData.status && countryData.status && regionData.status) {
                setTag(tagData.data);
                countryData.data.map(_ele => setCountry(_prev => [..._prev, { value: _ele.code, label: capitalalizeFirstletter(_ele.name), name: 'country' }]))
                // regionData.data.map(_ele => setRegion(_prev => [..._prev, { value: _ele.name.toLowerCase(), label: capitalalizeFirstletter(_ele.name), name: 'region' }]))
                if (addEditDeleteTag === `update`) {
                    let oldCountryData = updateStateAryData(formData?.country, countryData.data, 'country');
                    setMultiCountry(oldCountryData);
                    // let oldRegionData = updateStateAryData(formData?.region, regionData.data, 'region');
                    // setMultiRegion(oldRegionData);
                }
            } else {
                toast.error(tagData.message);
            }
        } catch (error) {
            console.error(error);
        };
        setIsModalLoading(false);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        // const form = e.currentTarget;
        // if (form.checkValidity() ==== false) {
        //     e.preventDefault();
        //     e.stopPropagation();
        //     setValidated(true);
        //     return;
        // }
        let createUpdateSlider;
        setIsModalLoading(true);
        if (addEditDeleteTag === `update`) {
            createUpdateSlider = await update_slider({ ...formData, country: formData?.country, region: formData?.region }, formData?._id)
        } else {
            createUpdateSlider = await add_slider(formData)
        }
        setIsModalLoading(false);
        if (createUpdateSlider) {
            const { message, status } = createUpdateSlider;
            if (status) {
                toast.success(message);
                handleClose();
                // setValidated(false);
            } else {
                toast.error(message);
            };
        }
    }

    useEffect(() => {
        getDataFunc();
    }, []);

    return (
        <Dialog size="lg" open={open} handler={handleOpen} className='p-[30px]  w-[325px] md:w-[482px] max-w-none min-w-0'>
            <DialogHeader className='p-0 mb-[30px] text-[#1E1627] text-xl leading-none sm:text-[28px]'>
                {
                    addEditDeleteTag === 'view' ? 'Details View' : addEditDeleteTag === 'update' ? `Edit Wallpaper` : `Add New Wallpaper`
                }
            </DialogHeader>
            <DialogBody className='p-0'>
                <div className="flex">
                    <div className="mr-[26px]">
                        <Typography className="text-sm md:text-lg text-[#1E1627] font-medium">Preview:</Typography>
                        <div className="">
                            {
                                imgObj?.previewSlider || formData?.preview_img ?
                                    <img src={addEditDeleteTag === 'update' ? formData?.preview_img : imgObj?.previewSlider} alt={formData?.name} className='w-[92px] h-[164px]' />
                                    : <img src="../../assets/images/no-image.png" alt="No Image" className='w-[92px] h-[164px]' />
                            }
                        </div>
                    </div>
                    <div className="">
                        <Typography className="text-sm md:text-lg text-[#1E1627] font-medium">Background Image:</Typography>
                        <div className="">
                            {
                                imgObj?.previewSlider || formData?.preview_img ?
                                    <img src={addEditDeleteTag === 'update' ? formData?.preview_img : imgObj?.previewSlider} alt={formData?.name} className='w-[140px] h-[164px]' />
                                    : <img src="../../assets/images/no-image.png" alt="No Image" className='w-[140px] h-[164px]' />
                            }
                        </div>
                    </div>

                </div>
                <div>
                    {
                        addEditDeleteTag !== 'view' ?
                            <form onSubmit={handleSubmit}>
                                <div className="mt-5 grid grid-cols-6 md:grid-cols-6 gap-5 add-edit-form">
                                    <div className="col-span-6 sm:col-span-3">
                                        <label htmlFor="name" className="block text-sm font-medium leading-6 text-gray-900">Name:</label>
                                        <div className="mt-2">
                                            <Input id='name' placeholder='Enter Name' name='name' onChange={handleChange} value={formData?.name} />
                                        </div>
                                    </div>

                                    <div className="col-span-6 sm:col-span-3">
                                        <label htmlFor="country" className="block text-sm font-medium leading-6 text-gray-900">Country:</label>
                                        <div className="mt-1.5 relative">
                                            {
                                                country && country.length > 0 &&
                                                <MultiSelect
                                                    options={country}
                                                    value={multiCountry}
                                                    onChange={handleSelectMultiCountry}
                                                    hasSelectAll={true}
                                                    ClearSelectedIcon={null}
                                                />
                                            }
                                            <input ref={homeActivityRef} className='absolute top-0 right-0 bottom-0 left-0 m-auto w-[95%] h-[80%] bg-red-300 -z-10' />
                                        </div>
                                    </div>

                                    <div className="col-span-3  sm:col-span-2">
                                        <label htmlFor="region" className="block text-sm font-medium leading-6 text-gray-900">Premium:</label>
                                        <div className="mt-1.5">
                                            <Switch color='green' id='premium' name='premium' onChange={handleSwitch} checked={formData?.isActive} />
                                        </div>
                                    </div>
                                    <div className="col-span-3 sm:col-span-2">
                                        <label htmlFor="region" className="block text-sm font-medium leading-6 text-gray-900">Active:</label>
                                        <div className="mt-1.5">
                                            <Switch color='green' id='isActive' name='isActive' onChange={handleSwitch} checked={formData?.isActive} />
                                        </div>
                                    </div>
                                </div>
                                <div className='flex justify-end mt-10'>
                                    <Button
                                        onClick={handleClose}
                                        className="mr-2.5 px-5 py-2.5 bg-white shadow-none text-[#1E3354] font-medium border border-[#75869F] hover:shadow-none capitalize text-base"
                                    >
                                        <span>Cancel</span>
                                    </Button>
                                    <Button
                                        disabled={isModalLoading}
                                        className="flex items-center mr-1 px-5 py-2.5 bg-[#0047FF] shadow-none text-white font-medium border border-[#0047FF] hover:shadow-none capitalize text-base"
                                        type='submit'
                                    >
                                        {isModalLoading && <Spinner className="h-4 w-4" />}
                                        <span className={isModalLoading ? 'ml-4' : ''}>{addEditDeleteTag === `add` ? `Add` : `Update`}</span>
                                    </Button>
                                </div>
                            </form>
                            :
                            <div>
                                <div className="mt-5 grid grid-cols-1 lg:grid-cols-6 gap-5 add-edit-form">
                                    <div className="sm:col-span-6">
                                        <label htmlFor="link" className="block text-lg font-medium leading-5 text-[#1E3354]">Link:</label>
                                        <div className="flex items-center justify-between">
                                            <div className="mt-2 flex items-center text-[#2288FF]">
                                                <span className='mr-[5px]'><BiLink className='text-xl leading-none' /></span>
                                                <Typography>{formData?.link || `None`}</Typography>
                                            </div>
                                            {/* <div className=""> */}
                                            <Button variant='text' disabled={!formData?.link} className='p-3 rounded-full' onClick={() => handleCopyData(`Link`, formData?.link)}><MdContentCopy className='text-xl leading-none' /></Button>
                                            {/* </div> */}
                                        </div>
                                    </div>
                                    <div className="sm:col-span-3">
                                        <label htmlFor="name" className="block text-lg font-medium leading-5 text-[#1E3354]">Name:</label>
                                        <div className="mt-2">
                                            <Typography className="text-[17px] text-[#75869F] font-normal leading-5">{formData?.name || `None`}</Typography>
                                        </div>
                                    </div>
                                    <div className="sm:col-span-3">
                                        <label htmlFor="from_flg" className="block text-lg font-medium leading-5 text-[#1E3354]">From Flag:</label>
                                        <div className="mt-2">
                                            <Typography className="text-[17px] text-[#75869F] font-normal leading-5">{formData?.from_flg || `None`}</Typography>
                                        </div>
                                    </div>
                                    <div className="sm:col-span-3">
                                        <label htmlFor="street-address" className="block text-lg font-medium leading-5 text-[#1E3354]">Priority:</label>
                                        <div className="mt-2">
                                            <Typography className="text-[17px] text-[#75869F] font-normal leading-5">{formData?.priority}</Typography>
                                        </div>
                                    </div>
                                    <div className="sm:col-span-3">
                                        <label htmlFor="country" className="block text-lg font-medium leading-5 text-[#1E3354]">Slider Tag:</label>
                                        <div className="mt-2">
                                            <Typography className="text-[17px] text-[#75869F] font-normal leading-5">{formData?.slider_tag || `None`}</Typography>
                                        </div>
                                    </div>
                                    <div className="sm:col-span-3">
                                        <label htmlFor="country" className="block text-lg font-medium leading-5 text-[#1E3354]">Region:</label>
                                        <div className="mt-2">
                                            <Typography className="text-[17px] text-[#75869F] font-normal leading-5">
                                                {formData?.region && formData?.region?.length !== 0 ? formData?.region?.map((_ele, key) => key === _ele.region?.length - 1 ? _ele : `${_ele}, `) : `None`}
                                            </Typography>
                                        </div>
                                    </div>
                                    <div className="sm:col-span-3">
                                        <label htmlFor="country" className="block text-lg font-medium leading-5 text-[#1E3354]">Country:</label>
                                        <div className="mt-2">
                                            <Typography className="text-[17px] text-[#75869F] font-normal leading-5">
                                                {formData?.country && formData?.country?.length !== 0 ? formData?.country?.map((_ele, key) => key === _ele.country?.length - 1 ? _ele : `${_ele}, `) : `None`}
                                            </Typography>
                                        </div>
                                    </div>
                                    <div className="sm:col-span-3">
                                        <label htmlFor="city" className="block text-lg font-medium leading-5 text-[#1E3354]">Orientation:</label>
                                        <div className="mt-2 grid grid-cols-2">
                                            <Typography className="text-[17px] text-[#75869F] font-normal leading-5">{formData?.orientation || `None`}</Typography>
                                        </div>
                                    </div>
                                    <div className="sm:col-span-3">
                                        <label htmlFor="region" className="block text-lg font-medium leading-5 text-[#1E3354]">Active:</label>
                                        <div className="mt-2 ml-2">
                                            {/* <Switch color='green' id='isActive' name='isActive' onChange={handleSwitch} checked={formData?.isActive} /> */}
                                            <Typography className="text-[17px] text-[#75869F] font-normal leading-5">{formData?.isActive ? "Off" : "On"}</Typography>
                                        </div>
                                    </div>
                                </div>
                                <div className='flex justify-end'>
                                    <Button
                                        onClick={handleClose}
                                        className="mr-1 px-5 py-2.5 bg-white shadow-none text-[#1E3354] font-medium border border-[#75869F] hover:shadow-none capitalize text-base"
                                    >
                                        <span>Cancel</span>
                                    </Button>
                                </div>
                            </div>
                    }
                </div>
            </DialogBody>
        </Dialog>
    )
}

export default WallpaperModal
