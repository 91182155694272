import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { IoChevronForwardOutline } from 'react-icons/io5';

export const Splash = ({ title }) => {
    const [load, setLoad] = useState(0);
    const locationDetails = useParams();
    const navigate = useNavigate()

    useEffect(() => { setLoad(0) }, []);

    useEffect(() => {
        let timerId;
        if (load < 4) {
            timerId = setInterval(() => {
                setLoad((prevLoad) => Math.max(0, prevLoad + 1))
            }, 1000)
        } else {
            localStorage.setItem('loginType', locationDetails.loginType)
            localStorage.setItem('userName', locationDetails.userName)
            localStorage.setItem('token', locationDetails.token)
            navigate('/dashboard');
            window.location.reload();
        }

        return () => clearInterval(timerId)
    }, [load])

    useEffect(() => {
        document.title = title;
    }, [title])

    return (
        <div>

            <div className="grid grid-cols-12 gap-2 min-h-screen py-48 px-52">
                <div className=' col-span-12 flex items-center font-extrabold text-4xl'>
                    Welcome, you almost there...
                </div>
                <div className=' grid grid-cols-12 col-span-12 min-w-full gap-10'>
                    <div className="col-span-5 flex flex-col items-center justify-center">
                        <div>
                            <img src='../../assets/images/super-login-icon.svg' className='w-32 h-32' />
                        </div>
                        <div className='mt-3'>
                            <h2 className=" text-center text-5xl font-bold leading-9 tracking-tight text-gray-900">Super Admin</h2>
                        </div>
                    </div>
                    <div className="col-span-2 flex items-center justify-center">
                        <IoChevronForwardOutline className={`w-32 h-32 ${load > 0 ? 'text-[#0047FF]' : 'text-gray-300'}`} />
                        <IoChevronForwardOutline className={`w-32 h-32 ${load > 1 ? 'text-[#0047FF]' : 'text-gray-300'} -ml-14`} />
                        <IoChevronForwardOutline className={`w-32 h-32 ${load > 2 ? 'text-[#0047FF]' : 'text-gray-300'} -ml-14`} />
                    </div>
                    <div className="col-span-5 flex flex-col items-center justify-center">
                        <div>
                            <img src='../../assets/images/login_logo.svg' className='w-32 h-32' />
                        </div>
                        <div className='mt-3'>
                            <h2 className=" text-center text-5xl font-bold leading-9 tracking-tight text-gray-900">My Photo Keyboard</h2>
                        </div>
                    </div>
                </div>
                <div className=' grid grid-cols-12 col-span-12 min-w-full gap-10 px-28'>
                    <div className=' col-span-12 flex items-end justify-end font-extrabold text-4xl'>
                        Please wait...
                    </div>
                </div>
            </div >
        </div>
    )
};

Splash.defaultProps = {
    title: "Loading Photo Keyboard..."
};

export default Splash;


