import React, { useContext, useEffect, useState } from 'react'
import { Outlet } from 'react-router-dom';

import { Header, Sidebar } from '../../Components';
import { NewContext } from '../../Context/NewContext';

import './layout.css';

export const Layout = () => {
    const [path, setPath] = useState(window.location.href.split('/').pop());
    const { setSearch, setPerPage, setFilterDropdown } = useContext(NewContext);

    useEffect(() => {
        setSearch(""); setPerPage(15); setFilterDropdown({});
    }, [path])
    return (
        <div className='flex flex-row min-h-screen bg-gray-100 text-gray-800'>
            <Sidebar />
            {/* <MobileHeader /> */}
            {/* ${toggleSidebar ? `w-[calc(100%-18rem)]` : `w-full`} */}
            <main className={`ml-auto flex flex-col w-full 2xl:w-[calc(100%-18rem)]`}>
                <Header title={"Title"} />
                <span className='px-4'>
                    <Outlet />
                </span>
            </main>
        </div >
    )
}

export default Layout