import React from 'react'

export const CommunityBtn = ({ approvedBtn = false, pendingBtn = false, rejectBtn = false,pendingBtnUser = false, violationBtn = false,onClick }) => {
    return (
        <>
            <div className="flex gap-2">
                {
                    approvedBtn &&
                    <div className="">
                        <button className='bg-[#2BC127] text-white py-3.5 px-4 rounded-lg text-sm leading-none font-medium' onClick={onClick}>Approved</button>
                    </div>
                }
                {
                    pendingBtn &&
                    <div className="">
                        <button className='bg-[#0047FF] text-white py-3.5 px-4 rounded-lg text-sm leading-none font-medium' onClick={onClick}>Move to pending</button>
                    </div>
                }
                {
                    pendingBtnUser &&
                    <div className="">
                        <button className='bg-[#0047FF] text-white py-3.5 px-4 rounded-lg text-sm leading-none font-medium' onClick={onClick}>pending</button>
                    </div>
                }
                {
                    rejectBtn &&
                    <div className="">
                        <button className='bg-[#DD2B2B] text-white py-3.5 px-4 rounded-lg text-sm leading-none font-medium' onClick={onClick}>Reject</button>
                    </div>
                }
                {
                    violationBtn &&
                    <div className="">
                        <button className='bg-[#0047FF] text-white py-3.5 px-4 rounded-lg text-sm leading-none font-medium' onClick={onClick}>Remove All Violation</button>
                    </div>
                }
            </div>
        </>
    )
}

export default CommunityBtn
