import { Dialog, DialogContent, DialogTitle } from '@mui/material'
import React, { useContext, useState } from 'react'
import { NewContext } from '../../../Context/NewContext';
import { Button, Spinner, Typography } from '@material-tailwind/react';
import ReportChildModal from './ReportChildModal';
import { useEffect } from 'react';
import { getOneThemeOffensiveReport } from '../../../Controller/actions'

export const ReportModal = () => {
    const { open, isLoading, handleClose, handleReportChildOpen, formData, setDeleteUpdateItem, setDeleteUpdateYes, confirmationModalShow, setConfirmationModalShow } = useContext(NewContext);
    const [Themereson, setThemereson] = useState([]);
    const themeId = formData?.reportOnThemeId?.map((item) => item._id)
    useEffect(() => {
        if (themeId) {
            getOneThemeOffensiveReport(themeId).then((res) => {
                setThemereson(res.data)
            })
        }
    }, [formData])
    return (
        <>
            <Dialog open={open}
                onClose={handleClose}
                aria-labelledby="responsive-dialog-title"
                sx={{
                    "& .MuiDialog-container": {
                        "& .MuiPaper-root": {
                            width: "100%",
                            maxWidth: "885px",
                            borderRadius: "12px" // Set your width here
                        },
                    },
                }}
            >
                <DialogTitle id="responsive-dialog-title" className='p-0 mb-[30px] text-[#1E1627] !text-[28px] font-medium leading-none'>
                    {"Report Details View"}
                </DialogTitle>

                <DialogContent>

                    {
                        formData?.reportOnThemeId?.map((item, i) => {
                            return (
                                <>
                                    <div className="">
                                        <div className="mb-4 bg-[#EAEDF3] p-2.5">
                                            <h6 className="text-[#1E3354] text-lg leading-none font-semibold ">{"Themes Details"}</h6>
                                        </div>
                                        <div className="mb-4 grid grid-cols-12 gap-2 ">
                                            <div className="col-span-6 sm:col-span-3">
                                                <label htmlFor="name" className="block text-lg font-medium leading-5 text-[#1E3354]">Thumbnail:</label>
                                                <div className="mt-2">
                                                    <img src={item?.preview} alt="" className='rounded-md' />
                                                </div>
                                            </div>
                                            <div className="col-span-6 sm:col-span-3">
                                                <label htmlFor="name" className="block text-lg font-medium leading-5 text-[#1E3354]">Preview:</label>
                                                <div className="mt-2">
                                                    <img src={item?.smallPreview} alt="" className='rounded-md' />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="mb-2 grid grid-cols-12 border-b border-[#B4BDCE] pb-3 gap-2">
                                            <div className="col-span-6 sm:col-span-3">
                                                <label htmlFor="name" className="block text-lg font-medium leading-5 text-[#1E3354]">Title:</label>
                                                <div className="mt-2">
                                                    <Typography className="text-[17px] text-[#75869F] font-normal leading-5">{item?.title}</Typography>
                                                </div>
                                            </div>
                                            <div className="col-span-6 sm:col-span-3">
                                                <label htmlFor="name" className="block text-lg font-medium leading-5 text-[#1E3354]">Likes:</label>
                                                <div className="mt-2">
                                                    <Typography className="text-[17px] text-[#75869F] font-normal leading-5">{item?.like?.length}</Typography>
                                                </div>
                                            </div>
                                            <div className="col-span-6 sm:col-span-6">
                                                <label htmlFor="name" className="block text-lg font-medium leading-5 text-[#1E3354]">Dislikes:</label>
                                                <div className="mt-2">
                                                    <Typography className="text-[17px] text-[#75869F] font-normal leading-5">{item?.dislike?.length}</Typography>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="mb-[30px] grid grid-cols-12">
                                            <div className="col-span-6 sm:col-span-3">
                                                <label htmlFor="name" className="block text-lg font-medium leading-5 text-[#1E3354]">Downloads:</label>
                                                <div className="mt-2">
                                                    <Typography className="text-[17px] text-[#75869F] font-normal leading-5">{item?.downloadCount}</Typography>
                                                </div>
                                            </div>
                                            <div className="col-span-6 sm:col-span-9">
                                                <label htmlFor="name" className="block text-lg font-medium leading-5 text-[#1E3354]">Status:</label>
                                                <div className="mt-2">
                                                    <Typography className="text-[17px] text-[#75869F] font-normal leading-5">{item?.themeStatus}</Typography>
                                                </div>
                                            </div>
                                        </div>
                                        {
                                            formData?.reportBy?.map((reportByData, i) => {
                                                const { email, loginType, profileImgType, Gender, mobile, name, loginKey } = reportByData
                                                return (
                                                    <>
                                                        <div className="mb-4 bg-[#EAEDF3] p-2.5">
                                                            <h6 className="text-[#1E3354] text-lg leading-none font-semibold ">{"User Details"}</h6>
                                                        </div>
                                                        <div className="mb-2 grid grid-cols-12 border-b border-[#B4BDCE] pb-3 gap-2">
                                                            <div className="col-span-6 sm:col-span-3">
                                                                <label htmlFor="name" className="block text-lg font-medium leading-5 text-[#1E3354]">Profile:</label>
                                                                <div className="mt-2">
                                                                    <Typography className="text-[17px] text-[#75869F] font-normal leading-5">{name}</Typography>
                                                                </div>
                                                            </div>
                                                            <div className="col-span-6 sm:col-span-3">
                                                                <label htmlFor="name" className="block text-lg font-medium leading-5 text-[#1E3354]">User Name:</label>
                                                                <div className="mt-2">
                                                                    <Typography className="text-[17px] text-[#75869F] font-normal leading-5">{name}</Typography>
                                                                </div>
                                                            </div>
                                                            <div className="col-span-6 sm:col-span-3">
                                                                <label htmlFor="name" className="block text-lg font-medium leading-5 text-[#1E3354]">Contact No:</label>
                                                                <div className="mt-2">
                                                                    <Typography className="text-[17px] text-[#75869F] font-normal leading-5">{mobile}</Typography>
                                                                </div>
                                                            </div>
                                                            <div className="col-span-6 sm:col-span-3">
                                                                <label htmlFor="name" className="block text-lg font-medium leading-5 text-[#1E3354]">Gender:</label>
                                                                <div className="mt-2">
                                                                    <Typography className="text-[17px] text-[#75869F] font-normal leading-5">{Gender}</Typography>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="mb-2 grid grid-cols-12 gap-2">
                                                            <div className="col-span-6 sm:col-span-3">
                                                                <label htmlFor="name" className="block text-lg font-medium leading-5 text-[#1E3354]">Login From:</label>
                                                                <div className="mt-2">
                                                                    <Typography className="text-[17px] text-[#75869F] font-normal leading-5">{loginType}</Typography>
                                                                </div>
                                                            </div>
                                                            <div className="col-span-6 sm:col-span-3">
                                                                <label htmlFor="name" className="block text-lg font-medium leading-5 text-[#1E3354]">Profile Type:</label>
                                                                <div className="mt-2">
                                                                    <Typography className="text-[17px] text-[#75869F] font-normal leading-5">{profileImgType}</Typography>
                                                                </div>
                                                            </div>
                                                            <div className="col-span-6 sm:col-span-3">
                                                                <label htmlFor="name" className="block text-lg font-medium leading-5 text-[#1E3354]">Login Key:</label>
                                                                <div className="mt-2">
                                                                    <Typography className="text-[17px] text-[#75869F] font-normal leading-5">{loginKey}</Typography>
                                                                </div>
                                                            </div>
                                                            <div className="col-span-6 sm:col-span-3">
                                                                <label htmlFor="name" className="block text-lg font-medium leading-5 text-[#1E3354]">Email id:</label>
                                                                <div className="mt-2">
                                                                    <Typography className="text-[17px] text-[#75869F] font-normal leading-5">{email}</Typography>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </>
                                                )
                                            })
                                        }
                                        <div className="mb-4 bg-[#EAEDF3] p-2.5">
                                            <div className="flex items-center justify-between">
                                                <h6 className="text-[#1E3354] text-lg leading-none font-semibold ">{"Offensive Report Details"}</h6>
                                                <div className="flex items-center gap-5">
                                                    <p className='text-[#1E3354] text-lg leading-none font-semibold'>{`No. of Reports ${Themereson?.length || 0} `}</p>
                                                    <Button onClick={() => { handleReportChildOpen(); }} className='bg-[#0047FF] text-white px-3 py-2 rounded-[3px] text-base leading-none font-medium shadow-none hover:shadow-none capitalize'>View All</Button>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="mb-2 grid grid-cols-12">
                                            <div className="col-span-12">
                                                <table className='w-full max-w-max text-left gap-2'>
                                                    <thead className='text-[#1E3354] text-lg leading-none' >
                                                        <tr>
                                                            <th className='w-60'>Name</th>
                                                            <th className='w-60'>Email id</th>
                                                            <th className='w-60'>Reason</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody className='text-[17px] text-[#75869F]'>
                                                        {
                                                            Themereson?.slice(0, 2)?.map((itemData, i) => {
                                                                return (<>
                                                                    <tr>
                                                                        <td>{i + 1} {itemData?.reportBy?.name || "None"}</td>
                                                                        <td>{itemData?.reportBy?.email || "None"}</td>
                                                                        <td>{itemData?.reasonId?.reason || "None"}</td>
                                                                    </tr>
                                                                </>)
                                                            })
                                                        }

                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>

                                        <div className='flex flex-row justify-end mt-10 gap-3'>
                                            <Button onClick={() => { handleClose(); }} className="px-5 py-2.5 bg-transparent shadow-none text-[#1E3354] font-medium border border-[#75869F] hover:shadow-none capitalize text-xs md:text-base text-center">
                                                Cancel
                                            </Button>
                                            <Button className='bg-[#0047FF] text-white px-5 py-2.5 rounded-lg  text-xs md:text-base text-center  leading-none font-medium shadow-none hover:shadow-none capitalize' onClick={() => { setDeleteUpdateYes("delete-Report"); setDeleteUpdateItem({ id: item?._id }); setConfirmationModalShow(!confirmationModalShow) }}>Remove All Violation</Button>
                                            <Button className='bg-[#0047FF] text-white px-5 py-2.5 rounded-lg  text-xs md:text-base text-center  leading-none font-medium shadow-none hover:shadow-none capitalize' onClick={() => { setDeleteUpdateYes("update-approvedTheme"); setDeleteUpdateItem({ id: formData?.reportOnThemeId[0]?._id, themeStatus: 'pending' }); setConfirmationModalShow(!confirmationModalShow) }} >
                                                Move to pending
                                            </Button>
                                            <Button
                                                disabled={isLoading}
                                                className="px-5 py-2.5 bg-transparent shadow-none text-[#DD2B2B] font-medium border border-[#DD2B2B] hover:shadow-none capitalize  text-xs md:text-base text-center "
                                                type='submit'
                                                onClick={() => { setDeleteUpdateYes("delete-Report"); setDeleteUpdateItem({ id: item._id }); setConfirmationModalShow(!confirmationModalShow) }}
                                            >
                                                {isLoading && <Spinner className="h-4 w-4" />}
                                                <span className={isLoading ? 'ml-4' : ''}>{"Delete"}</span>
                                            </Button>
                                        </div>
                                    </div>
                                </>
                            )
                        })
                    }


                </DialogContent>
            </Dialog>
            <ReportChildModal />
        </>
    )
}

export default ReportModal
