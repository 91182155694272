import React, { useContext, useEffect } from 'react'
import { Button, Option, Select } from '@material-tailwind/react'
import { AiOutlinePlusSquare } from 'react-icons/ai'

import { NewContext } from '../../Context/NewContext'

export const HomeActivityModalHeader = ({ nonActive = true, addBtn = true }) => {
    const { addEditDeleteTag, handleChange, setAddEditDeleteTag, handleChildOpen, homeScreenData, currentHomeModalKey, setCurrentHomeModalKey, setHomeTableData, currentModalTableData, formData, setFormData, deleteUpdateTag } = useContext(NewContext);
    const handleChnageOption = (e) => {
        console.log(e, '==========Handle change e=============');
    }
    return (
        <>
            <div className="mb-[30px] flex items-center justify-between sticky">
                <div className="flex flex-wrap gap-[15px] items-center">
                    <div className="flex items-center">
                        <label htmlFor="name">Name</label>
                        <input type="text" name='name' className='bg-[#F8F8FA] px-2 py-[11px] border-[#BCC2CC] border-[1.5px] rounded-lg focus-visible:outline-none ml-[15px] w-[201px]' value={formData?.name} onChange={handleChange} />
                    </div>
                    <div className="flex items-center">
                        <label htmlFor="name">Section Type</label>
                        <div className='ml-[15px] add-edit-form'>
                            <Select onChange={(e) => setCurrentHomeModalKey(e)} value={formData?.key?.toLowerCase()} containerProps={{ className: "h-12" }}>
                                {currentModalTableData.section_setting?.map((_ele, i) => _ele?.name && <Option key={i} value={_ele.key?.toLowerCase()}>{_ele?.name}</Option>)}
                            </Select>
                        </div>
                    </div>
                    <div className="flex items-center">
                        <label htmlFor="name">Data Limit</label>
                        <input disabled={!['top_creator_theme', 'Game', 'wallpaper', 'sticker'].includes(currentHomeModalKey)} name='limit' type="number" className='bg-[#F8F8FA] px-2 py-[11px] border-[#BCC2CC] border-[1.5px] rounded-lg focus-visible:outline-none ml-[15px] w-[103px]' value={formData?.limit} onChange={handleChange} />
                    </div>
                </div>
                <div className='flex'>
                    {
                        nonActive &&
                        <div className="">
                            <Button disabled={['sticker', 'wallpaper'].includes(currentHomeModalKey)} className='flex items-center px-5 py-[13px] bg-[#0047FF] capitalize text-sm leading-none font-medium whitespace-nowrap shadow-none hover:shadow-none' onClick={() => { setAddEditDeleteTag(`view`); handleChildOpen() }}>None Active Data <AiOutlinePlusSquare size={"22px"} className='ml-2.5' /></Button>
                        </div>
                    }
                    {
                        addBtn &&
                        <div className="">
                            <Button className='flex items-center px-5 py-[13px] bg-[#0047FF] capitalize text-sm leading-none font-medium whitespace-nowrap shadow-none hover:shadow-none ml-4' onClick={() => { setAddEditDeleteTag(`add`); handleChildOpen() }}>Add New <AiOutlinePlusSquare size={"22px"} className='ml-2.5' /></Button>
                        </div>
                    }
                </div>
            </div>
        </>
    )
}

export default HomeActivityModalHeader
