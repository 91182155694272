import React, { useContext } from 'react'
import { NewContext } from '../../../Context/NewContext'
import { useNavigate } from 'react-router-dom';
import { Button } from "@material-tailwind/react";
import { BsCalendar4Week } from "react-icons/bs";
import moment from 'moment';

const PreviewBlog = () => {
    const { addEditDeleteTag, formData, imgObj } = useContext(NewContext);
    const navigate = useNavigate();
    return (
        <>
            <div className='grid grid-cols-12 gap-x-5 p-10'>

                <div className='col-span-12 relative'>
                    <h1 className='text-[20px] md:text-[40px] font-bold  text-[#222933] leading-[42px]'>{formData?.title}</h1>
                    <img fetchpriority='low' loading='lazy' src={addEditDeleteTag === 'update-c' ? formData?.preview : imgObj?.blogPreview} alt={formData?.title} className='w-auto mx-auto mt-6 rounded-md' />
                    <div className="flex flex-col md:flex-row items-start md:items-center justify-between gap-x-3 mt-0 md:mt-7">
                        <div className="!order-2 tracking-wide md:!order-1 text-sm xl:text-xl mt-5 md:mt-0 inline-block align-middle text-center select-none !font-medium  whitespace-no-wrap leading-normal text-[#222933]">
                            Published By: <span className=' text-[#FEB811]'>Admin</span>
                        </div>
                        <div className='hidden md:flex items-center !order-1 md:!order-2 md:gap-x-4 gap-x-6 tracking-wide'>
                            <p className="font-medium flex items-center !mb-0 text-xl">
                                <BsCalendar4Week color='#6C6059' /><span className='md:ms-1 ms-2 md:text-lg text-xl text-[#6C6059]'>{moment(formData?.scheduleDate).format("MMMM Do YYYY")}</span>
                            </p>
                        </div>
                    </div>
                    <div className='text-lg xl:text-xl font-semibold pt-5 text-[#222933] tracking-wide'>
                        Category: <span className='text-[#622300]'>{formData?.categoriesName}</span>
                    </div>

                    <div className="blogsection  pe-4 lg:px-0 mt-2  text-gray-700  text-lg leading-relaxed" style={{ wordBreak: "break-word" }}>
                        <div dangerouslySetInnerHTML={{ __html: formData?.blogDetails || "-" }} ></div>
                    </div>


                    <div className='flex justify-end mt-5'>
                        <Button
                            onClick={() => navigate('/addBlogs')}
                            className="mr-1 px-5 py-2.5 bg-white shadow-none text-[#1E3354] font-medium border border-[#75869F] hover:shadow-none capitalize text-base"
                        >
                            <span>Cancel</span>
                        </Button>
                    </div>
                </div>
            </div>
        </>
    )
}

export default PreviewBlog
