import React from 'react'

export const Switch = ({ id, name, checked, onChange, disabled = false }) => {
    return (
        <label className="switch">
            <input type="checkbox" id={id} name={name} checked={checked} onChange={onChange} disabled={disabled} />
            <p className="inline slider"></p>
        </label>
    )
}

export default Switch