import React, { useContext, useEffect, useState } from 'react'
import { Card, CardBody, IconButton, Checkbox, Spinner, Tooltip } from "@material-tailwind/react";
import { BiEdit } from 'react-icons/bi';

import { Pagination, SearchAndAddBtn, THead, Switch } from '../../../Components';
import { NewContext } from '../../../Context/NewContext';
import { get_tag } from '../../../Controller/actions';
import ThemeTagModal from '../../../Components/Modals/ThemeTagModal';
import { InformationCircleIcon } from '@heroicons/react/24/outline';
import { RiDeleteBinLine } from 'react-icons/ri';

// const FilterDropdowns = () => {
//     const countryFilterData = ["Afghanistan", "India", "Pakistan"]
//     return (
//         <div className='flex gap-4'>
//             <Dropdowns name={`Country`} Icon={TbWorld} dropData={countryFilterData} />
//             <Dropdowns name={`Categories`} Icon={MdOutlineCategory} dropData={countryFilterData} />
//             <Dropdowns name={`Total Downloads`} Icon={HiDownload} dropData={countryFilterData} />
//         </div>
//     )
// }

export const ThemeTags = ({ title }) => {
    const { permissions, tokenType, setAddEditDeleteTag, setFormData, confirmationModalShow, setConfirmationModalShow, setDeleteUpdateItem, handleSwitchUpdate, setDeleteUpdateTag, isLoading, deleteUpdateTag, checkedValues, handleCheckboxChange, currentPage, totalPage, perPage, search, setDeleteUpdateYes, setIsLoading, setCurrentPage, setPerPage, setTotalPage, currentPageData, setCurrentPageData, filterDropdown, handleOpen } = useContext(NewContext);
    const simpleFields = [
        { name: "Name", sortable: true, field: 'name' },
        { name: "Country", },
        { name: "User Hits", },
    ];
    const [pagePermissions, setPagePermissions] = useState({
        CU: true,
        R: true,
        D: true
    });
    const dropdownFields = [{ name: "Active", }, { name: "Trending", }];
    const totalFields = simpleFields.length + dropdownFields.length + 2;
    const getTagsData = async (_currentPage = 1) => {
        setIsLoading(true);
        try {
            const getTags = await get_tag(_currentPage, perPage, search, filterDropdown);
            if (getTags) {
                const { status, data, currentPage, perPage, totalPage } = getTags;
                if (status) {
                    setCurrentPageData(data || []);
                    setCurrentPage(currentPage);
                    setPerPage(perPage);
                    setTotalPage(totalPage || 1);
                } else {
                    setCurrentPageData([]);
                }
            }
        } catch (error) {
            console.error(error);
        }
        setIsLoading(false);
    }
    useEffect(() => {
        getTagsData(currentPage);
        setDeleteUpdateTag("");
    }, [deleteUpdateTag, search, currentPage, perPage, filterDropdown, pagePermissions]);

    useEffect(() => {
        if (tokenType === 'ldap') {
            const update = permissions?.subPermissions?.find((route) => route.name === 'Theme Tags');
            setPagePermissions({ ...pagePermissions, CU: update?.CU, R: update?.R, D: update?.D })
        }
    }, [tokenType, permissions])

    useEffect(() => {
    }, [pagePermissions])
    useEffect(() => {
        document.title = title;
    }, [title])
    return (
        <>
            <SearchAndAddBtn title={`Theme Tags`} addBtn={pagePermissions?.CU} FilterDropdowns={false} deleteUpdateTag='all-delete-tags' />
            <Card className="overflow-hidden w-full rounded-md shadow-none">
                <CardBody className="overflow-x-auto w-100 py-2.5 px-[5px] card-body-scrollbar">
                    <table className="w-full min-w-max table-auto text-left ">
                        <THead simpleFields={simpleFields} dropdownFields={dropdownFields} />
                        <tbody>
                            {
                                isLoading ?
                                    <tr>
                                        <td colSpan={totalFields} className='py-12'>
                                            <div className="flex justify-center items-center">
                                                <Spinner />
                                            </div>
                                        </td>
                                    </tr>
                                    : currentPageData?.length === 0 ?
                                        <tr>
                                            <td colSpan={totalFields} className='py-12 bg-gray-200'>
                                                <div className="flex justify-center items-center">
                                                    No Data Found
                                                </div>
                                            </td>
                                        </tr>
                                        : currentPageData?.map((_ele, i) => {
                                            const isLast = i === currentPageData?.length - 1;
                                            const classes = `text-[#1E1627] ${isLast ? "p-4" : "p-[14.5px]"}`;

                                            return (
                                                <tr key={i} className='border-b border-blue-gray-50'>
                                                    <td className={classes}>
                                                        <Checkbox
                                                            className='w-[18px] h-[18px] border-[1.5px] rounded-[3.6px] border-[#1E3354] checked:bg-[#0047FF] checked:border-transparent'
                                                            id={`slider-${_ele._id}`}
                                                            value={`${_ele._id}`}
                                                            disabled={!pagePermissions?.CU}
                                                            checked={checkedValues.includes(`${_ele._id}`)}
                                                            onChange={() => handleCheckboxChange(`${_ele._id}`)}
                                                        />
                                                    </td>
                                                    <td className={classes}>
                                                        <div className="flex items-center">
                                                            <span className={`font-normal text-[17px] ${!_ele.name && `text-gray-500`}`}>{_ele.name || `None`}</span>
                                                            {/* {
                                                        link && <button onClick={() => handleCopyData(link)}><BiLink className='text-[#2288FF] ml-2.5' size={20} /></button>
                                                    } */}
                                                        </div>
                                                    </td>
                                                    <td className={`${classes}`}>
                                                        <div className="flex items-center">
                                                            <p className={`font-normal !w-40 truncate p-0 text-[17px] ${!_ele.country?.length && `text-gray-500`}`}>
                                                                {_ele.country && _ele.country?.length !== 0 && _ele.country?.length > 1 ? _ele.country.map(_ele => _ele.name).slice(0, -1).join(", ") + ", " + _ele.country[_ele.country.length - 1].name : _ele.country && _ele.country?.length !== 0 && _ele.country[_ele.country.length - 1].name || `None`}
                                                            </p>
                                                            {
                                                                _ele.country && _ele.country.length > 1 &&
                                                                <Tooltip placement="right-start" content={
                                                                    <div className="p-2 w-[300px] h-48 2xl:w-[400px] overflow-y-auto">
                                                                        {_ele.country && _ele.country?.length !== 0 && _ele.country?.length > 1 ? _ele.country.map(_ele => _ele.name).slice(0, -1).join(", ") + ", " + _ele.country[_ele.country.length - 1].name : _ele.country && _ele.country?.length !== 0 && _ele.country[_ele.country.length - 1].name || `None`}
                                                                    </div>
                                                                }>
                                                                    <InformationCircleIcon strokeWidth={2} className="text-[#1E1627] w-5 h-5 cursor-pointer" />
                                                                </Tooltip>
                                                            }
                                                        </div>
                                                    </td>
                                                    <td className={classes}>
                                                        <span className={`font-normal text-[17px] ${!_ele.userHit && `text-gray-500`}`}>{_ele.userHit || `None`}</span>
                                                    </td>
                                                    {
                                                        ["isActive", "isTrending"].map((_is_ele, i) => (
                                                            <td className={classes} key={i}>
                                                                <div className="ml-1">
                                                                    {/* <Switch id={_ele._id} name={_is_ele} color="green" onChange={(e) => { setDeleteUpdateYes("updateslider"); handleSwitch(e) }} checked={_ele[_is_ele]} /> */}
                                                                    <Switch disabled={!pagePermissions?.CU} id={_ele._id} name={_is_ele} onChange={(e) => { setDeleteUpdateYes("update-themetags"); handleSwitchUpdate(e); }} checked={_ele[_is_ele]} />
                                                                </div>
                                                            </td>
                                                            // <td key={i}>
                                                            //     <Badge bg={_is_ele ? "success" : "danger"} className="fw-normal px-2">
                                                            //     {_is_ele ? `On` : `Off`}
                                                            // </Badge>
                                                            //     <Form.Check type="switch" id={_ele._id} name={_is_ele} onChange={handleSwitch} checked={_ele[_is_ele]} role="button" />
                                                            // </td>
                                                        ))
                                                    }
                                                    <td className={classes}>
                                                        <div className="flex items-center gap-2">
                                                            <IconButton className='bg-[#EAEDF3] text-[#0047FF] text-[21px] shadow-none hover:shadow-none' disabled={!pagePermissions?.CU} onClick={() => { setDeleteUpdateYes("update-themetags"); setAddEditDeleteTag(`update`); setFormData(_ele); handleOpen() }}>
                                                                <BiEdit />
                                                            </IconButton>
                                                            {/* <IconButton className='bg-[#EAEDF3] text-[#0047FF] text-[21px] shadow-none hover:shadow-none' onClick={() => { setAddEditDeleteTag(`view`); setFormData(_ele); handleOpen() }}>
                                                                <IoEyeOutline />
                                                            </IconButton> */}
                                                            <IconButton className='bg-[#EAEDF3] text-[#F62929] text-[21px] shadow-none hover:shadow-none' disabled={!pagePermissions?.D} onClick={() => { setDeleteUpdateYes("delete-tags"); setDeleteUpdateItem({ id: _ele._id, name: _ele.name }); setConfirmationModalShow(!confirmationModalShow) }}>
                                                                <RiDeleteBinLine />
                                                            </IconButton>
                                                        </div>
                                                    </td>
                                                </tr>
                                            );
                                        })
                            }
                        </tbody>
                    </table>
                </CardBody>
            </Card>
            {
                totalPage > 1 &&
                <Pagination currentPage={currentPage} totalPage={totalPage} getData={getTagsData} />
            }
            <ThemeTagModal />
        </>
    )
}
ThemeTags.defaultProps = {
    title: "My Photo Keyboard | ThemeTags"
};


export default ThemeTags
