import React, { useContext, useEffect, useState } from 'react'
import { Card, CardBody, IconButton, Checkbox, Spinner, Avatar } from "@material-tailwind/react";
import { IoEyeOutline } from 'react-icons/io5';
import { TbWorld } from 'react-icons/tb';
import { HiDownload } from 'react-icons/hi';

import { CommunityBtn, Dropdowns, Pagination, SearchAndAddBtn, THead } from '../../../Components';
import { NewContext } from '../../../Context/NewContext';
import { get_community_user_data, get_theme_categories_data } from '../../../Controller/actions';
import { MdOutlineCategory } from 'react-icons/md';
import { RiDeleteBinLine } from 'react-icons/ri';
import Zoom from 'react-medium-image-zoom';
import ReasonListModel from '../../../Components/Modals/ReasonListModel';


const FilterDropdowns = () => {
  return (
    <div className='flex gap-4'>
      {/* <Dropdowns title={`Gender`} name={`gender`} Icon={TbWorld} dropData={[{id: 1, label: 'Male'},{id: 2, label: 'Female'}]} /> */}
      {/* <Dropdowns title={`User Rating`} name={`user_rating`} Icon={MdOutlineCategory} dropData={[]} /> */}
      <Dropdowns title={`Total Downloads`} name={"download"} Icon={HiDownload} dropData={[]} download={true} />
    </div>
  )
}

export const Users = ({ title }) => {
  const { permissions, tokenType, setCheckedValues, handleOpenReason, setFormData, currentUserId, setCurrentUserId, isLoading, deleteUpdateTag, checkedValues, handleCheckboxChange, currentThemePage, setCurrentThemePage, currentPage, totalPage, perPage, search, setDeleteUpdateYes, handleSwitchUpdate, handleCopyData, setIsLoading, setCurrentPage, setPerPage, setTotalPage, currentPageData, setCurrentPageData, filterDropdown, handleOpen, isShown, setDeleteUpdateItem, setConfirmationModalShow, confirmationModalShow } = useContext(NewContext);
  const [totalThemePages, setTotalThemePages] = useState(1);
  const [currentUsersThemes, setCurrentUsersThemes] = useState([]);
  const [pagePermissions, setPagePermissions] = useState({
    CU: true,
    R: true,
    D: true
  });
  useEffect(() => {
    if (currentUserId) {
      const filterUser = currentPageData?.find(_e => _e._id === currentUserId);
      setTotalThemePages(Math.ceil(filterUser?.userTheme?.length / perPage));
      const startIndex = (currentThemePage - 1) * perPage;
      const endIndex = startIndex + perPage;
      const currentUserThemeData = filterUser?.userTheme?.slice(startIndex, endIndex);
      setCurrentUsersThemes(currentUserThemeData)
    }
  }, [currentUserId, currentThemePage])

  const handlePageChange = (pageNumber) => setCurrentThemePage(pageNumber);

  const handleThemeSelectAll = (e) => {
    e.target.checked ? setCheckedValues([...currentPageData?.map(_ele => _ele._id)]) : setCheckedValues([])
  };

  const simpleFields = [
    { name: "User Name", sortable: true, field: 'name' },
    { name: "User Email", sortable: true, field: 'email' },
    { name: "Gender", sortable: true, field: 'Gender' },
    { name: "Uploaded", sortable: true, field: 'Uploaded' },
    { name: "Downloads", sortable: true, field: 'totalDownload' },
    { name: "Likes", sortable: true, field: 'totalLike' },
    { name: "Dislike", sortable: true, field: 'totalDisLike' },
  ];
  const simpleFields1 = [
    { name: "Theme", sortable: true, field: 'preview' },
    { name: "Name", sortable: true, field: 'title' },
    { name: "Download", sortable: true, field: 'downloadCount' },
    { name: "Likes", sortable: true, field: 'totalLike' },
    { name: "Dislike", sortable: true, field: 'totalDisLike' },
    { name: "Status", sortable: true, field: 'themeStatus' },
  ];
  const dropdownFields = [{ name: "Active", }];
  const totalFields = simpleFields.length + dropdownFields.length + 2;

  const getThemeCategoriesData = async (_currentPage = 1) => {
    setIsLoading(true);
    try {
      const getThemeCategories = await get_community_user_data(_currentPage, perPage, search, filterDropdown);
      if (getThemeCategories) {
        const { status, data, currentPage, perPage, totalPage } = getThemeCategories;
        if (status) {
          setCurrentPageData(data);
          setCurrentPage(currentPage);
          setPerPage(perPage);
          setTotalPage(totalPage || 1);
        } else {
          // toast.error(message);
          setCurrentPageData([])
        }
        setIsLoading(false);
      }
    } catch (error) {
      console.error(error);
    }
  }

  useEffect(() => {
    if (!currentUserId) {
      getThemeCategoriesData(currentPage);
    }
  }, [deleteUpdateTag, search, currentPage, perPage, currentUserId, filterDropdown, setDeleteUpdateItem, pagePermissions]);

  useEffect(() => {
    if (tokenType === 'ldap') {
      const update = permissions?.subPermissions?.find((route) => route.name === 'Users');
      setPagePermissions({ ...pagePermissions, CU: update?.CU, R: update?.R, D: update?.D })
    }
  }, [tokenType, permissions])

  useEffect(() => {
  }, [pagePermissions])

  useEffect(() => {
    document.title = title;
  }, [title])

  return (
    <>
      <SearchAndAddBtn title={`Users`} addBtn={false} deleteUpdateTag={currentUserId ? 'all-delete-approvedTheme' : 'all-delete-communityUser'} FilterDropdowns={<FilterDropdowns dropData={[{ id: 1, label: "All" }]} />} countryFilter={false} />
      <Card className="overflow-hidden w-full rounded-md shadow-none">
        <CardBody className="overflow-x-auto w-100 py-2.5 px-[5px] card-body-scrollbar">

          <table className="w-full min-w-max table-auto text-left ">
            {
              currentUserId ?
                <>
                  <THead simpleFields={simpleFields1} dropdownFields={false} handleSelectAll={handleThemeSelectAll} />
                  <tbody>
                    {
                      isLoading ?
                        <tr>
                          <td colSpan={totalFields} className='py-12'>
                            <div className="flex justify-center items-center">
                              <Spinner />
                            </div>
                          </td>
                        </tr>
                        : currentUsersThemes?.length === 0 ?
                          <tr>
                            <td colSpan={totalFields} className='py-12 bg-gray-200'>
                              <div className="flex justify-center items-center">
                                No Data Found
                              </div>
                            </td>
                          </tr>
                          : currentUsersThemes?.map((_ele, i) => {
                            const isLast = i === currentUsersThemes?.length - 1;
                            const classes = `text-[#1E1627] ${isLast ? "p-4" : "p-[14.5px]"}`;

                            return (
                              <tr key={i} className='border-b border-blue-gray-50'>
                                <td className={classes}>
                                  <Checkbox
                                    className='w-[18px] h-[18px] border-[1.5px] rounded-[3.6px] border-[#1E3354] checked:bg-[#0047FF] checked:border-transparent'
                                    id={`slider-${_ele._id}`}
                                    value={`${_ele._id}`}
                                    disabled={!pagePermissions?.CU}
                                    checked={checkedValues.includes(`${_ele._id}`)}
                                    onChange={() => handleCheckboxChange(`${_ele._id}`)}
                                  />
                                </td>
                                {/* <td className={`${classes}`}>
                                  <img src={_ele.preview || `../../../assets/images/no-image.png`} alt={`user image`} className='mr-0 h-[55px] rounded-lg' />
                                </td> */}
                                <td className={`${classes} relative`}>
                                  <img src={_ele.preview || `../../../assets/images/no-image.png`} alt={_ele.title} className='mr-0 h-[55px] rounded-lg block absolute' />
                                  <Zoom>
                                    {
                                      _ele?.backgroundType == "Gif" ?
                                        <div className='relative'>
                                          <img src={_ele?.preview || `../../../assets/images/no-image.png`} alt="/" className='mr-0 h-[55px] rounded-lg relative z-10' />
                                          <img src={_ele?.gifFile} alt="/" className='mr-0 h-[55px] rounded-lg absolute top-0' />
                                        </div> :
                                        <img src={_ele?.preview || `../../../assets/images/no-image.png`} alt="/" className='mr-0 h-[55px] rounded-lg relative z-10' />
                                    }
                                  </Zoom>
                                </td>
                                <td className={classes}>
                                  <div className="flex items-center">
                                    <span className={`font-normal text-[17px] ${!_ele?.title && `text-gray-500`}`}>{_ele?.title || `None`}</span>
                                  </div>
                                </td>

                                <td className={classes}>
                                  <span className={`font-normal text-[17px] ${!_ele?.downloadCount && `text-gray-500`}`}>{_ele?.downloadCount}</span>
                                </td>
                                <td className={classes}>
                                  <span className={`font-normal text-[17px] ${!_ele?.like?.length && `text-gray-500`}`}>{_ele?.like?.length || 0}</span>
                                </td>
                                <td className={classes}>
                                  <span className={`font-normal text-[17px] ${!_ele?.dislike?.length && `text-gray-500`}`}>{_ele?.dislike?.length || 0}</span>
                                </td>
                                <td className={classes}>
                                  {
                                    _ele?.themeStatus === "approved" ? <span className={`font-normal text-[12px] p-2 rounded-2xl text-[#fff] bg-[#61CB6C] ${!_ele.priority && `text-[#1E1627]`}`}>{_ele?.themeStatus || `None`}</span> :
                                      _ele?.themeStatus === "reject" ? <><span className={`font-normal text-[12px] p-2 rounded-2xl text-[#fff] bg-[#DD2B2B] ${!_ele.priority && `text-[#1E1627]`}`}>{_ele?.themeStatus || `None`}</span> </> :
                                        _ele?.themeStatus === "pending" ? <><span className={`font-normal text-[12px] p-2 rounded-2xl text-[#fff] bg-[#FD9E10] ${!_ele.priority && `text-[#1E1627]`}`}>{_ele?.themeStatus || `None`}</span> </> : null
                                  }
                                </td>

                                <td className={classes}>
                                  <div className="flex items-center gap-2">
                                    {
                                      _ele?.themeStatus === "approved" ? <>
                                        <CommunityBtn pendingBtnUser={pagePermissions?.CU} onClick={() => { setDeleteUpdateYes("update-approvedTheme"); setDeleteUpdateItem({ id: _ele._id, themeStatus: 'pending' }); setConfirmationModalShow(!confirmationModalShow) }} />
                                        <CommunityBtn rejectBtn={true} onClick={() => { setFormData(_ele); handleOpenReason(); }} />
                                      </> : ""
                                    }
                                    {
                                      _ele?.themeStatus === "reject" ? <>
                                        <CommunityBtn pendingBtnUser={pagePermissions?.CU} onClick={() => { setDeleteUpdateYes("update-approvedTheme"); setDeleteUpdateItem({ id: _ele._id, themeStatus: 'pending' }); setConfirmationModalShow(!confirmationModalShow) }} />
                                        <CommunityBtn approvedBtn={pagePermissions?.CU} onClick={() => { setDeleteUpdateYes("update-approvedTheme"); setDeleteUpdateItem({ id: _ele._id, themeStatus: 'approved' }); setConfirmationModalShow(!confirmationModalShow) }} />
                                      </> : ""
                                    }
                                    {
                                      _ele?.themeStatus === "pending" ? <>
                                        <CommunityBtn approvedBtn={pagePermissions?.CU} onClick={() => { setDeleteUpdateYes("update-approvedTheme"); setDeleteUpdateItem({ id: _ele._id, themeStatus: 'approved' }); setConfirmationModalShow(!confirmationModalShow) }} />
                                        <CommunityBtn rejectBtn={true} onClick={() => { setFormData(_ele); handleOpenReason(); }} />
                                      </> : ""
                                    }
                                    <IconButton className='bg-[#EAEDF3] text-[#F62929] text-[21px] shadow-none hover:shadow-none' disabled={!pagePermissions?.D} onClick={() => { setDeleteUpdateYes("delete-approvedTheme"); setDeleteUpdateItem({ id: _ele._id }); setConfirmationModalShow(!confirmationModalShow) }}>
                                      <RiDeleteBinLine />
                                    </IconButton>
                                  </div>
                                </td>
                              </tr>
                            );
                          })
                    }
                  </tbody>
                </>
                :
                <>
                  <THead simpleFields={simpleFields} dropdownFields={false} />
                  <tbody>
                    {
                      isLoading ?
                        <tr>
                          <td colSpan={totalFields} className='py-12'>
                            <div className="flex justify-center items-center">
                              <Spinner />
                            </div>
                          </td>
                        </tr>
                        : currentPageData?.length === 0 ?
                          <tr>
                            <td colSpan={totalFields} className='py-12 bg-gray-200'>
                              <div className="flex justify-center items-center">
                                No Data Found
                              </div>
                            </td>
                          </tr>
                          : currentPageData?.map((_ele, i) => {
                            const isLast = i === currentPageData?.length - 1;
                            const classes = `text-[#1E1627] ${isLast ? "p-4" : "p-[14.5px]"}`;

                            return (
                              <tr key={i} className='border-b border-blue-gray-50'>
                                <td className={classes}>
                                  <Checkbox
                                    className='w-[18px] h-[18px] border-[1.5px] rounded-[3.6px] border-[#1E3354] checked:bg-[#0047FF] checked:border-transparent'
                                    id={`slider-${_ele._id}`}
                                    value={`${_ele._id}`}
                                    checked={checkedValues.includes(`${_ele._id}`)}
                                    onChange={() => handleCheckboxChange(`${_ele._id}`)}
                                  />
                                </td>
                                <td className={`${classes}`}>
                                  <div className="flex items-center gap-2">
                                    <Avatar
                                      variant="circular"
                                      alt="/"
                                      className="cursor-pointer w-9 lg:w-[53px] h-9 lg:h-[53px]"
                                      src={_ele?.profileImg ? _ele.profileImg : "https://images.unsplash.com/photo-1633332755192-727a05c4013d?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1480&q=80"}
                                    />
                                    <span onClick={() => setCurrentUserId(_ele._id)} className={`font-normal text-[17px] text-[#0047FF] cursor-pointer  ${!_ele.name && `text-gray-500`}`}>{_ele.name || `None`}</span>
                                  </div>
                                </td>
                                <td className={classes}>
                                  <div className="flex items-center">
                                    <span className={`font-normal text-[17px] ${!_ele.email && `text-gray-500`}`}>{_ele.email || `None`}</span>
                                  </div>
                                </td>
                                <td className={classes}>
                                  <span className={`font-normal text-[17px] ${!_ele.Gender && `text-gray-500`}`}>{_ele.Gender || `None`}</span>
                                </td>
                                <td className={classes}>
                                  <span className={`font-normal text-[17px] ${!_ele?.userTheme?.length && `text-gray-500`}`}>{_ele?.userTheme?.length || `0`}</span>
                                </td>
                                <td className={classes}>
                                  <span className={`font-normal text-[17px] ${!_ele?.totalDownload && `text-gray-500`}`}>{_ele?.totalDownload || `0`}</span>
                                </td>
                                <td className={classes}>
                                  <span className={`font-normal text-[17px] ${!_ele.totalLike && `text-gray-500`}`}>{_ele.totalLike || `0`}</span>
                                </td>
                                <td className={classes}>
                                  <span className={`font-normal text-[17px] ${!_ele.totalDisLike && `text-gray-500`}`}>{_ele.totalDisLike || `0`}</span>
                                </td>
                                <td className={classes}>
                                  <div className="flex items-center gap-2">
                                    <IconButton className='bg-[#EAEDF3] text-[#F62929] text-[21px] shadow-none hover:shadow-none' onClick={() => { setDeleteUpdateYes("delete-communityUser"); setDeleteUpdateItem({ id: _ele._id }); setConfirmationModalShow(!confirmationModalShow) }}>
                                      <RiDeleteBinLine />
                                    </IconButton>
                                  </div>
                                </td>
                              </tr>
                            );
                          })
                    }
                  </tbody>
                </>
            }
          </table>
        </CardBody>
      </Card>
      <Pagination currentPage={currentUserId ? currentThemePage : currentPage} totalPage={currentUserId ? totalThemePages : totalPage} getData={currentUserId ? handlePageChange : getThemeCategoriesData} />
      <ReasonListModel />
    </>
  )
}

Users.defaultProps = {
  title: "My Photo Keyboard | Users"
};
export default Users
