import React, { useContext, useEffect, useState } from 'react'

import { Card, CardBody, IconButton, Checkbox, Spinner, Tooltip } from "@material-tailwind/react";

import { BiEdit } from 'react-icons/bi';
import { IoClose } from 'react-icons/io5';

import { Dropdowns, Pagination, SearchAndAddBtn, Switch, THead } from '../../Components';
import { NewContext } from '../../Context/NewContext';
import { get_textart_categories_data, get_textart_data } from '../../Controller/actions';
import TextArtModal from '../../Components/Modals/TextArtModal';
import { RiDeleteBinLine } from 'react-icons/ri';
import { InformationCircleIcon } from '@heroicons/react/24/outline';
import { MdOutlineCategory } from 'react-icons/md';

const FilterDropdowns = ({ dropData }) => {
  return (
    <div className='flex gap-4'>
      <Dropdowns title={`Categories`} name={"category"} Icon={MdOutlineCategory} dropData={dropData} />
    </div>
  )
}

export const TextArt = ({ title }) => {
  const { permissions, tokenType, currentModalKey, setDeleteUpdateTag, setDeleteUpdateItem, setAddEditDeleteTag, setFormData, confirmationModalShow, setConfirmationModalShow, handleSwitchUpdate, deleteUpdateTag, isLoading, checkedValues, handleCheckboxChange, currentPage, totalPage, perPage, search, setDeleteUpdateYes, handleSwitch, handleCopyData, setIsLoading, setCurrentPage, setPerPage, setTotalPage, currentPageData, setCurrentPageData, filterDropdown, handleOpen } = useContext(NewContext);
  const simpleFields = [
    { name: "Text Art", sortable: true, field: 'textmoji' },
    { name: "Category", },
    { name: "Country", },
    // { name: "Category", sortable: true, field: 'categoryName' },
  ];
  const [pagePermissions, setPagePermissions] = useState({
    CU: true,
    R: true,
    D: true
  });
  const dropdownFields = [{ name: "Active", }];
  const totalFields = simpleFields.length + dropdownFields.length + 2;
  const [category, setCategory] = useState([]);
  const getCategoryData = async () => {
    const categoryData = await get_textart_categories_data(1, "", "", { isActive: true });
    categoryData.data.map(_ele => setCategory(_prev => !category.includes(_prev) && [..._prev, { id: _ele._id, label: _ele.name }]))
  }
  useEffect(() => {
    getCategoryData();
  }, [currentModalKey])
  const getFontListData = async (_currentPage = 1) => {
    setIsLoading(true);
    try {
      const getFontList = await get_textart_data(_currentPage, perPage, search, filterDropdown);
      if (getFontList) {
        const { status, data, currentPage, perPage, totalPage } = getFontList;
        if (status) {
          setCurrentPageData(data);
          setCurrentPage(currentPage);
          setPerPage(perPage);
          setTotalPage(totalPage || 1);
        } else {
          setCurrentPageData([]);
        }
      }
    } catch (error) {
      console.error(error);
    }
    setIsLoading(false);
  }
  useEffect(() => {
    getFontListData(currentPage);
    setDeleteUpdateTag("")
  }, [deleteUpdateTag, search, currentPage, perPage, filterDropdown, pagePermissions]);

  useEffect(() => {
    if (tokenType === 'ldap') {
      const update = permissions?.subPermissions?.find((route) => route.name === 'Text Art');
      setPagePermissions({ ...pagePermissions, CU: update?.CU, R: update?.R, D: update?.D })
    }
  }, [tokenType, permissions])

  useEffect(() => {
  }, [pagePermissions])
  useEffect(() => {
    document.title = title;
  }, [title])
  return (
    <>
      <SearchAndAddBtn title={`Text Art`} addBtn={pagePermissions?.CU} FilterDropdowns={<FilterDropdowns dropData={category} />} isSearch={false} deleteUpdateTag='all-delete-textart' />
      <Card className="overflow-hidden w-full rounded-md shadow-none">
        <CardBody className="overflow-x-auto w-100 py-2.5 px-[5px] card-body-scrollbar">
          <table className="w-full min-w-max table-auto text-left ">
            <THead simpleFields={simpleFields} dropdownFields={dropdownFields} />
            <tbody>
              {
                isLoading ?
                  <tr>
                    <td colSpan={totalFields} className='py-12'>
                      <div className="flex justify-center items-center">
                        <Spinner />
                      </div>
                    </td>
                  </tr>
                  : currentPageData?.length === 0 ?
                    <tr>
                      <td colSpan={totalFields} className='py-12 bg-gray-200'>
                        <div className="flex justify-center items-center">
                          No Data Found
                        </div>
                      </td>
                    </tr>
                    : currentPageData?.map((_ele, i) => {
                      const isLast = i === currentPageData?.length - 1;
                      const classes = `text-[#1E1627] ${isLast ? "p-4" : "p-[14.5px]"}`;

                      return (
                        <tr key={i} className='border-b border-blue-gray-50'>
                          <td className={classes}>
                            <Checkbox
                              className='w-[18px] h-[18px] border-[1.5px] rounded-[3.6px] border-[#1E3354] checked:bg-[#0047FF] checked:border-transparent'
                              id={`slider-${_ele._id}`}
                              value={`${_ele._id}`}
                              disabled={!pagePermissions?.CU}
                              checked={checkedValues.includes(`${_ele._id}`)}
                              onChange={() => handleCheckboxChange(`${_ele._id}`)}
                            />
                          </td>
                          <td className={classes}>
                            <div className="flex items-center">
                              <span className={`font-normal !w-40 truncate text-[17px] ${!_ele.textmoji && `text-gray-500`}`}>
                                {_ele.textmoji || `None`}
                              </span>
                              {
                                _ele.textmoji &&
                                <Tooltip placement="right-start" content={
                                  <div className="p-2 w-[300px] h-48 2xl:w-[400px] overflow-y-auto">
                                    {_ele.textmoji}
                                  </div>
                                }>
                                  <InformationCircleIcon strokeWidth={2} className="text-[#1E1627] w-5 h-5 cursor-pointer" />
                                </Tooltip>
                              }
                            </div>
                          </td>
                          <td className={classes}>
                            <div className="flex items-center">
                              <span className={`font-normal text-[17px] ${!_ele.categoryName && `text-gray-500`}`}>{_ele.categoryName || `None`}</span>
                            </div>
                          </td>
                          <td className={`${classes}`}>
                            <div className="flex items-center">
                              <p className={`font-normal !w-40 truncate p-0 text-[17px] ${!_ele.country?.length && `text-gray-500`}`}>
                                {_ele.country && _ele.country?.length !== 0 && _ele.country?.length > 1 ? _ele.country.map(_ele => _ele.name).slice(0, -1).join(", ") + ", " + _ele.country[_ele.country.length - 1].name : _ele.country && _ele.country?.length !== 0 && _ele.country[_ele.country.length - 1].name || `None`}
                              </p>
                              {
                                _ele.country && _ele.country.length > 1 &&
                                <Tooltip placement="right-start" content={
                                  <div className="p-2 w-[300px] h-48 2xl:w-[400px] overflow-y-auto">
                                    {_ele.country && _ele.country?.length !== 0 && _ele.country?.length > 1 ? _ele.country.map(_ele => _ele.name).slice(0, -1).join(", ") + ", " + _ele.country[_ele.country.length - 1].name : _ele.country && _ele.country?.length !== 0 && _ele.country[_ele.country.length - 1].name || `None`}
                                  </div>
                                }>
                                  <InformationCircleIcon strokeWidth={2} className="text-[#1E1627] w-5 h-5 cursor-pointer" />
                                </Tooltip>
                              }
                            </div>
                          </td>
                          {
                            ["isActive"].map((_is_ele, i) => (
                              <td className={classes} key={i}>
                                <div className="ml-1">
                                  <Switch disabled={!pagePermissions?.CU} id={_ele._id} name={_is_ele} onChange={(e) => { setDeleteUpdateYes("update-textart"); handleSwitchUpdate(e); }} checked={_ele[_is_ele]} />
                                </div>
                              </td>
                            ))
                          }
                          <td className={classes}>
                            <div className="flex items-center gap-2">
                              <IconButton className='bg-[#EAEDF3] text-[#0047FF] text-[21px] shadow-none hover:shadow-none' disabled={!pagePermissions?.CU} onClick={() => { setDeleteUpdateYes("update-textart"); setAddEditDeleteTag(`update`); setFormData(_ele); handleOpen() }}>
                                <BiEdit />
                              </IconButton>
                              <IconButton className='bg-[#EAEDF3] text-[#F62929] text-[21px] shadow-none hover:shadow-none' disabled={!pagePermissions?.D} onClick={() => { setDeleteUpdateYes("delete-textart"); setDeleteUpdateItem({ id: _ele._id, name: _ele.name }); setConfirmationModalShow(!confirmationModalShow) }}>
                                <RiDeleteBinLine />
                              </IconButton>
                            </div>
                          </td>
                        </tr>
                      );
                    })
              }
            </tbody>
          </table>
        </CardBody>
      </Card>
      {
        totalPage > 1 &&
        <Pagination currentPage={currentPage} totalPage={totalPage} getData={getFontListData} />
      }
      <TextArtModal />
    </>
  )
}

TextArt.defaultProps = {
  title: "My Photo Keyboard | TextArt"
};
export default TextArt
