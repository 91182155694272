import React from 'react';
import Highcharts from "highcharts/highcharts.js";
import highchartsMore from "highcharts/highcharts-more.js";
import variablepie from "highcharts/modules/variable-pie.js";
import HighchartsReact from "highcharts-react-official";

highchartsMore(Highcharts);
variablepie(Highcharts);

const NumberScaleChart = ({ question }) => {

    const options = {
        chart: {
            type: 'variablepie'
        },
        title: {
            text: ''
        },
        credits: {
            enabled: false
        },
        subtitle: {
            text: 'Type: Number Scale'
        },
        plotOptions: {
            variablepie: {
                borderColor: '#ffffff',
                dataLabels: {
                    enabled: true,
                    format: '{point.name}: {point.y}',
                    style: {
                        color: 'black'
                    }
                }
            }
        },
        series: [{
            minPointSize: 10,
            innerSize: '30%',
            zMin: 0,
            name: 'Count',
            data: question.answer.map((option, index) => ({
                name: option.text,
                y: option.count,
                z: option.count,
            }))
        }],
        tooltip: {
            formatter: function () {
                return '<b>Count:</b> ' + this.y;
            }
        },
        lang: {
            noData: 'There are currently no survey Result'
        },
        noData: {
            style: {
                fontWeight: 'bold',
                fontSize: '15px',
                color: '#303030'
            }
        },
        responsive: {
            rules: [{
                condition: {
                    maxWidth: 500
                },
                chartOptions: {
                    legend: {
                        align: 'center',
                        verticalAlign: 'bottom',
                        layout: 'horizontal'
                    }
                }
            }]
        }
    };

    return <HighchartsReact highcharts={Highcharts} options={options} />;
};

export default NumberScaleChart;
