import React, { useContext, useEffect, useState } from "react";
import {
  Card,
  CardBody,
  IconButton,
  Spinner,
} from "@material-tailwind/react";
import { BiEdit } from "react-icons/bi";
import { SearchAndAddBtn, Switch, THead } from "../../Components";
import { NewContext } from "../../Context/NewContext";
import { RiDeleteBinLine } from "react-icons/ri";
import moment from "moment";
import SurveyModel from "../../Components/Modals/SurveyModel";
import { get_survey } from "../../Controller/actions";
import { Button } from "@material-tailwind/react";
import { IoCopyOutline, IoEyeOutline } from "react-icons/io5";
import ViewSurveyModel from "../../Components/Modals/ViewSurveyModel";
import { toast } from "react-toastify";
import Tooltip from '@mui/material/Tooltip';


const Survey = ({ title }) => {
  const {
    setDeleteUpdateTag,
    deleteUpdateYes,
    confirmationModalShow,
    setConfirmationModalShow,
    setFormData,
    setAddEditDeleteTag,
    setDeleteUpdateItem,
    isLoading,
    deleteUpdateTag,
    setDeleteUpdateYes,
    handleSwitchUpdate,
    setIsLoading,
    filterDropdown,
    handleOpen,
    currentPageData,
    setCurrentPageData,
    showSurveyModel,
    setShowSurveyModel,
    permissions,
    tokenType
  } = useContext(NewContext);


  const websiteURL = `${process.env.REACT_APP_WEBSITE_URL_SURVEY}`

  const copySurveyUrlToClipboard = (surveyId) => {
    const surveyUrl = `${websiteURL}/survey/${surveyId}/`;

    navigator.clipboard.writeText(surveyUrl)
      .then(() => {
        toast.success('item copied to clipboard')
      })
      .catch((error) => {
        console.error("Failed to copy survey URL to clipboard: ", error);
      });
  };

  const simpleFields = [
    { name: "Survey", field: "title" },
    { name: "Created At", field: "createAt" },
  ];

  const dropdownFields = [{ name: "Active" }];
  const totalFields = simpleFields?.length + dropdownFields.length + 2;

  const getSurveyData = async () => {
    setIsLoading(true);
    try {
      const getSurvey = await get_survey(filterDropdown);
      if (getSurvey) {
        const { status, data } = getSurvey;
        if (status) {
          setCurrentPageData(data);
        } else {
          setCurrentPageData([]);
        }
        setIsLoading(false);
      } else {
        setCurrentPageData([]);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const [pagePermissions, setPagePermissions] = useState({
    CU: true,
    R: true,
    D: true
  });

  useEffect(() => {
    if (tokenType === 'ldap') {
      const update = permissions?.subPermissions?.find((route) => route.name === 'Survey');
      setPagePermissions({ ...pagePermissions, CU: update?.CU, R: update?.R, D: update?.D })
    }
  }, [tokenType, permissions])

  useEffect(() => {
    getSurveyData();
    setDeleteUpdateTag("");
  }, [
    deleteUpdateTag,
    deleteUpdateYes,
    showSurveyModel,
    filterDropdown
  ]);

  useEffect(() => {
  }, [pagePermissions])

  useEffect(() => {
    document.title = title;
  }, [title]);

  const renderSurveyRows = () => {
    return currentPageData.map((_ele, index) => (
      <>
        <tr
          className={`border-b border-blue-gray-50 dragging w-full`}
        >
          <td
            className={`text-[#1E1627] ${index === currentPageData.length - 1
              ? "p-4"
              : "p-[14.5px]"
              } max-w-[200px] overflow-hidden
                 break-words`}
          >
            <div className="flex items-center">
              <span className={`font-normal text-[17px]`}>
                {_ele.name || `None`}
              </span>
            </div>
          </td>
          <td
            className={`text-[#1E1627] ${index === currentPageData.length - 1
              ? "p-4"
              : "p-[14.5px]"
              }`}
          >
            <span
              className={`font-normal text-[17px] ${!_ele?.createdAt && `text-gray-500`
                }`}
            >
              {moment(_ele?.createdAt).format(
                "DD/MM/YYYY, hh:mm A"
              )}
            </span>
          </td>
          {["isActive"]?.map((_is_ele, i) => (
            <td
              className={`text-[#1E1627] ${index === currentPageData.length - 1
                ? "p-4"
                : "p-[14.5px]"
                }`}
              key={i}
            >
              <Switch
                id={_ele?._id}
                name={_is_ele}
                onChange={(e) => {
                  setDeleteUpdateYes("update-survey");
                  handleSwitchUpdate(e);
                }}
                checked={_ele[_is_ele]}
                disabled={!pagePermissions?.CU}
              />
            </td>
          ))}
          <td
            className={`text-[#1E1627] ${index === currentPageData.length - 1
              ? "p-4"
              : "p-[14.5px]"
              }`}
          >
            <div className="flex items-center gap-2">
              <IconButton
                disabled={!pagePermissions?.CU}
                className="bg-[#EAEDF3] text-[#0047FF] text-[21px] shadow-none hover:shadow-none"
                onClick={() => {
                  setDeleteUpdateYes("update-survey");
                  setAddEditDeleteTag(`update`);
                  setFormData(_ele);
                  setShowSurveyModel(true)
                }}
              >
                <BiEdit />
              </IconButton>
              <IconButton
                className="bg-[#EAEDF3] text-[#F62929] text-[21px] shadow-none hover:shadow-none"
                disabled={!pagePermissions?.D}
                onClick={() => {
                  setDeleteUpdateYes("delete-survey");
                  setDeleteUpdateItem({
                    id: _ele._id,
                  });
                  setConfirmationModalShow(
                    !confirmationModalShow
                  );
                }}
              >
                <RiDeleteBinLine />
              </IconButton>
              <IconButton className='bg-[#EAEDF3] text-[#0047FF] text-[21px] shadow-none hover:shadow-none'
                onClick={() => { setAddEditDeleteTag(`view`); setFormData(_ele); handleOpen() }}>
                <IoEyeOutline />
              </IconButton>
              <Tooltip title="Copy survey webpage URL">
                <IconButton className='bg-[#EAEDF3] text-[#0047FF] text-[21px] shadow-none hover:shadow-none'
                  onClick={() => { copySurveyUrlToClipboard(_ele.surveyId) }}>
                  <IoCopyOutline />
                </IconButton>
              </Tooltip>
            </div>
          </td>
        </tr>
      </>
    ));
  };

  const CustomButton = () => {
    return (
      <Button
        type="button"
        onClick={() => { setShowSurveyModel(true) }}
        className='mb-2 lg:ml-2.5 lg:px-4 2xl:px-5 py-2.5 flex items-center bg-[#0047FF] capitalize text-base leading-none font-medium  shadow-none hover:shadow-none'
      >
        Add Survey
      </Button>
    )
  }

  return (
    <>
      <SearchAndAddBtn title={`Survey`} CustomButton={pagePermissions?.CU && <CustomButton />} isSearch={false} perPageCount={null} FilterDropdowns={false} countryFilter={false} addBtn={false} deleteUpdateTag='all-survey' />
      <div className="p-2.5 bg-[#FFFFFF]">
        <div className="grid grid-cols-12 gap-4 ">
          {showSurveyModel &&
            <div className="col-span-3">
              <div className="p-2 bg-gray-100 rounded-lg">
                {showSurveyModel && <SurveyModel setShowSurveyModel={setShowSurveyModel} />}
              </div>
            </div>
          }
          <div className={showSurveyModel ? `col-span-9` : `col-span-12`}>
            <Card
              className={`overflow-hidden w-full rounded-md shadow-none col-span-12 `}
            >
              <CardBody className="w-100 py-2.5 px-[5px]">
                <table className="w-full min-w-max table-auto text-left ">
                  <THead
                    isSelectAll={false}
                    simpleFields={simpleFields}
                    dropdownFields={dropdownFields}
                  />
                  <tbody>
                    {isLoading ? (
                      <tr>
                        <td colSpan={totalFields} className="py-12">
                          <div className="flex justify-center items-center">
                            <Spinner />
                          </div>
                        </td>
                      </tr>
                    ) : currentPageData?.length === 0 ? (
                      <tr>
                        <td
                          colSpan={totalFields}
                          className="py-12 bg-gray-200 h-screen"
                        >
                          <div className="flex justify-center items-center rounded-lg">
                            No Data Found
                          </div>
                        </td>
                      </tr>
                    ) : (
                      renderSurveyRows()
                    )}
                  </tbody>
                </table>
              </CardBody>
            </Card>
          </div>
          <ViewSurveyModel />
        </div>
      </div>
    </>
  )
}

Survey.defaultProps = {
  title: "My Photo Keyboard | Survey"
};

export default Survey;
