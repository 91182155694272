import React, { useContext, useEffect, useState } from 'react'
import { Typography, Button, Input, Select, Option, Spinner } from "@material-tailwind/react";
import { toast } from 'react-toastify';

import { get_background_categories_data, update_background, add_background, get_country_data, get_region_data } from '../../Controller/actions';
import { NewContext } from '../../Context/NewContext';

import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Switch from '../Switch/Switch';
import { MultiSelect } from 'react-multi-select-component';
import FileInput from '../FileInput/FileInput';
import BackgroundChildModal from './BackgroundChildModal';

export const BackgroundModal = () => {
    const { homeActivityRef, isModalLoading, currentHomeModalKey, setFormData, setIsModalLoading, setCurrentHomeModalKey, childOpen, handleChildOpen, handleSelectMultiCountry, handleSelectMultiRegion, multiCountry, multiRegion, updateStateAryData, capitalalizeFirstletter, setMultiCountry, setMultiRegion, setDeleteUpdateTag, deleteUpdateYes, handleSingleSelect, handleSwitch, handleClose, handleChange, formData, open, addEditDeleteTag } = useContext(NewContext);

    const [category, setCategory] = useState([]);
    const [country, setCountry] = useState([]);
    const [region, setRegion] = useState([]);
    const [multiCategories, setMultiCategories] = useState([]);
    const [isOpenCategoryDropDown, setIsOpenCategoryDropDown] = useState(false);
    const [isOpenCountryDropDown, setIsOpenCountryDropDown] = useState(false);

    const getCategoryAndTagFunc = async () => {
        setIsModalLoading(true);
        try {
            const categoryData = await get_background_categories_data(1, "", "", { isActive: true });
            const countryData = await get_country_data();
            const regionData = await get_region_data();
            if (categoryData.status && countryData.status && regionData.status) {
                // setCategory(categoryData.data);
                categoryData.data.map(_ele => {
                    if (category.find(_e => _e._id === _ele._id)) {
                        if (category.find(_e => _e.name !== _ele.name)) {
                            let filterData = category.map(_t => ({ ..._t, name: 'category' }))
                            setCategory(filterData);
                        }
                    } else {
                        _ele.name && setCategory(_prev => [..._prev, { _id: _ele._id, value: _ele._id, label: _ele.name, name: 'category' }])
                    }
                })
                countryData.data.map(_ele => !country.find(_e => _e._id === _ele._id) && setCountry(_prev => [..._prev, { _id: _ele._id, value: _ele.code, label: capitalalizeFirstletter(_ele.name), name: 'country' }]))
                // regionData.data.map(_ele => !region.find(_e => _e._id === _ele._id) && setRegion(_prev => [..._prev, { _id: _ele._id, value: _ele.name.toLowerCase(), label: capitalalizeFirstletter(_ele.name), name: 'region' }]))
                if (addEditDeleteTag === `update`) {
                    let oldCategoryData = updateStateAryData([formData['cat_name']], categoryData.data, 'category');
                    let oldCountryData = updateStateAryData(formData?.country, countryData.data, 'country');
                    setMultiCategories(oldCategoryData);
                    setMultiCountry(oldCountryData);
                    // let oldRegionData = updateStateAryData(formData?.region, regionData.data, 'region');
                    // setMultiRegion(oldRegionData);
                    if (oldCategoryData.length == 1) {
                        let selectedSliderTag = category.filter(_ele => oldCategoryData[0]._id === _ele._id);
                        let nonSelectedSliderTag = category.filter(_ele => oldCategoryData[0]._id !== _ele._id).map(_ele => ({ ..._ele, disabled: true }))
                        setCategory(selectedSliderTag.concat(nonSelectedSliderTag));
                    }
                }
            } else {
                toast.error(categoryData.message || countryData.message || regionData.message);
            }
        } catch (error) {
            console.error(error);
        };
        setIsModalLoading(false);
    };
    useEffect(() => {
        getCategoryAndTagFunc();
    }, [addEditDeleteTag, currentHomeModalKey]);
    const handleNewCategoryField = async (_value) => { setCurrentHomeModalKey('addCategory'); handleChildOpen(); };
    const handleSelectMultiCategories = (e) => {
        if (e[0] && e.length > 0) {
            e.map(_ele => {
                let multiSelectAry = [].slice.call(e).map(item => item && item.value);
                setMultiCategories([{ ...e[0], disabled: false }]);
                setFormData({ ...formData, [_ele?.name]: multiSelectAry[0] });
                if (e[0] && e.length === 1) {
                    let selectedSliderTag = category.filter(_ele => e[0]._id === _ele._id);
                    let nonSelectedSliderTag = category.filter(_ele => e[0]._id !== _ele._id).map(_ele => ({ ..._ele, disabled: true }))
                    setCategory(selectedSliderTag.concat(nonSelectedSliderTag));
                }
            })
        } else {
            let categoryData = category.map(_ele => ({ ..._ele, disabled: false }));
            setCategory(categoryData);
            setMultiCategories([]);
            setFormData({ ...formData, category: [] })
        }
        // homeActivityRef.current.focus();
    }
    const handleSubmit = async (e) => {
        e.preventDefault();
        // const form = e.currentTarget;
        // if (form.checkValidity() === false) {
        //     e.preventDefault();
        //     e.stopPropagation();
        //     setValidated(true);
        //     return;
        // }
        let createUpdateBackgrounds;
        setIsModalLoading(true);
        if (addEditDeleteTag === `update`) {
            let countryIdAry = multiCountry.map(_ele => _ele._id)
            createUpdateBackgrounds = await update_background({ ...formData, country: countryIdAry || [], region: formData?.region || [], category: formData?.category._id || [] }, formData?._id)
        } else {
            createUpdateBackgrounds = await add_background(formData)
        }
        if (createUpdateBackgrounds) {
            const { message, status } = createUpdateBackgrounds;
            if (status) {
                toast.success(message);
                handleClose();
                setDeleteUpdateTag(deleteUpdateYes);
                // setValidated(false);
                setMultiCategories([]);
                setMultiCountry([]);
                setMultiRegion([]);
            } else {
                toast.error(message);
            };
        }
        setIsModalLoading(false);
    }
    return (
        <>
            <Dialog
                open={open}
                onClose={() => { handleClose(); setCategory([]); setMultiCategories([]); }}
                aria-labelledby="responsive-dialog-title"
            >
                <DialogTitle id="responsive-dialog-title" className='p-0 mb-[30px] text-[#1E1627]'>
                    {
                        addEditDeleteTag === 'view' ? 'Details View' : addEditDeleteTag === 'update' ? `Edit Background` : `Add Background`
                    }
                </DialogTitle>
                <DialogContent>
                    <div className="grid grid-cols-1 lg:grid-cols-2 gap-5">
                        {/* <div className="mr-[26px]">
                            <Typography className="text-sm md:text-lg text-[#1E1627] font-medium">Preview:</Typography>
                            <div className="">
                                {
                                    imgObj?.previewSlider || formData?.preview_img ?
                                        <img src={addEditDeleteTag === 'update' ? formData?.preview_img : imgObj?.previewSlider} alt={formData?.name} className='w-[92px] h-[95px]' />
                                        : <img src="../../assets/images/no-image.png" alt="No Image" className='w-[92px] h-[95px]' />
                                }
                            </div>
                        </div>
                        <div className="">
                            <Typography className="text-sm md:text-lg text-[#1E1627] font-medium">Background Image:</Typography>
                            <div className="">
                                {
                                    imgObj?.previewSlider || formData?.background_img ?
                                        <img src={addEditDeleteTag === 'update' ? formData?.background_img : imgObj?.previewSlider} alt={formData?.name} className='w-[140px] h-[95px]' />
                                        : <img src="../../assets/images/no-image.png" alt="No Image" className='w-[140px] h-[95px]' />
                                }
                            </div>
                        </div> */}
                        <div className='lg:col-span-1'>
                            <Typography className="text-lg text-[#1E1627] font-medium">Small Preview (90 x 90):</Typography>
                            <div className='w-auto'>
                                <FileInput name={`backgroundImgDiy`} imgClass={`h-full`} previewFields={'backgroundImg'} accept={'image/*'} width={90} height={90} acceptedExtensions={/\.(jpg|png|webp)$/} />
                            </div>
                        </div>
                        <div className='lg:col-span-1'>
                            <Typography className="text-lg text-[#1E1627] font-medium">Big Preview (720 x 491):</Typography>
                            <div className='w-auto'>
                                <FileInput name={`backgroundPreviewDiy`} imgClass={`h-full`} previewFields={'preview'} accept={'image/*'} width={720} height={491} acceptedExtensions={/\.(jpg|png|webp)$/} />
                            </div>
                        </div>
                    </div>
                    <div>
                        {
                            addEditDeleteTag !== 'view' ?
                                <form onSubmit={handleSubmit}>
                                    <div className="mt-5 grid grid-cols-6 lg:grid-cols-6 gap-5 add-edit-form">
                                        <div className="col-span-6 sm:col-span-3">
                                            <label htmlFor="name" className="block text-sm font-medium leading-6 text-gray-900">Name:</label>
                                            <div className="mt-2">
                                                <Input id='name' placeholder='Enter Name' name='name' onChange={handleChange} value={formData?.name} />
                                            </div>
                                        </div>
                                        <div className="col-span-6 sm:col-span-3">
                                            <label htmlFor="category" className="block text-sm font-medium leading-6 text-gray-900">Category:</label>
                                            <div className="mt-1.5" onFocus={() => { setIsOpenCategoryDropDown(true) }} onBlur={() => { setIsOpenCategoryDropDown(false) }}>
                                                {/* <Select name='category' onChange={(e) => handleSingleSelect(`category`, e)} value={formData?.category?._id}>
                                                    {category.map((_ele, i) => <Option key={i} value={_ele._id}>{_ele.name}</Option>)}
                                                </Select> */}
                                                {
                                                    !childOpen && category && category.length > 0 &&
                                                    <MultiSelect
                                                        options={category}
                                                        value={multiCategories}
                                                        onChange={handleSelectMultiCategories}
                                                        hasSelectAll={false}
                                                        // ClearSelectedIcon={null}
                                                        isCreatable={true}
                                                        onCreateOption={handleNewCategoryField}
                                                        isOpen={isOpenCategoryDropDown}
                                                    />
                                                }
                                            </div>
                                        </div>
                                        {/* <div className="col-span-6 sm:col-span-3">
                                            <label htmlFor="region" className="block text-sm font-medium leading-6 text-gray-900">Region:</label>
                                            <div className="mt-1.5">
                                                {
                                                    region && region.length > 0 &&
                                                    <MultiSelect
                                                        labelledBy='Select Region'
                                                        options={region}
                                                        value={multiRegion}
                                                        onChange={handleSelectMultiRegion}
                                                        hasSelectAll={false}
                                                        ClearSelectedIcon={null}
                                                    />
                                                }
                                            </div>
                                        </div> */}
                                        <div className="col-span-6 sm:col-span-3">
                                            <label htmlFor="country" className="block text-sm font-medium leading-6 text-gray-900">Country:</label>
                                            <div className="mt-1.5 relative" onFocus={() => { setIsOpenCountryDropDown(true) }} onBlur={() => { setIsOpenCountryDropDown(false) }}>
                                                {
                                                    country && country.length > 0 &&
                                                    <MultiSelect
                                                        labelledBy='Select Country'
                                                        options={country}
                                                        value={multiCountry}
                                                        onChange={handleSelectMultiCountry}
                                                        hasSelectAll={false}
                                                        ClearSelectedIcon={null}
                                                        isOpen={isOpenCountryDropDown}
                                                    />
                                                }
                                                <input ref={homeActivityRef} className='absolute top-0 right-0 bottom-0 left-0 m-auto w-[95%] h-[80%] bg-red-300 -z-10' />
                                            </div>
                                        </div>
                                        <div className="col-span-2 lg:col-span-1">
                                            <label htmlFor="isPremium" className="block text-sm font-medium leading-6 text-gray-900">Premium:</label>
                                            <div className="mt-1.5 ml-2">
                                                <Switch color='green' id='isPremium' name='isPremium' onChange={handleSwitch} checked={formData?.isPremium} />
                                            </div>
                                        </div>
                                        <div className="col-span-1 lg:col-span-2">
                                            <label htmlFor="isActive" className="block text-sm font-medium leading-6 text-gray-900">Active:</label>
                                            <div className="mt-1.5 ml-2">
                                                <Switch color='green' id='isActive' name='isActive' onChange={handleSwitch} checked={formData?.isActive} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className='flex justify-end mt-10'>
                                        <Button
                                            onClick={() => { handleClose(); setCategory([]); setMultiCategories([]); }}
                                            className="mr-1 px-5 py-2.5 bg-white shadow-none text-[#1E3354] font-medium border border-[#75869F] hover:shadow-none capitalize text-base"
                                        >
                                            <span>Cancel</span>
                                        </Button>
                                        <Button
                                            disabled={isModalLoading}
                                            className="flex items-center mr-1 px-5 py-2.5 bg-[#0047FF] shadow-none text-white font-medium border border-[#0047FF] hover:shadow-none capitalize text-base"
                                            type='submit'
                                        >
                                            {isModalLoading && <Spinner className="h-4 w-4" />}
                                            <span className={isModalLoading && 'ml-4'}>{addEditDeleteTag === `add` ? `Add` : `Update`}</span>
                                        </Button>
                                    </div>
                                </form>
                                :
                                <div>
                                    <div className="mt-5 grid grid-cols-1 lg:grid-cols-6 gap-5 add-edit-form">
                                        <div className="sm:col-span-3">
                                            <label htmlFor="name" className="block text-lg font-medium leading-5 text-[#1E3354]">Name:</label>
                                            <div className="mt-2">
                                                <Typography className="text-[17px] text-[#75869F] font-normal leading-5">{formData?.name || `None`}</Typography>
                                            </div>
                                        </div>
                                        <div className="sm:col-span-3">
                                            <label htmlFor="category" className="block text-lg font-medium leading-5 text-[#1E3354]">Category:</label>
                                            <div className="mt-2">
                                                <Typography className="text-[17px] text-[#75869F] font-normal leading-5">{formData?.category?.name || `None`}</Typography>
                                            </div>
                                        </div>
                                        <div className="sm:col-span-3">
                                            <label htmlFor="street-address" className="block text-lg font-medium leading-5 text-[#1E3354]">Country:</label>
                                            <div className="mt-2">
                                                <Typography className="text-[17px] text-[#75869F] font-normal leading-5 truncate w-full">
                                                    {formData?.country && formData?.country?.length !== 0 && formData?.country?.length > 1 ? formData?.country.map(_ele => _ele.name).slice(0, -1).join(", ") + ", " + formData?.country[formData?.country.length - 1].name : formData?.country && formData?.country?.length !== 0 && formData?.country[formData?.country.length - 1].name || `None`}
                                                </Typography>
                                            </div>
                                        </div>
                                        <div className="sm:col-span-3">
                                            <label htmlFor="country" className="block text-lg font-medium leading-5 text-[#1E3354]">Region:</label>
                                            <div className="mt-2">
                                                <Typography className="text-[17px] text-[#75869F] font-normal leading-5">
                                                    {formData?.region && formData?.region?.length !== 0 && formData?.region?.length > 1 ? formData?.region.map(_ele => _ele.name).slice(0, -1).join(", ") + ", " + formData?.region[formData?.region.length - 1].name : formData?.region && formData?.region?.length !== 0 && formData?.region[formData?.region.length - 1].name || `None`}
                                                </Typography>
                                            </div>
                                        </div>
                                        <div className="sm:col-span-3">
                                            <label htmlFor="region" className="block text-lg font-medium leading-5 text-[#1E3354]">Premium:</label>
                                            <div className="mt-2">
                                                {/* <Switch color='green' id='isPremium' name='isPremium' onChange={handleSwitch} checked={formData?.isPremium} /> */}
                                                <Typography className="text-[17px] text-[#75869F] font-normal leading-5">{formData?.isPremium ? "Off" : "On"}</Typography>
                                            </div>
                                        </div>
                                        <div className="sm:col-span-3">
                                            <label htmlFor="region" className="block text-lg font-medium leading-5 text-[#1E3354]">Active:</label>
                                            <div className="mt-2">
                                                {/* <Switch color='green' id='isActive' name='isActive' onChange={handleSwitch} checked={formData?.isActive} /> */}
                                                <Typography className="text-[17px] text-[#75869F] font-normal leading-5">{formData?.isActive ? "Off" : "On"}</Typography>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='flex justify-end'>
                                        <Button
                                            onClick={() => { handleClose(); setCategory([]); setMultiCategories([]); }}
                                            className="mr-1 px-5 py-2.5 bg-white shadow-none text-[#1E3354] font-medium border border-[#75869F] hover:shadow-none capitalize text-base"
                                        >
                                            <span>Cancel</span>
                                        </Button>
                                    </div>
                                </div>
                        }
                    </div>
                </DialogContent>
            </Dialog>
            <BackgroundChildModal />
        </>
    )
}

export default BackgroundModal
