import React, { useContext, useEffect, useState } from "react";
import {
  Card,
  CardBody,
  IconButton,
  Spinner,
} from "@material-tailwind/react";
import { BiEdit } from "react-icons/bi";
import { SearchAndAddBtn, Switch, THead } from "../../Components";
import { NewContext } from "../../Context/NewContext";
import { get_faq, update_faq_priority } from "../../Controller/actions";
import { RiDeleteBinLine } from "react-icons/ri";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import FaqModel from "../../Components/Modals/FaqModel";

export const Faq = ({ title }) => {
  const navigate = useNavigate();
  const {

    setDeleteUpdateTag,
    deleteUpdateYes,
    confirmationModalShow,
    setConfirmationModalShow,
    setFormData,
    setAddEditDeleteTag,
    setDeleteUpdateItem,
    isLoading,
    deleteUpdateTag,
    search,
    setDeleteUpdateYes,
    handleSwitchUpdate,
    setIsLoading,
    filterDropdown,
    handleOpen,
    formDataBoolean,
    currentPageData,
    setCurrentPageData
  } = useContext(NewContext);


  const simpleFields = [
    { name: "Question", field: "title" },
    { name: "Created At", field: "createAt" },
  ];
  const dropdownFields = [{ name: "Active" }];
  const totalFields = simpleFields.length + dropdownFields.length + 2;

  const getFAQData = async () => {
    setIsLoading(true);
    try {
      const getFAQ = await get_faq(filterDropdown);
      if (getFAQ) {
        const { status, data } = getFAQ;
        if (status) {
          setCurrentPageData(data);
        } else {
          setCurrentPageData([]);
        }
        setIsLoading(false);
      } else {
        setCurrentPageData([]);
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    getFAQData();
    setDeleteUpdateTag("");
  }, [
    deleteUpdateTag,
    search,
    filterDropdown,
    deleteUpdateYes,
    formDataBoolean,
  ]);

  useEffect(() => {
    document.title = title;
  }, [title]);

  const handleDragEnd = async (result) => {
    if (!result.destination) {
      return;
    }
    const { source, destination } = result;
    if (source.index === destination.index) {
      return;
    }
    const newData = Array.from(currentPageData);
    const [movedItem] = newData.splice(source.index, 1);
    newData.splice(destination.index, 0, movedItem);
    const updatedFAQs = newData?.map((item, index) => ({
      _id: item?._id,
      priority: index + 1,
    }));
    setCurrentPageData(newData);
    try {
      await update_faq_priority({ updates: updatedFAQs });
    } catch (error) {
      console.error("Error updating FAQ priorities:", error);
    }
  };

  return (
    <>
      <SearchAndAddBtn title={`FAQ`} countryFilter={false} isSearch={false} perPageCount={false} />
      <div className="grid grid-cols-12">
        <Card
          className={`overflow-hidden w-full rounded-md shadow-none  col-span-12 `}
        >
          <CardBody className="w-100 py-2.5 px-[5px]">
            <table className="w-full min-w-max table-auto text-left ">
              <THead
                isSelectAll={false}
                simpleFields={simpleFields}
                dropdownFields={dropdownFields}
              />
              <DragDropContext onDragEnd={handleDragEnd}>
                <Droppable droppableId="faq-list">
                  {(provided) => (
                    <tbody ref={provided.innerRef} {...provided.droppableProps}>
                      {isLoading ? (
                        <tr>
                          <td colSpan={totalFields} className="py-12">
                            <div className="flex justify-center items-center">
                              <Spinner />
                            </div>
                          </td>
                        </tr>
                      ) : currentPageData?.length === 0 ? (
                        <tr>
                          <td
                            colSpan={totalFields}
                            className="py-12 bg-gray-200"
                          >
                            <div className="flex justify-center items-center">
                              No Data Found
                            </div>
                          </td>
                        </tr>
                      ) : (
                        currentPageData?.map((_ele, index) => (
                          <Draggable
                            key={_ele._id}
                            draggableId={_ele._id}
                            index={index}
                          >
                            {(provided, snapshot) => (
                              <tr
                                className={`border-b border-blue-gray-50 dragging w-full`}
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                                style={{
                                  ...provided.draggableProps.style,
                                  position: snapshot.isDragging ? "flex" : "",
                                }}
                              >
                                <td
                                  className={`text-[#1E1627] ${index === currentPageData.length - 1
                                    ? "p-4"
                                    : "p-[14.5px]"
                                    } max-w-[200px] overflow-hidden
                                   break-words`}
                                >
                                  <div className="flex items-center">
                                    <span className={`font-normal text-[17px]`}>
                                      {_ele?.question || `None`}
                                    </span>
                                  </div>
                                </td>
                                <td
                                  className={`text-[#1E1627] ${index === currentPageData.length - 1
                                    ? "p-4"
                                    : "p-[14.5px]"
                                    }`}
                                >
                                  <span
                                    className={`font-normal text-[17px] ${!_ele?.createdAt && `text-gray-500`
                                      }`}
                                  >
                                    {moment(_ele?.createdAt).format(
                                      "DD/MM/YYYY, hh:mm A"
                                    )}
                                  </span>
                                </td>
                                {["isActive"]?.map((_is_ele, i) => (
                                  <td
                                    className={`text-[#1E1627] ${index === currentPageData.length - 1
                                      ? "p-4"
                                      : "p-[14.5px]"
                                      }`}
                                    key={i}
                                  >
                                    <Switch
                                      id={_ele?._id}
                                      name={_is_ele}
                                      onChange={(e) => {
                                        setDeleteUpdateYes("update-FAQ");
                                        handleSwitchUpdate(e);
                                      }}
                                      checked={_ele[_is_ele]}
                                    />
                                  </td>
                                ))}
                                <td
                                  className={`text-[#1E1627] ${index === currentPageData.length - 1
                                    ? "p-4"
                                    : "p-[14.5px]"
                                    }`}
                                >
                                  <div className="flex items-center gap-2">
                                    <IconButton
                                      className="bg-[#EAEDF3] text-[#0047FF] text-[21px] shadow-none hover:shadow-none"
                                      onClick={() => {
                                        setDeleteUpdateYes("update-FAQ");
                                        setAddEditDeleteTag(`update`);
                                        setFormData(_ele);
                                        handleOpen();
                                      }}
                                    >
                                      <BiEdit />
                                    </IconButton>
                                    <IconButton
                                      className="bg-[#EAEDF3] text-[#F62929] text-[21px] shadow-none hover:shadow-none"
                                      onClick={() => {
                                        setDeleteUpdateYes("delete-FAQ");
                                        setDeleteUpdateItem({
                                          id: _ele._id,
                                        });
                                        setConfirmationModalShow(
                                          !confirmationModalShow
                                        );
                                      }}
                                    >
                                      <RiDeleteBinLine />
                                    </IconButton>
                                  </div>
                                </td>
                              </tr>
                            )}
                          </Draggable>
                        ))
                      )}
                      {provided.placeholder}
                    </tbody>
                  )}
                </Droppable>
              </DragDropContext>
            </table>
          </CardBody>
        </Card>
      </div>
      <FaqModel />
    </>
  );
};
Faq.defaultProps = {
  title: "My Photo Keyboard | FAQ",
};

export default Faq;
