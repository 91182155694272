import React, { useContext } from 'react'
import { Button, Menu, MenuHandler, MenuItem, MenuList } from '@material-tailwind/react'
import { FaChevronDown } from 'react-icons/fa'

import { NewContext } from '../../Context/NewContext'

export const PerpageAndDeleteBtn = ({ Icon, delBtn = true, resetFilterBtn = true, name = "10", dropData, deleteUpdateTag, movePendingTag, moveRejectedTag, perPageCount = true, movePending = false, moveRejected = false, moveAprroved = false, moveApprovedTag }) => {
    const { filterDropdown, setFilterDropdown, selectedOptions, setSelectedOptions, range, setRange, filterDate, setFilterDate, perPage, setPerPage, setCurrentPage, checkedValues, handleDeleteAll, handleOpenReason, setSingleThemeRejected } = useContext(NewContext);

    return (
        <>
            <div className={`flex flex-wrap gap-2`}>
                {
                    perPageCount && perPage > 0 &&
                    <Menu placement='bottom-start'>
                        <MenuHandler>
                            <Button variant='outlined' color='white' className='text-darkblue bg-white border-[#B4BCC9] flex items-center px-4 lg:px-2 2xl:px-4'>
                                <div className='flex mr-2 items-center'>
                                    {/* <Icon size={"22px"} /> */}
                                    <span className='text-[16px] lg:text-[14px] capitalize font-normal'>{perPage}</span>
                                </div>
                                <FaChevronDown size={"12px"} />
                            </Button>
                        </MenuHandler>
                        <MenuList className='border-[#B4BCC9]'>
                            {
                                [5, 10, 15, 20, 25].map((_ele, i) => (
                                    <MenuItem key={i} onClick={() => { setCurrentPage(1); setPerPage(_ele); }} className={`${perPage == _ele ? `bg-[#0047FF] text-white hover:bg-[#0047FF] hover:text-white focus:bg-[#0047FF] focus:text-white active:bg-[#0047FF] active:text-white` : ``}`}>{_ele}</MenuItem>
                                ))
                            }
                        </MenuList>
                    </Menu>
                }
                {
                    delBtn && checkedValues.length > 0 && <Button className='bg-[#F22F42] shadow-none hover:shadow-none font-medium text-white text-base capitalize lg:text-[14px] lg:px-3' onClick={() => handleDeleteAll(deleteUpdateTag)} disabled={checkedValues?.length === 0}>Delete</Button>

                }
                {
                    movePending && checkedValues.length > 0 && <Button className='bg-[#0047FF] shadow-none hover:shadow-none font-medium text-white text-base capitalize lg:text-[14px] lg:px-3' onClick={() => handleDeleteAll(movePendingTag)} disabled={checkedValues?.length === 0}  >Move to pending</Button>
                }
                {
                    moveAprroved && checkedValues.length > 0 && <Button className='bg-[#2BC127] shadow-none hover:shadow-none font-medium text-white text-base capitalize lg:text-[14px] lg:px-3' onClick={() => handleDeleteAll(moveApprovedTag)} disabled={checkedValues?.length === 0}  >Approved</Button>
                }

                {
                    // moveRejected && checkedValues.length > 0 && <Button className='bg-[#DD2B2B] shadow-none hover:shadow-none font-medium text-white text-base capitalize lg:text-[14px] lg:px-3' onClick={() => handleDeleteAll(moveRejectedTag)} disabled={checkedValues?.length === 0} >Reject</Button>
                    moveRejected && checkedValues.length > 0 && <Button className='bg-[#DD2B2B] shadow-none hover:shadow-none font-medium text-white text-base capitalize lg:text-[14px] lg:px-3' onClick={() => { setSingleThemeRejected(false); handleOpenReason(); }} >Reject</Button>
                }
                {
                    resetFilterBtn && (Object.keys(filterDropdown).map(_key => filterDropdown[_key].length > 0).includes(true) || filterDate) && <Button className='bg-[#F22F42] shadow-none hover:shadow-none font-medium text-white text-base capitalize lg:text-[14px] lg:px-3' onClick={() => { setFilterDropdown({}); setSelectedOptions([]); setRange({}); setFilterDate(""); }} disabled={!Object.keys(filterDropdown).length}>Reset Filters</Button>
                }
            </div>

        </>
    )
}

export default PerpageAndDeleteBtn
