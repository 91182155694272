import React, { useContext, useEffect, useRef, useState } from 'react'
import { Spinner, Typography, Button, Input, Select, Option, Chip, IconButton } from "@material-tailwind/react";

import { NewContext } from '../../../Context/NewContext';
import { add_homescreen_key, add_section_settings, add_slider_tags, get_home_screen_data, get_homescreen_key_data, get_wallpaper_home_screen_data, update_section_settings } from '../../../Controller/actions';

import Switch from '../../Switch/Switch';
import THead from '../../Table/THead';
import FileInput from '../../FileInput/FileInput';
import { toast } from 'react-toastify';
import HomeActivityModalHeader from '../../HomeActivityModalHeader/HomeActivityModalHeader';
import { Box, Dialog, DialogContent, Modal } from '@mui/material';
import { BiEdit } from 'react-icons/bi';
import { RiDeleteBinLine } from 'react-icons/ri';
import ChildModal from './ChildModal';
import { Pagination } from '../../Pagination';
import { MultiSelect } from 'react-multi-select-component';
import { AiOutlineCloseCircle } from 'react-icons/ai';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
};
const ITEMS_PER_PAGE = 6;
export const HomeActivityModal = () => {
    const [key, setKey] = useState([]);
    const [multiKey, setMultiKey] = useState([]);

    const [wallpaperCount, setWallpaperCount] = useState(6);
    const { homeActivityRef, homeScreenData, currentModalTableData, setHomeTableData, setCurrentModalTableData, setHomeScreenData, search, perPage, filterDropdown, deleteUpdateTag, setChildFormData, handleChildOpen, homeTableData, setMultiCountry, setMultiRegion, setIsModalLoading, setDeleteUpdateTag, deleteUpdateYes, handleSwitch, handleChange, formData, handleSwitchUpdate, addEditDeleteTag, setAddEditDeleteTag, currentHomeModalKey, isModalLoading, setDeleteUpdateYes, open, handleClose, handleSingleSelect, confirmationModalShow, setConfirmationModalShow, setDeleteUpdateItem, handleOpen, setFormData, capitalalizeFirstletter, updateStateAryData } = useContext(NewContext);

    const simpleFields = [
        { name: "Preview", },
        { name: ['subscription', 'kaomoji', 'effect', 'instafont', 'textart', 'emojimaker', 'font', 'sound'].includes(currentHomeModalKey) ? "Redirect To" : "Name", },
        { name: "Active", },
    ];
    currentHomeModalKey === 'wallpaper' && simpleFields.splice(-1)
    const totalFields = simpleFields.length + 2;

    const [currentPage, setCurrentPage] = useState(1);
    const totalPages = Math.ceil(homeTableData.length / ITEMS_PER_PAGE);
    const startIndex = (currentPage - 1) * ITEMS_PER_PAGE;
    const endIndex = startIndex + ITEMS_PER_PAGE;
    const currentData = homeTableData.slice(startIndex, endIndex);
    const handlePageChange = (pageNumber) => setCurrentPage(pageNumber);

    const getSliderData = async () => {
        try {
            const getHomeActivityPreview = await get_home_screen_data();
            if (getHomeActivityPreview) {
                if (getHomeActivityPreview.status) {
                    setHomeScreenData(getHomeActivityPreview.section_setting);
                    getHomeActivityPreview.section_setting.map((_ele) => {
                        if (_ele.key === 'wallpaper') {
                            setWallpaperCount(_ele.limit);
                        }
                    });
                    setCurrentPage(1);
                    const getWallpapers = await get_wallpaper_home_screen_data(wallpaperCount);
                    setCurrentModalTableData({ ...getHomeActivityPreview, wallpaper_list: getWallpapers });
                } else {
                    setHomeScreenData([]);
                }
            }
        } catch (error) {
            console.error(error);
        }
    }
    useEffect(() => {
        getSliderData();
    }, [deleteUpdateTag, search, perPage, filterDropdown, wallpaperCount]);
    const filteredData = (_data) => _data.filter(_ele => _ele.isActive)
    useEffect(() => {
        if (currentHomeModalKey === 'slider') {
            setHomeTableData(currentModalTableData.slider_list || []);
        } else if (currentHomeModalKey === 'custom_second_sec') {
            setHomeTableData(filteredData(currentModalTableData.custom_second_sec_list) || []);
        } else if (currentHomeModalKey === 'functionality') {
            setHomeTableData(currentModalTableData.functionality_list || []);
        } else if (currentHomeModalKey === 'theme') {
            setHomeTableData(currentModalTableData.theme_list || []);
        } else if (currentHomeModalKey === 'top_creator_theme') {
            setHomeTableData(currentModalTableData.top_creator_theme_list || []);
        } else if (currentHomeModalKey === 'font') {
            setHomeTableData(currentModalTableData.font || []);
        } else if (currentHomeModalKey === 'sticker') {
            setHomeTableData(currentModalTableData.sticker_list || []);
        } else if (currentHomeModalKey === 'wallpaper') {
            setHomeTableData(currentModalTableData.wallpaper_list || []);
        } else if (currentHomeModalKey === 'kaomoji') {
            setHomeTableData(currentModalTableData.kaomoji || []);
        } else if (currentHomeModalKey === 'textart') {
            setHomeTableData(currentModalTableData.textart || []);
        } else if (currentHomeModalKey === 'subscription') {
            setHomeTableData(currentModalTableData.subscription || []);
        } else if (currentHomeModalKey === 'sound') {
            setHomeTableData(currentModalTableData.sound || []);
        } else if (currentHomeModalKey === 'instafont') {
            setHomeTableData(currentModalTableData.instafont || []);
        } else if (currentHomeModalKey === 'effect') {
            setHomeTableData(currentModalTableData.effect || []);
        } else if (currentHomeModalKey === 'emojimaker') {
            setHomeTableData(currentModalTableData.emojimaker || []);
        } else if (currentHomeModalKey?.toLowerCase() === 'game') {
            setHomeTableData(currentModalTableData.game_list || []);
        } else if (currentHomeModalKey?.toLowerCase() === 'gamebanner') {
            setHomeTableData(currentModalTableData.gameBanner || []);
        } else {
            setHomeTableData([])
        }
        let findCurrentPageData = homeScreenData.find(_e => _e.key?.toLowerCase() === currentHomeModalKey?.toLowerCase());
        setFormData(findCurrentPageData);
        setDeleteUpdateTag("");
    }, [deleteUpdateTag, currentHomeModalKey, currentModalTableData]);
    const handleSelectMultiKeys = (e) => {
        if (e.length > 0) {
            e.map(_ele => {
                let multiSelectAry = [].slice.call(e).map(item => item.value);
                // if (_ele.name === 'slider_tag') {
                setMultiKey([{ ...e[0], disabled: false }]);
                setFormData({ ...formData, [_ele.name]: multiSelectAry[0] });
                if (e.length == 1) {
                    let selectedSliderTag = key.filter(_ele => e[0]._id === _ele._id);
                    let nonSelectedSliderTag = key.filter(_ele => e[0]._id !== _ele._id).map(_ele => ({ ..._ele, disabled: true }))
                    setKey(selectedSliderTag.concat(nonSelectedSliderTag));
                }
                // }
            })
        } else {
            let keys = key.map(_ele => ({ ..._ele, disabled: false }));
            setKey(keys);
            setMultiKey([]);
            setFormData({ ...formData, slider_tag: [] })
        }
        homeActivityRef.current.focus();
    }
    const handleNewField = async (_value) => {
        let { status, data } = await add_homescreen_key({ name: _value });
        if (status) {
            key.unshift({ _id: data._id, value: data.name, label: capitalalizeFirstletter(data.name), name: `key`, disabled: false });
            return {
                _id: data._id,
                name: `key`,
                disabled: false,
                label: capitalalizeFirstletter(data.name),
                value: data.name,
            }
        }
    };
    const getDataFunc = async () => {
        try {
            const keyData = await get_homescreen_key_data(1, "", "", { isActive: true });
            if (keyData.status) {
                // setKey(keyData.data);
                keyData.data.map(_ele => {
                    if (key.find(_e => _e._id === _ele._id)) {
                        if (key.find(_e => _e.name !== _ele.name)) {
                            let filterData = key.map(_t => ({ ..._t, name: `key` }))
                            setKey(filterData);
                        }
                    } else {
                        _ele.name && setKey(_prev => [..._prev, { _id: _ele._id, value: _ele.name, label: _ele.name && capitalalizeFirstletter(_ele.name), name: `key` }])
                    }
                });
                // if (addEditDeleteTag === `update-c`) {
                //     let oldKeyData = updateStateAryData([formData['key']], keyData.data, `key`);
                //     setMultiKey(oldKeyData);
                //     if (oldKeyData.length == 1) {
                //         let selectedSliderTag = key.filter(_ele => oldKeyData[0]._id === _ele._id);
                //         let nonSelectedSliderTag = key.filter(_ele => oldKeyData[0]._id !== _ele._id).map(_ele => ({ ..._ele, disabled: true }))
                //         setKey(selectedSliderTag.concat(nonSelectedSliderTag));
                //     }
                // }
            } else {
                toast.error(keyData.message);
            }
        } catch (error) {
            console.error(error);
        };
    };
    useEffect(() => {
        getDataFunc();
    }, [addEditDeleteTag, open]);
    const handleSubmit = async (e) => {
        e.preventDefault();
        let createUpdateSlider;
        setIsModalLoading(true);
        if (addEditDeleteTag === `update`) {
            // let countryIdAry = multiCountry.map(_ele => _ele._id)
            createUpdateSlider = await update_section_settings(formData, formData?._id)
        } else {
            if (multiKey.length) {
                createUpdateSlider = await add_section_settings(formData)
            } else {
                toast.error(`Please Select Key!`);
            }
        }
        setIsModalLoading(false);
        if (createUpdateSlider) {
            const { message, status } = createUpdateSlider;
            if (status) {
                toast.success(message);
                handleClose();
                // setValidated(false);
                setDeleteUpdateTag(deleteUpdateYes);
                setMultiCountry([]);
                setMultiRegion([]);
                setKey([]); setMultiKey([]);
            } else {
                toast.error(message);
            };
        }
    }
    const handleDeleteUpdateYes = (_tag) => {
        if (currentHomeModalKey === `slider`) {
            if (_tag === `delete`) {
                setDeleteUpdateYes("delete-slider");
            } else if (_tag === `update`) {
                setDeleteUpdateYes("update-slider");
            }
        } else if (currentHomeModalKey === `functionality`) {
            if (_tag === `delete`) {
                setDeleteUpdateYes("delete-functionality");
            } else if (_tag === `update`) {
                setDeleteUpdateYes("update-functionality");
            }
        } else if (currentHomeModalKey === `custom_second_sec`) {
            if (_tag === `delete`) {
                setDeleteUpdateYes("delete-customsection");
            } else if (_tag === `update`) {
                setDeleteUpdateYes("update-customsection");
            }
        } else if (currentHomeModalKey === `theme`) {
            if (_tag === `delete`) {
                setDeleteUpdateYes("delete-themetab");
            } else if (_tag === `update`) {
                setDeleteUpdateYes("update-themetab");
            }
        } else if (['subscription', 'kaomoji', 'effect', 'instafont', 'textart', 'emojimaker', 'font', 'sound'].includes(currentHomeModalKey)) {
            if (_tag === `delete`) {
                setDeleteUpdateYes("delete-banner");
            } else if (_tag === `update`) {
                setDeleteUpdateYes("update-banner");
            }
        }
    }

    return (
        <>
            <Dialog
                open={open}
                onClose={() => { handleClose(); setKey([]); setMultiKey([]); }}
                aria-labelledby="responsive-dialog-title"
                sx={{
                    "& .MuiDialog-container": {
                        "& .MuiPaper-root": {
                            width: "100%",
                            maxWidth: `${currentHomeModalKey === "add" ? "395px" : "1200px"}`,
                            borderRadius: "12px"
                        },
                    },
                }}
            >
                <DialogContent>
                    {
                        currentHomeModalKey ?
                            <>
                                {
                                    currentHomeModalKey === 'add' ?
                                        <form onSubmit={handleSubmit}>
                                            <div className="">
                                                <div className="">
                                                    <Typography className="text-lg text-[#1E1627] font-medium">Preview:</Typography>
                                                    <div className='w-auto'>
                                                        <FileInput name={`homePreview`} imgClass={`h-full`} previewFields={'preview_img'} accept={'image/*'} required={false} />
                                                    </div>
                                                </div>
                                                <div className="mt-5 grid grid-cols-6 lg:grid-cols-6 gap-5 add-edit-form">
                                                    <div className="col-span-6 sm:col-span-6">
                                                        <label htmlFor="name" className="block text-sm font-medium leading-6 text-gray-900">Name:</label>
                                                        <div className="mt-1.5">
                                                            <Input id='name' placeholder='Enter Name' name='name' onChange={handleChange} value={formData?.name || ''} autoComplete='off' required />
                                                        </div>
                                                    </div>
                                                    <div className="col-span-6 sm:col-span-6">
                                                        <label htmlFor="key" className="block text-sm font-medium leading-6 text-gray-900">Key:</label>
                                                        <div className="mt-1.5 relative">
                                                            {/* <Select name='key' onChange={(e) => handleSingleSelect(`key`, e)} value={formData?.key}>
                                                                    {key?.map((_ele, i) => <Option key={i} value={_ele.name?.toLowerCase()}>{_ele?.name}</Option>)}
                                                                </Select> */}
                                                            <MultiSelect
                                                                labelledBy='Select Key'
                                                                options={key}
                                                                value={multiKey}
                                                                onChange={handleSelectMultiKeys}
                                                                hasSelectAll={false}
                                                                isCreatable={true}
                                                                onCreateOption={handleNewField}
                                                            />
                                                            <input ref={homeActivityRef} className='absolute top-0 right-0 bottom-0 left-0 m-auto w-[95%] h-[80%] bg-red-300 -z-10' />
                                                        </div>
                                                    </div>
                                                    {
                                                        currentHomeModalKey === 'slider' || currentHomeModalKey === 'Game' || currentHomeModalKey === 'sticker' &&
                                                        <div className="col-span-6 sm:col-span-6">
                                                            <label htmlFor="limit" className="block text-sm font-medium leading-6 text-gray-900">Data Limit:</label>
                                                            <div className="mt-1.5">
                                                                <Input id='limit' placeholder='Enter Data Limit' type='number' name='limit' onChange={handleChange} value={formData?.limit} />
                                                            </div>
                                                        </div>
                                                    }
                                                    <div className="col-span-3 sm:col-span-6">
                                                        <label htmlFor="isShowNameHomeScreen" className="block text-sm font-medium leading-6 text-gray-900">Show Name Home Screen:</label>
                                                        <div className="mt-1.5 pl-1">
                                                            <Switch color='green' id='isShowNameHomeScreen' name='isShowNameHomeScreen' onChange={handleSwitch} checked={formData?.isShowNameHomeScreen} />
                                                        </div>
                                                    </div>
                                                    <div className="col-span-3 sm:col-span-6">
                                                        <label htmlFor="isActive" className="block text-sm font-medium leading-6 text-gray-900">Active:</label>
                                                        <div className="mt-1.5 pl-1">
                                                            <Switch color='green' id='isActive' name='isActive' onChange={handleSwitch} checked={formData?.isActive} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='flex justify-end mt-10'>
                                                    <Button
                                                        onClick={() => { handleClose(); setKey([]); setMultiKey([]); }}
                                                        className="mr-1 px-5 py-2.5 bg-white shadow-none text-[#1E3354] font-medium border border-[#75869F] hover:shadow-none capitalize text-base"
                                                    >
                                                        <span>Cancel</span>
                                                    </Button>
                                                    <Button
                                                        className="flex items-center mr-1 px-5 py-2.5 bg-[#0047FF] shadow-none text-white font-medium border border-[#0047FF] hover:shadow-none capitalize text-base"
                                                        type='submit'
                                                    >
                                                        <span>{currentHomeModalKey === `add` ? `Add` : `Update`}</span>
                                                    </Button>
                                                </div>
                                            </div>
                                        </form>
                                        :
                                        <div className="p-1">
                                            <HomeActivityModalHeader nonActive={!['sticker', 'wallpaper', 'top_creator_theme', 'Game', 'subscription', 'kaomoji', 'effect', 'instafont', 'textart', 'emojimaker', 'font', 'sound', 'gameBanner'].includes(currentHomeModalKey)} addBtn={!['sticker', 'wallpaper', 'top_creator_theme', 'theme', 'Game', 'subscription', 'kaomoji', 'effect', 'instafont', 'textart', 'emojimaker', 'font', 'sound', 'gameBanner'].includes(currentHomeModalKey)} />
                                            {/* <SearchAndAddBtn addBtn={true} countryFilter={false} FilterDropdowns={false} isSearch={false} perPageCount={false} /> */}
                                            <table className="w-full min-w-max text-left">
                                                <THead simpleFields={simpleFields} actionField={!['wallpaper', 'top_creator_theme', 'Game', 'sticker'].includes(currentHomeModalKey)} isSelectAll={false} />
                                                <tbody className='!min-h-16 !overflow-y-auto'>
                                                    {
                                                        isModalLoading ?
                                                            <tr>
                                                                <td colSpan={totalFields} className='py-12'>
                                                                    <div className="flex justify-center items-center">
                                                                        <Spinner />
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                            : currentData?.length === 0 ?
                                                                <tr>
                                                                    <td colSpan={totalFields} className='py-12 bg-gray-200'>
                                                                        <div className="flex justify-center items-center">
                                                                            No Data Found
                                                                        </div>
                                                                    </td>
                                                                </tr>
                                                                :
                                                                currentData?.map((_ele, i) => {
                                                                    const isLast = i === currentData?.length - 1;
                                                                    const classes = `text-[#1E1627] ${isLast ? "p-4" : "p-[14.5px]"}`;

                                                                    return (
                                                                        <tr key={i} className='border-b border-blue-gray-50'>
                                                                            <td className={classes}>
                                                                                <img key={i} src={_ele.preview_img || _ele.preview || _ele.banner_image_url || _ele.mediumPreview640 || _ele.small_preview || _ele?.urls?.regular || _ele?.thumbnail_small_path || `../../../assets/images/no-image.png`} alt={_ele.name || _ele.alt_description} className='h-[55px]' />
                                                                            </td>
                                                                            <td className={classes}>
                                                                                <span className={`font-normal text-[17px] capitalize`}>{_ele.name || _ele.banner_view_type || _ele.theme_type || _ele.alt_description || _ele.title || `None`}</span>
                                                                            </td>
                                                                            {
                                                                                currentHomeModalKey !== 'wallpaper' && ["isActive"].map((_is_ele, i) => (
                                                                                    <td className={classes} key={i}>
                                                                                        {
                                                                                            currentHomeModalKey === `theme` ?
                                                                                                <Switch id={_ele._id} name={`show_in_homescreen`} onChange={(e) => { handleDeleteUpdateYes(`update`); handleSwitchUpdate(e); }} checked={_ele.show_in_homescreen} />
                                                                                                : <Switch id={_ele._id} name={_is_ele} onChange={(e) => { handleDeleteUpdateYes(`update`); handleSwitchUpdate(e); }} checked={_ele[_is_ele]} />
                                                                                        }
                                                                                    </td>
                                                                                ))
                                                                            }
                                                                            {
                                                                                !['wallpaper', 'top_creator_theme', 'Game', 'sticker'].includes(currentHomeModalKey) && //  || currentHomeModalKey !== 'top_creator_theme'
                                                                                <td className={classes}>
                                                                                    <div className="flex items-center gap-2">
                                                                                        <IconButton className='bg-[#EAEDF3] text-[#0047FF] text-[21px] shadow-none hover:shadow-none' onClick={(e) => { setAddEditDeleteTag(`update-c`); setDeleteUpdateYes(`update`); setChildFormData(_ele); handleChildOpen() }}>
                                                                                            <BiEdit />
                                                                                        </IconButton>
                                                                                        <IconButton className='bg-[#EAEDF3] text-[#F62929] text-[21px] shadow-none hover:shadow-none' onClick={(e) => { handleDeleteUpdateYes('delete'); setDeleteUpdateItem({ id: _ele._id, name: _ele.name }); setConfirmationModalShow(!confirmationModalShow) }}>
                                                                                            <RiDeleteBinLine />
                                                                                        </IconButton>
                                                                                    </div>
                                                                                </td>
                                                                            }
                                                                        </tr>
                                                                    );
                                                                })
                                                    }
                                                </tbody>
                                            </table>
                                            <div className='flex items-center justify-between mt-[30px]'>
                                                <div className="flex items-center gap-3">
                                                    {
                                                        homeTableData && homeTableData.length > ITEMS_PER_PAGE && <Pagination currentPage={currentPage} totalPage={totalPages} getData={handlePageChange} />
                                                    }
                                                </div>
                                                <div className='flex'>
                                                    <Button
                                                        onClick={handleClose}
                                                        className="mr-1 px-5 py-2.5 bg-white shadow-none text-[#1E3354] font-medium border border-[#75869F] hover:shadow-none capitalize text-base"
                                                    >
                                                        <span>Cancel</span>
                                                    </Button>
                                                    <Button
                                                        disabled={isModalLoading}
                                                        onClick={handleSubmit}
                                                        className="flex items-center mr-1 px-5 py-2.5 bg-[#0047FF] shadow-none text-white font-medium border border-[#0047FF] hover:shadow-none capitalize text-base"
                                                    >
                                                        {isModalLoading && <Spinner className="h-4 w-4" />}
                                                        <span className={isModalLoading && 'ml-4'}>{`Save`}</span>
                                                    </Button>
                                                </div>
                                            </div>
                                        </div>
                                }
                            </> :
                            <>
                                <div className='flex justify-end'>
                                    <Button
                                        variant='outlined'
                                        onClick={handleClose}
                                        className="p-0 shadow-none text-[#1E3354] font-medium border border-transparent hover:shadow-none capitalize text-base rounded-full"
                                    >
                                        <AiOutlineCloseCircle size={30} />
                                    </Button>
                                </div>
                                <iframe src="../../../assets/pdfs/sample.pdf" className={`w-full h-[85vh] rounded-xl mt-2`} frameborder="0"></iframe>
                            </>
                    }
                </DialogContent>
            </Dialog >
            <ChildModal homeActiveLength={currentData.length + 1} />
        </>
    )

}



export default HomeActivityModal
