import React, { useContext, useEffect, useState } from 'react'
import { Card, CardBody, IconButton, Checkbox, Tooltip, Spinner } from "@material-tailwind/react";
import { BiEdit, BiFilter } from 'react-icons/bi';
import { IoEyeOutline, IoClose } from 'react-icons/io5';
import { TbLayout2, TbUser } from 'react-icons/tb';
import moment from 'moment';

import { Dropdowns, Pagination, SearchAndAddBtn, THead } from '../../Components';
import { NewContext } from '../../Context/NewContext';
import { get_history_data, get_history_filter_data } from '../../Controller/actions';
import { MdOutlineTimer } from 'react-icons/md';
import { InformationCircleIcon } from '@heroicons/react/24/outline';
import { baseURL } from '../../Controller/api';

const FilterDropdowns = ({ historySection, historyUsers }) => {
  const typeFilter = [{ label: 'Added' }, { label: 'Updated' }, { label: 'Deleted' }, { label: 'Deleted All' }];
  return (
    <div className='flex gap-2.5'>
      <Dropdowns title={`Section`} name={`section`} Icon={TbLayout2} dropData={historySection} />
      <Dropdowns title={`Type`} name={`action`} Icon={BiFilter} dropData={typeFilter} />
      <Dropdowns title={`User`} name={`user`} Icon={TbUser} dropData={historyUsers} />
      <Dropdowns title={`Date and time`} name={`dateTime`} Icon={MdOutlineTimer} dateTime={true} />
    </div>
  )
}
export const History = ({ title }) => {
  // const [currentPageData, setCurrentPageData] = useState([]);
  const { permissions, tokenType, capitalalizeFirstletter, currentPageData, setCurrentPageData, isLoading, deleteUpdateTag, checkedValues, handleCheckboxChange, currentPage, totalPage, perPage, search, setIsLoading, setCurrentPage, setPerPage, setTotalPage, filterDropdown, setDeleteUpdateTag } = useContext(NewContext);
  const simpleFields = [
    { name: "Section", },
    { name: "Type", },
    { name: "Name", },
    { name: "Old Data", },
    { name: "New Data", },
    { name: "Updated By", },
    { name: "Updated At", },
  ];
  const [pagePermissions, setPagePermissions] = useState({
    CU: true,
    R: true,
    D: true
  });
  const totalFields = simpleFields.length + 1;

  // const [category, setCategory] = useState([]);
  const [historySection, setHistorySection] = useState([]);
  const [historyUsers, setHistoryUsers] = useState([]);
  const getCategoryData = async () => {
    // const categoryData = await get_theme_categories_data(1, "", "", { isActive: true });
    const historyFilterData = await get_history_filter_data();
    // categoryData.data.map(_ele => setCategory(_prev => !category.includes(_prev) && [..._prev, { id: _ele._id, label: capitalalizeFirstletter(_ele.name) }]))
    historyFilterData.data.section.map(_ele => setHistorySection(_prev => !historySection.includes(_prev) && [..._prev, { label: capitalalizeFirstletter(_ele) }]))
    historyFilterData.data.name.map(_ele => setHistoryUsers(_prev => !historyUsers.includes(_prev) && [..._prev, { id: _ele._id, label: capitalalizeFirstletter(_ele.name) }]))
  }
  useEffect(() => {
    getCategoryData();
  }, [])
  const getHistoryData = async (_currentPage = 1) => {
    setIsLoading(true);
    try {
      const getThemeCategories = await get_history_data(_currentPage, perPage, search, filterDropdown);
      if (getThemeCategories) {
        const { status, data, currentPage, perPage, totalPage } = getThemeCategories;
        if (status) {
          setCurrentPageData(data);
          setCurrentPage(currentPage);
          setPerPage(perPage);
          setTotalPage(totalPage || 1);
        } else {
          setCurrentPageData([]);
        }
      }
    } catch (error) {
      console.error(error);
    }
    setIsLoading(false);
  }
  useEffect(() => {
    getHistoryData(currentPage);
    setDeleteUpdateTag("");
  }, [deleteUpdateTag, search, currentPage, perPage, filterDropdown, pagePermissions]);
  useEffect(() => {
    if (tokenType === 'ldap') {
      const update = permissions?.subPermissions?.find((route) => route.name === 'History');
      setPagePermissions({ ...pagePermissions, CU: update?.CU, R: update?.R, D: update?.D })
    }
  }, [tokenType, permissions])

  useEffect(() => {
  }, [pagePermissions])
  useEffect(() => {
    document.title = title;
  }, [title]);
  return (
    <>
      <SearchAndAddBtn title={`History`} addBtn={false} isSearch={false} FilterDropdowns={<FilterDropdowns historySection={historySection} historyUsers={historyUsers} />} countryFilter={false} deleteUpdateTag='all-delete-history' />
      <Card className="overflow-hidden w-full rounded-md shadow-none">
        <CardBody className="overflow-x-auto w-100 py-2.5 px-[5px] card-body-scrollbar">
          <table className="w-full min-w-max table-auto text-left ">
            <THead simpleFields={simpleFields} dropdownFields={false} actionField={false} />
            <tbody>
              {
                isLoading ?
                  <tr>
                    <td colSpan={totalFields} className='py-12'>
                      <div className="flex justify-center items-center">
                        <Spinner />
                      </div>
                    </td>
                  </tr>
                  : currentPageData?.length === 0 ?
                    <tr>
                      <td colSpan={totalFields} className='py-12 bg-gray-200'>
                        <div className="flex justify-center items-center">
                          No Data Found
                        </div>
                      </td>
                    </tr>
                    : currentPageData?.map((_ele, i) => {
                      const isLast = i === currentPageData?.length - 1;
                      const classes = `text-[#1E1627] ${isLast ? "p-4" : "p-[14.5px]"}`;

                      return (
                        <tr key={i} className='border-b border-blue-gray-50'>
                          <td className={classes}>
                            <Checkbox
                              className='w-[18px] h-[18px] border-[1.5px] rounded-[3.6px] border-[#1E3354] checked:bg-[#0047FF] checked:border-transparent'
                              id={`slider-${_ele._id}`}
                              value={`${_ele._id}`}
                              disabled={!pagePermissions?.CU}
                              checked={checkedValues.includes(`${_ele._id}`)}
                              onChange={() => handleCheckboxChange(`${_ele._id}`)}
                            />
                          </td>
                          <td className={`${classes}`}>
                            <div className="flex items-center">
                              <span className={`font-normal text-[17px] !w-28 truncate ${!_ele.section && `text-gray-500`}`}>{_ele.section || `None`}</span>
                              {
                                _ele?.section && _ele.section?.length > 12 &&
                                <Tooltip placement="right-start" content={
                                  <div className="w-auto">
                                    {_ele.section || `None`}
                                  </div>
                                }>
                                  <InformationCircleIcon strokeWidth={2} className="text-[#1E1627] w-5 h-5 cursor-pointer" />
                                </Tooltip>
                              }
                            </div>
                          </td>
                          <td className={`${classes}`}>
                            <span className={`font-normal text-[17px] ${!_ele.action && `text-gray-500`}`}>{_ele.action || `None`}</span>
                          </td>
                          <td className={`${classes}`}>
                            <div className="flex items-center">
                              <span className={`font-normal text-[17px] !w-36 truncate`}>
                                {_ele.id?.length > 0 ? (_ele.id[0]?.name ? _ele.id[0]?.name : _ele.id[0]?.textmoji ? _ele.id[0]?.textmoji : _ele.id[0]?.title ? _ele.id[0]?.title : _ele.id[0]?.reason ? _ele.id[0]?.reason : _ele.id[0]?.banner_view_type || "None") : "None"}
                              </span>
                              {
                                _ele.id?.length > 0 && _ele?.id[0]?.textmoji && _ele?.id[0]?.textmoji?.length > 12 &&
                                <Tooltip placement="right-start" content={
                                  <div className="w-60">
                                    {_ele?.id[0]?.textmoji && _ele?.id[0]?.textmoji?.length !== 0 && _ele?.id[0]?.textmoji?.length > 1 && _ele?.id[0]?.textmoji || `None`}
                                  </div>
                                }>
                                  <InformationCircleIcon strokeWidth={2} className="text-[#1E1627] w-5 h-5 cursor-pointer" />
                                </Tooltip>
                              }
                              {
                                _ele.id?.length > 0 && _ele?.id[0]?.name && _ele?.id[0]?.name?.length > 12 &&
                                <Tooltip placement="right-start" content={
                                  <div className="w-60">
                                    {_ele.id[0]?.name && _ele.id[0]?.name?.length !== 0 && _ele.id[0]?.name?.length > 1 && _ele.id[0]?.name || `None`}
                                  </div>
                                }>
                                  <InformationCircleIcon strokeWidth={2} className="text-[#1E1627] w-5 h-5 cursor-pointer" />
                                </Tooltip>
                              }
                            </div>
                          </td>
                          <td className={`${classes}`}>
                            <span className='flex items-center'>
                              <span>
                                {
                                  _ele?.oldData && Object?.keys(_ele?.oldData)?.length ? Object?.keys(_ele?.oldData).map((_key, i) => {
                                    if (_ele.oldData[_key].toString().includes(baseURL)) {
                                      return <span key={i} className={`font-normal text-[17px] block text-[#F62929]`}>{_key} : {_ele.oldData[_key].toString().split('/').slice(-1).join('/') || `None`}</span>
                                    } else {
                                      return <span key={i} className={`font-normal text-[17px] block text-[#F62929] !w-80 truncate`}>{_key} : {_ele.oldData[_key].toString() || `None`}</span>
                                    }
                                  }) : <span className='text-gray-500'>None</span>
                                }
                              </span>
                              {
                                _ele?.oldData && Object.keys(_ele.oldData).length > 0 &&
                                <Tooltip placement="right-start" content={
                                  <div className="p-2 w-[300px] h-48 2xl:w-[400px] overflow-y-auto">
                                    {Object.keys(_ele.oldData).map((_key, i) => {
                                      if (_ele.oldData[_key].toString().includes(baseURL)) {
                                        return <p key={i} className={`font-normal text-[17px] block text-[#F62929]`}>{_key} : {_ele.oldData[_key].toString().split('/').slice(-1) || `None`}</p>
                                      } else {
                                        return <p key={i} className={`font-normal text-[17px] block text-[#F62929] !w-80`}>{_key} : {_ele.oldData[_key].toString() || `None`}</p>
                                      }
                                    })}
                                  </div>
                                }>
                                  <InformationCircleIcon strokeWidth={2} className="text-[#1E1627] w-5 h-5 cursor-pointer" />
                                </Tooltip>
                              }
                            </span>
                          </td>
                          <td className={`${classes}`}>
                            <span className='flex items-center'>
                              <span>
                                {
                                  _ele?.newData && Object.keys(_ele.newData).length ? Object.keys(_ele.newData).map((_key, i) => {
                                    if (_ele.newData[_key].toString().includes(baseURL)) {
                                      return <p key={i} className={`font-normal text-[17px] block text-[#0DBC7D]`}>{_key} : {_ele.newData[_key].toString().split('/').slice(-1) || `None`}</p>
                                    } else {
                                      return <p key={i} className={`font-normal text-[17px] block text-[#0DBC7D] !w-80 truncate`}>{_key} : {_ele.newData[_key].toString() || `None`}</p>
                                    }
                                  }) : <span className='text-gray-500'>None</span>
                                }
                              </span>
                              {
                                _ele?.newData && Object.keys(_ele.newData).length > 0 &&
                                <Tooltip placement="right-start" content={
                                  <div className="p-2 w-[300px] h-48 2xl:w-[400px] overflow-y-auto">
                                    {Object.keys(_ele.newData).map((_key, i) => {
                                      if (_ele.newData[_key].toString().includes(baseURL)) {
                                        return <p key={i} className={`font-normal text-[17px] block text-[#0DBC7D]`}>{_key} : {_ele.newData[_key].toString().split('/').slice(-1) || `None`}</p>
                                      } else {
                                        return <p key={i} className={`font-normal text-[17px] block text-[#0DBC7D] !w-80 truncate`}>{_key} : {_ele.newData[_key].toString() || `None`}</p>
                                      }
                                    })}
                                  </div>
                                }>
                                  <InformationCircleIcon strokeWidth={2} className="text-[#1E1627] w-5 h-5 cursor-pointer" />
                                </Tooltip>
                              }
                            </span>
                          </td>
                          <td className={classes}>
                            <span className={`font - normal capitalize text - [17px] ${_ele.update_by && !_ele.update_by.name && `text-gray-500`} `}>{(_ele.update_by && _ele.update_by.name) || `None`}</span>
                          </td>
                          <td className={classes}>
                            <span className={`font - normal text - [17px] ${!_ele.updatedAt && `text-gray-500`} `}>{moment(_ele.updatedAt).local().format("DD/MM/YYYY, hh:mm A") || `None`}</span>
                          </td>
                        </tr>
                      );
                    })
              }
            </tbody>
          </table>
        </CardBody>
      </Card>
      {
        totalPage > 1 &&
        <Pagination currentPage={currentPage} totalPage={totalPage} getData={getHistoryData} />
      }
    </>
  )
}

History.defaultProps = {
  title: "My Photo Keyboard | History"
};
export default History
