import React, { useContext, useEffect, useState } from 'react';
import { Spinner } from "@material-tailwind/react";
import { DashboradCard, SearchAndAddBtn } from '../../Components';
import { get_dashboard_data } from '../../Controller/actions';
import { NewContext } from '../../Context/NewContext';

export const Dashboard = ({ title }) => {
    const { search, isLoading, setIsLoading, currentPageData, setCurrentPageData, permissions, tokenType } = useContext(NewContext);
    const [pagePermissions, setPagePermissions] = useState({
        CU: true,
        R: true,
        D: true
    });

    const getDashboardData = async (_currentPage = 1) => {
        setIsLoading(true);
        try {
            const getDashboards = await get_dashboard_data(search);
            if (getDashboards) {
                const { status, data } = getDashboards;
                if (status) {
                    setCurrentPageData(data);
                } else {
                    setCurrentPageData([]);
                }
            }
        } catch (error) {
            console.error(error);
        }
        setIsLoading(false);
    }

    useEffect(() => {
        getDashboardData();
    }, [search])

    useEffect(() => {
        if (tokenType === 'ldap') {
            const update = permissions?.subPermissions?.find((route) => route.name === 'Dashboard');
            setPagePermissions({ ...pagePermissions, CU: update?.CU, R: update?.R, D: update?.D })
        }
    }, [tokenType, permissions])

    useEffect(() => {
    }, [pagePermissions])

    useEffect(() => {
        document.title = title;
    }, [title])

    return (
        <>
            <SearchAndAddBtn title={`Dashboard`} addBtn={false} delBtn={false} resetFilterBtn={false} perPageCount={false} countryFilter={false} isSearch={false} />
            {
                isLoading ?
                    <div className="flex justify-center items-center py-12 bg-white">
                        <Spinner />
                    </div>
                    : currentPageData.length === 0 ?
                        <div className="flex justify-center items-center py-12 bg-white">
                            No Data Found
                        </div> :
                        <div className="grid grid-cols-1 sm:grid-cols-2 xl:grid-cols-3 lg:grid-cols-3 2xl:grid-cols-4 gap-4 pb-4">
                            {
                                currentPageData?.map((_ele, i) => (
                                    <DashboradCard cardData={_ele} key={i} />
                                ))
                            }
                        </div>
            }
        </>
    )
};

Dashboard.defaultProps = {
    title: "My Photo Keyboard | Dashboard"
};

export default Dashboard;
