import { BiBrushAlt, BiSticker, BiBookmarks, BiData } from "react-icons/bi";
import { CiGrid42 } from "react-icons/ci";
import { HiOutlineHashtag } from "react-icons/hi";
import { TbSmartHome, TbHistory } from "react-icons/tb";
import { IoImagesOutline, IoTextOutline } from "react-icons/io5"
import { BsGrid1X2 } from "react-icons/bs"
import { MdOutlineDraw, MdOutlineEmojiSymbols } from "react-icons/md";
import { LuLayoutGrid } from "react-icons/lu";
import { RiLineChartLine, RiSurveyLine } from "react-icons/ri";
import { TfiLayoutSliderAlt } from "react-icons/tfi";
import { FaChartLine, FaQuestion } from "react-icons/fa";


const icon = { size: "24px" };

export const routes = {
  sidebarRoutes: [
    {
      name: "Dashboard",
      path: "/dashboard",
      key: "525ec8a6-a4a9-4754-886a-d1527a2eedef",
      icon: <TbSmartHome {...icon} />
    },
    {
      name: "Home",
      path: "/homescreen",
      key: "efdf66d6-c4a1-4adf-85fc-b8e4bcfb51f6",
      icon: <LuLayoutGrid  {...icon} />,
      items: [
        {
          name: "Home Screen",
          path: "/homescreen",
          key: "efdf66d6-c4a1-4adf-85fc-b8e4bcfb51f6",
        },
        {
          name: "Custom Section",
          path: "/customsection",
          key: "acd8c4f2-6cad-4bf3-8484-c3a9cf224814",
        },
        {
          name: "Functionality",
          path: "/functionality",
          key: "e65ce844-f06d-4e3b-8c0b-ebece9aac2c3",
        },
        {
          name: "Banners",
          path: "/banners",
          key: "20485470-b597-48b0-b5e8-195aa1d9f62f",
        },
        // {
        //   name: "Banners",
        //   path: "/banners",
        // },
        // {
        //   name: "Banners Redirectional",
        //   path: "/bannersredirectional",
        // },
      ],
    },
    // {
    //   name: "Banners",
    //   path: "/banners
    //   icon: <BiBookmarks {...icon} />,
    //   items: [
    //     {
    //       name: "Banners",
    //       path: "/banners
    //     },
    //     {
    //       name: "Banner Redirectional",
    //       path: "/bannersredirectional
    //     },
    //   ]
    // },
    {
      name: "Redirectional",
      path: "/redirectional",
      key: "82a4da23-4ad3-4956-ba1b-7cbc50bfa4a4",
      icon: <HiOutlineHashtag {...icon} />
    },
    // {
    //   name: "Slider",
    //   path: "/sliders",
    //   icon: <TfiLayoutSliderAlt {...icon} />,
    //   items: [
    //     {
    //       name: "Sliders",
    //       path: "/sliders",
    //     }, {
    //       name: "Redirectional",
    //       path: "/redirectional",
    //     }
    //   ]
    // },
    {
      name: "Themes",
      path: "/themestore",
      key: "259e3fc5-f2e0-4d44-9b08-07cf244bc7e2",
      icon: <BiBrushAlt {...icon} />,
      items: [
        {
          name: "Themes Store",
          path: "/themestore",
          key: "259e3fc5-f2e0-4d44-9b08-07cf244bc7e2",
        },
        {
          name: "Theme Categories",
          path: "/themecategories",
          key: "458be680-4f48-4f8e-a7ae-3e2c4b5d6dcb",
        },
        {
          name: "Theme Tags",
          path: "/themetags",
          key: "fc77cf12-f2ca-439c-9e9c-39c63f1004d0",
        },
        {
          name: "Theme Tabs",
          path: "/themetabs",
          key: "f7aa3db2-6f81-4976-af1b-d5820f7a0b22",
        },
        // {
        //   name: "Theme Badges",
        //   path: "/themebadges",
        // },
        {
          name: "Zip Validation",
          path: "/zip-validation",
          key: "d9b37f8d-4165-4f2d-908e-c770c933a378",
        },
      ]
    }, {
      name: "DIY's",
      path: "/backgrounds",
      key: "2c48b2a8-516e-4069-a2e3-163220387cab",
      icon: <CiGrid42 {...icon} strokeWidth={0.8} />,
      items: [
        {
          name: "Backgrounds",
          path: "/backgrounds",
          key: "2c48b2a8-516e-4069-a2e3-163220387cab",
        },
        {
          name: "Background Categories",
          path: "/backgroundcategories",
          key: "2ae75902-f40b-420e-b8fc-bfd1e62d66f4",
        },
        {
          name: "Effects",
          path: "/effects",
          key: "e198cd65-f1b8-476b-8be3-4cc585c51f5f",
        },
        {
          name: "Font Lists",
          path: "/fontlists",
          key: "899967d9-3ece-4056-b1c1-3c4187520e11",
        },
        {
          name: "Keys",
          path: "/keys",
          key: "30c55254-923e-4751-9e1f-996795b8d714",
        },
        {
          name: "Sounds",
          path: "/sounds",
          key: "2d014452-fb34-46a4-9eeb-22378f61b906",
          // icon: <HiOutlineMusicNote {...icon} />
        },
      ]
    },
    {
      name: "Text Art",
      path: "/textart",
      key: "dbd325ca-eb71-44cc-88ca-5c51f231ae49",
      icon: <MdOutlineDraw {...icon} />,
      items: [
        {
          name: "Text Art",
          path: "/textart",
          key: "31c09c59-4c44-4207-8a9c-f39b68017997",
        },
        {
          name: "Text Art Category",
          path: "/textartcategory",
          key: "329b287f-b31d-418e-b2ff-ca49febb7d56",
        }
      ]
    },
    {
      name: "Kamoji",
      path: "/kamoji",
      key: "c4d93112-5b89-4f02-817a-daba6596a02a",
      icon: <MdOutlineEmojiSymbols {...icon} />
    },
    {
      name: "Fancy Fonts",
      path: "/fancyfonts",
      key: "eca1028e-3b9a-432b-82ad-c3e6db91716e",
      icon: <IoTextOutline {...icon} />
    },
    {
      name: "Sticker Store",
      path: "/stickerstore",
      key: "57a8dc18-5754-459a-84e7-c64eb0efe34f",
      icon: <BiSticker {...icon} />
    },
    // {
    //   name: "Wallpaper",
    //   path: "/wallpapers",
    //   icon: <IoImageOutline {...icon} />,
    //   items: [
    //     {
    //       name: "Wallpapers",
    //       path: "/wallpapers",
    //     }, {
    //       name: "Wallpaper Categories",
    //       path: "/wallpapercategories",
    //     },
    //   ]
    // },
    {
      name: "Community",
      path: "/approved-themes",
      key: "dc97c23c-7bea-4d94-95cc-45318d26246d",
      icon: <FaChartLine {...icon} />,
      items: [
        {
          name: "Approved Themes",
          path: "/approved-themes",
          key: "dc97c23c-7bea-4d94-95cc-45318d26246d",
        },
        {
          name: "Pending Themes",
          path: "/pending-themes",
          key: "f8e91e53-0dcd-43fe-9e6e-9e4c4ae558c6",
        },
        {
          name: "Reject Themes",
          path: "/reject-themes",
          key: "6c37e2e5-41d5-4d63-8525-d9e9fd59ce3c",
        },
        {
          name: "Reason",
          path: "/community-Reason",
          key: "22ae074c-a062-4865-8465-ee4fc3f76274",
        },
        {
          name: "Report",
          path: "/community-report",
          key: "3adffd8f-e8b0-4f0c-b8b6-bf028475ed47",
        },
        {
          name: "Users",
          path: "/community-users",
          key: "d794579b-d0e8-4caf-b4a5-3b75cdf89d52",
        }
      ]
    },
    // {
    //   name: "User Feedback",
    //   path: "/userfeedback",
    //   icon: <MdOutlineFeedback {...icon} />
    // },
    // {
    //   name: "Storage",
    //   path: "/storage",
    //   icon: <BiData {...icon} />
    // },
    {
      name: "Storage",
      path: "/Storage",
      icon: <BiData  {...icon} />,
      items: [
        {
          name: "Storage",
          path: "/Storage",
          key: "d4202bee-d8d4-462e-9738-2c486d7a8d0d",

        },
        {
          name: "Community Storage",
          path: "/communityStorage",
          key: "282e2735-578d-45a2-bd95-1a524fbbdcd5",
        },
        // {
        //   name: "Banners",
        //   path: "/banners",
        // },
        // {
        //   name: "Banners Redirectional",
        //   path: "/bannersredirectional",
        // },
      ],
    },
    {
      name: "Blogs",
      icon: <BiBookmarks {...icon} />,
      key: "deb76068-80f1-4e5d-ae6a-8ce2f345546c",
      items: [
        // {
        //   name: "Posts",
        //   path: "/addBlogs",
        //   key: "0f774bfb-1e2a-43f8-bdc0-2ae999dcf223",
        // },
        {
          name: "Blog List",
          path: "/blogs",
          key: "deb76068-80f1-4e5d-ae6a-8ce2f345546c",
        },
        {
          name: "Categories",
          path: "/categories",
          key: "079a2209-9230-42ef-b4ab-d0b35606e07f",
        },
        {
          name: "Comments",
          path: "/comment",
          key: "a65e4be1-3072-44eb-a1cf-71f9922413c5",
        },
      ]
    },
    {
      name: "Assets Manager",
      path: "/asset",
      key: "1232e871-e979-48e0-887b-1da2387cf745",
      icon: <IoImagesOutline  {...icon} />
    },
    {
      name: "FAQ",
      path: "/faq",
      key: "32f8abe4-ea5a-4d5f-93bc-a4c8ba16661a",
      icon: <FaQuestion  {...icon} />
    },
    {
      name: "Survey",
      path: "/survey",
      key: "fb4fe0d8-da26-4956-9033-9ac66c4ba8f9",
      icon: <RiSurveyLine {...icon} />,
      items: [
        {
          name: "Survey",
          path: "/survey",
          key: "fb4fe0d8-da26-4956-9033-9ac66c4ba8f9",
        },
        {
          name: "Survey Analysis",
          path: "/surveyAnalysis",
          key: "0f0b7c43-ecac-46de-b912-af070a6c22fc",
        },
      ]
    },
    {
      name: "History",
      path: "/history",
      key: "2020ca1d-05eb-4d55-bb59-0705dd821602",
      icon: <TbHistory {...icon} />
    },

  ],
};

export default routes;
