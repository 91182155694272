import React, { useContext, useEffect, useState } from 'react';
import { Menu, MenuHandler, MenuList, MenuItem, Avatar, Typography, Button, IconButton } from "@material-tailwind/react";
import { Link, useNavigate } from 'react-router-dom';
import { HiOutlineChevronDown } from 'react-icons/hi'
import { Bars3Icon } from '@heroicons/react/24/solid';
import pkg from '../../../../package.json';
import { IoLogoGooglePlaystore } from "react-icons/io5";
import { FiGlobe } from "react-icons/fi";
import { NewContext } from '../../../Context/NewContext';
import { get_asset_link } from '../../../Controller/actions';

export const Header = ({ }) => {
    const navigate = useNavigate();
    const { handleLogout, toggleSidebar, setToggleSidebar, expiryTime, deleteUpdateYes, deleteUpdateTag } = useContext(NewContext);
    const [minutes, setMinutes] = useState('00');
    const [seconds, setSeconds] = useState('00');
    const [timeLimit, setTimeLimit] = useState(1);
    const [assetData, setAssetsData] = useState([])

    const getAssetsLinkData = async () => {
        try {
            const getAsset = await get_asset_link();
            if (getAsset) {
                const { status, data } = getAsset;
                if (status) {
                    setAssetsData(data);
                } else {
                    setAssetsData([]);
                }
            } else {
                setAssetsData([]);
            }
        } catch (error) {
            console.error(error);
        }
    };

    useEffect(() => {
        getAssetsLinkData()
    }, [deleteUpdateTag, deleteUpdateYes])

    const playStoreLink = assetData?.find(item => item?.title === 'PlayStore');
    const webLink = assetData?.find(item => item?.title === 'Webpages');



    useEffect(() => {
        if (expiryTime !== null) {
            setTimeLimit(Math.floor(expiryTime / 1000) - Math.floor(Date.now() / 1000));
        }
    }, [expiryTime]);

    useEffect(() => {
        let timerId;
        if (timeLimit > 0) {
            timerId = setInterval(() => {
                setTimeLimit((prevTimeLimit) => Math.max(0, prevTimeLimit - 1))
            }, 1000)
        } else {
            // handleLogout();
            localStorage.clear();
            window.location.reload();
        }

        return () => clearInterval(timerId)
    }, [timeLimit])

    useEffect(() => {
        setMinutes(Math.floor(timeLimit / 60).toString().padStart(2, '0'))
        setSeconds(Math.floor(timeLimit % 60).toString().padStart(2, '0'))
    }, [timeLimit])

    return (
        <main className="main w-full sticky top-0 flex flex-col ml-0 overflow-hidden z-50" id='main-header'>
            <header className="header bg-white shadow py-[10px] px-[27px]">
                <div className="header-content flex items-center justify-between">
                    <div className='flex gap-2'>
                        {playStoreLink && (
                            <div className='text-xl bg-gray-100 px-2 py-2.5 rounded-lg'>
                                <Link to={playStoreLink.description} target='_blank'>
                                    <IoLogoGooglePlaystore />
                                </Link>
                            </div>
                        )}
                        {webLink && (
                            <div className='text-xl bg-gray-100 px-2 py-2.5 rounded-lg'>
                                <Link to={webLink.description} target='_blank'>
                                    <FiGlobe />
                                </Link>
                            </div>
                        )}
                    </div>

                    <div className='flex items-center gap-4'>
                        <IconButton variant="text" color="blue-gray" className="grid 2xl:hidden" onClick={() => setToggleSidebar(!toggleSidebar)}>
                            <Bars3Icon strokeWidth={3} className="h-6 w-6 text-blue-gray-500" />
                        </IconButton>
                        <div className="flex items-center justify-center 2xl:hidden">
                            <Typography variant="h5" color="blue-gray" className='flex flex-row items-end'>
                                <img src="../../assets/images/MPK_Admin_logo.svg" alt="logo_dashboard" className='w-48' />
                                <div className='font-extrabold text-gray-400 text-[10px] ml-1 mb-1'>v{pkg.version}</div>
                            </Typography>
                        </div>
                    </div>
                    <Menu placement='bottom-end'>
                        <MenuHandler>
                            <div className='flex justify-center items-center'>
                                {setTimeout ? <div className='flex-1 text-center mr-5 h-full'>
                                    <p className='text-xs font-bold'>Session Time</p>

                                    <div className='flex gap-2 justify-center items-center py-2'>
                                        <div className='flex gap-1'>
                                            <div className='bg-gray-300 rounded-md px-2 py-1'>{minutes?.split('')[0]}</div>
                                            <div className='bg-gray-300 rounded-md px-2 py-1'>{minutes?.split('')[1]}</div>
                                        </div>
                                        <div>:</div>
                                        <div className='flex gap-1'>
                                            <div className='bg-gray-300 rounded-md px-2 py-1'>{seconds?.split('')[0]}</div>
                                            <div className='bg-gray-300 rounded-md px-2 py-1'>{seconds?.split('')[1]}</div>
                                        </div>
                                    </div>
                                </div> : ''}
                                <Button variant="text" className='p-0 md:py-1.5 md:px-[15px] flex items-center hover:bg-transparent active:bg-transparent focus-visible:outline-none rounded-full md:rounded-none overflow-hidden'>
                                    <Avatar
                                        variant="circular"
                                        alt={localStorage.getItem('userName')}
                                        className="cursor-pointer w-9 lg:w-[53px] h-9 lg:h-[53px]"
                                        src="https://images.unsplash.com/photo-1633332755192-727a05c4013d?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1480&q=80"
                                    />
                                    <div className='ml-1.5 lg:ml-3.5 mr-0 lg:mr-0 text-left hidden md:block'>
                                        <Typography variant="h6" className="text-[#201A27] text-base lg:text-lg leading-none mb-1 capitalize">{localStorage.getItem('userName')}</Typography>
                                        {/* <Typography variant="small" className="text-[#75869F] leading-none mb-0 capitalize">Admin</Typography> */}
                                    </div>
                                    <div className='ml-2 lg:ml-3.5 -mr-2 lg:mr-4 text-left hidden md:block'>
                                        <HiOutlineChevronDown className='text-darkblue' size={'20px'} />
                                    </div>
                                </Button>
                            </div>
                        </MenuHandler>
                        <MenuList>
                            <MenuItem onClick={() => { handleLogout(); navigate(`/signin`) }}>
                                <Typography variant="small" className="font-normal">Logout</Typography>
                            </MenuItem>
                        </MenuList>
                    </Menu>

                </div>
            </header>


        </main>
    )
}

export default Header