import React from 'react'
import { Navigate, Outlet } from 'react-router-dom';

export const ProtectedRoutes = () => {
    // const { token } = useContext(NewContext);
    const token = localStorage.getItem('token');
    return (
        token ? <Outlet /> : <Navigate to="/signin" />
    )
}

export default ProtectedRoutes