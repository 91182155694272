import React, { useContext, useEffect, useState } from "react";
import { toast } from "react-toastify";
import { Card, CardBody, Button, Input, Spinner } from "@material-tailwind/react";
import { add_survey, update_survey } from "../../Controller/actions";
import { NewContext } from "../../Context/NewContext";
import QuestionModal from "./QuestionModal";
import { IoRadioButtonOn, IoCheckbox, IoStar } from "react-icons/io5";
import ShortTextIcon from '@mui/icons-material/ShortText';
import FadeMenuModel from "./FadeMenuModel";
import { TbSortAscendingNumbers } from "react-icons/tb";
import { v4 as uuidv4 } from 'uuid';
import { MdDelete } from "react-icons/md";

const SurveyModel = ({ setShowSurveyModel }) => {
    const {
        isModalLoading,
        handleClose,
        handleChange,
        setIsModalLoading,
        addEditDeleteTag,
        setFormData,
        formData,
        menuOpen
    } = useContext(NewContext);

    const [showQuestionModal, setShowQuestionModal] = useState(false);
    const [selectedQuestion, setSelectedQuestion] = useState(null);
    const [questions, setQuestions] = useState([]);
    const [selectedQuestionType, setSelectedQuestionType] = useState(null);

    useEffect(() => {
        setFormData(formData);
        if (formData) {
            setQuestions(formData.question || questions || []);
        }
    }, [formData.question, addEditDeleteTag]);

    const handleAddQuestion = (question) => {
        const newQuestion = { ...question, id: uuidv4() };
        setQuestions([...questions, newQuestion]);
    };

    const handleCloseQuestionModal = () => {
        setShowQuestionModal(false);
        setSelectedQuestion(null);
    };

    const handleEditQuestion = (question) => {
        setSelectedQuestion(question);
        setSelectedQuestionType(question.type);
        setShowQuestionModal(true);
    };

    const handleDeleteQuestion = (questionId) => {
        const updatedQuestions = questions.filter(question => question?._id !== questionId);
        setQuestions(updatedQuestions);
    };

    const handleSelectQuestionType = (questionType) => {
        setSelectedQuestionType(questionType);
        setShowQuestionModal(true);
    };


    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!formData?.name.trim()) {
            toast.error("Please enter survey name");
            return
        }
        let createUpdateSurvey;
        setIsModalLoading(true);

        if (addEditDeleteTag === "update") {
            createUpdateSurvey = await update_survey({ ...formData, question: questions }, formData._id);
        } else {
            createUpdateSurvey = await add_survey({ ...formData, question: questions });
        }

        setIsModalLoading(false);
        if (createUpdateSurvey) {
            const { message, status } = createUpdateSurvey;
            if (status) {
                toast.success(message);
                setQuestions([]);
                setFormData([]);
                handleClose();
                setShowSurveyModel(false);
            } else {
                toast.error(message);
            }
        }
        handleClose();
    };

    return (
        <div className="grid grid-cols-12 gap-4 h-screen">
            <Card
                className={`overflow-hidden w-full rounded-md shadow-none col-span-12 px-4`}
            >
                <div className=" w-full">
                    <div className="text-xl my-4 font-bold text-gray-800 flex justify-center">
                        Survey Form
                    </div>
                    <hr className="h-px my-2 bg-gray-200 border-0 dark:bg-gray-700" />
                </div>
                <CardBody className="w-100 py-2.5 px-[5px] overflow-y-auto overflow-x-hidden" >
                    {showQuestionModal ? (
                        <div className="col-span-12">
                            <QuestionModal
                                show={showQuestionModal}
                                handleClose={handleCloseQuestionModal}
                                handleAddQuestion={handleAddQuestion}
                                selectedQuestion={selectedQuestion}
                                questions={questions}
                                setQuestions={setQuestions}
                                setShowQuestionModal={setShowQuestionModal}
                                surveyName={formData?.name}
                                questionType={selectedQuestionType}
                            />
                        </div>
                    ) : (
                        <div className="col-span-12" >
                            <form onSubmit={handleSubmit} className="add-edit-form">
                                <>
                                    <div className="mb-4">
                                        <div className="flex">
                                            <label htmlFor="name" className="block text-sm font-medium leading-6 text-gray-900">Survey Name:</label> <span style={{ color: "red" }}>*</span></div>
                                        <Input id='name' placeholder='Enter name of Survey' name='name' onChange={handleChange} value={formData?.name || ""} autoComplete='off' required={true} />
                                        <label htmlFor="description" className="mt-2 block text-sm font-medium leading-6 text-gray-900">Survey Description:</label>
                                        <textarea id='description'
                                            rows="2"
                                            className=" block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                            placeholder="Write your thoughts here..."
                                            name='description'
                                            onChange={handleChange}
                                            value={formData?.description || ""}
                                            autoComplete='off' />
                                    </div>
                                    <div>
                                        {questions.map((question) => (
                                            <div key={question.id}>
                                                <div className="flex justify-between break-words">
                                                    <div onClick={() => handleEditQuestion(question)} className="w-80 break-words">
                                                        <div className="mb-2">
                                                            <div className="flex flex-item-center my-3 p-2 border rounded-lg">
                                                                <div>
                                                                    {question.type === "checkbox" && <IoCheckbox color="green" className="mt-1 mr-2 w-f" />}
                                                                    {question.type === "radio" && <IoRadioButtonOn color="blue" className="mt-1 mr-2" />}
                                                                    {question.type === "rating" && <IoStar color="gold" className="mt-1 mr-2" />}
                                                                    {question.type === "text" && <ShortTextIcon style={{ color: 'red' }} className="mt-1 mr-2" />}
                                                                    {question.type === "numberscale" && <TbSortAscendingNumbers style={{ color: 'orange' }} className="mt-1 mr-2" />}
                                                                </div>
                                                                <div className="w-60" id={`question-${question.id}`}>
                                                                    {question.text}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <button
                                                        type="button"
                                                        className="flex ml-2 my-auto text-red-500 focus:outline-none text-2xl"
                                                        onClick={() => handleDeleteQuestion(question._id)}
                                                    >
                                                        <MdDelete />
                                                    </button>
                                                </div>
                                            </div>
                                        ))}


                                    </div>
                                    <div>
                                        <FadeMenuModel onSelectQuestionType={handleSelectQuestionType} />
                                    </div>
                                </>
                                {menuOpen ? '' :
                                    <div className="flex justify-end mt-6">
                                        <Button
                                            onClick={handleClose}
                                            className="mr-2.5 px-5 py-2.5 bg-white shadow-none text-[#1E3354] font-medium border border-[#75869F] hover:shadow-none capitalize text-base"
                                        >
                                            Close
                                        </Button>
                                        <Button
                                            disabled={isModalLoading}
                                            className="flex items-center px-5 py-2.5 bg-[#0047FF] shadow-none text-white font-medium border border-[#0047FF] hover:shadow-none capitalize text-base"
                                            type="submit"
                                        >
                                            {isModalLoading && <Spinner className="h-4 w-4 mr-2" />}
                                            {addEditDeleteTag === "update" ? 'Update Survey' : 'Add Survey'}
                                        </Button>
                                    </div>
                                }
                            </form>
                        </div>
                    )}
                </CardBody>
            </Card>
        </div >
    );
};

export default SurveyModel;
